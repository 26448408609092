import React from "react";
import { animated, useSpring } from "react-spring";

type ButtonProps = {
  variant?: "solid" | "outline";
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({
  children,
  variant = "solid",
  className = "",
  disabled,
  ...props
}) => {
  let classNames = `p-2 px-5 rounded ${className} ${
    disabled ? "opacity-80 cursor-not-allowed" : "cursor-pointer"
  }`;

  let variantStyle = "";
  switch (variant) {
    case "solid":
      variantStyle += "bg-primary border-2 border-primary";
      break;
    case "outline":
      variantStyle += "dark:bg-dark-400 border-2 dark:border-dark-800 border-[#EF9781] text-[#FF0000] dark:text-light-500";
      break;
  }
  classNames += ` ${variantStyle}`;

  const [springProps, setSpringProps] = useSpring(() => ({
    transform: "scale(1)",
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  return (
    <animated.button
      className={classNames}
      {...props}
      style={springProps}
      disabled={disabled}
      onMouseEnter={() => setSpringProps({ transform: "scale(1.03)" })}
      onMouseLeave={() => setSpringProps({ transform: "scale(1)" })}
    >
      {children}
    </animated.button>
  );
};

export default Button;
