import { PropsWithChildren, ButtonHTMLAttributes } from "react";
import { useSpring, animated } from "react-spring";
import { HomeIcon, RingIcon, SearchIcon } from "../assets/Icons";
import ProfileMenu from "./Profile";
import Button from "./Button";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { FiSun, FiMoon } from "react-icons/fi";
import React, { useEffect, useContext } from "react";
import { IPContext } from '../hooks/IPContext';

type NavbarButtonProps = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement>
>;

export const NavbarButton: React.FC<NavbarButtonProps> = ({
  children,
  ...props
}) => {
  const [springProps, setSpringProps] = useSpring(() => ({
    scale: 1,
    config: { tension: 500, friction: 20 },
  }));

  return (
    <animated.button
      className="h-full w-[30px] flex items-center justify-center"
      style={{
        transform: springProps.scale.to((scale) => `scale(${scale})`),
      }}
      onMouseEnter={() => setSpringProps({ scale: 1.2 })}
      onMouseLeave={() => setSpringProps({ scale: 1 })}
      {...props}
    >
      {children}
    </animated.button>
  );
};

const Navbar = () => {
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const {darkMode, setDarkMode} = useContext(IPContext);

  const handleUpgradeClick = () => {
    navigate("/pixelplusai/subscription");
  };

  // const { isAuthenticated } = useAuth();

  const handleLightTheme = () => {
    setDarkMode(false);
  };

  const handleDarkTheme = () => {
    setDarkMode(true);
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark');
      document.documentElement.classList.add('dark');
    }
    else {
      document.body.classList.remove('dark');
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);


  return (
    <div className={`${darkMode? "dark": ""}`}>
      <div className="w-full bg-light-600 dark:bg-dark-100 text-light-600 font-michroma [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
        <div className="container flex items-center justify-between">
          <div className="flex justify-center items-center gap-3 p-5 text-[21px]">
            <img
              src={"/logo/bclogo.svg"}
              alt="Brain Capital AI Logo"
              className="w-[50px]"
            />
            <a href="https://braincapital.ai" className="text-red-500 dark:text-white font-bold dark:font-normal">Brain&nbsp;Capital&nbsp;AI</a>
          </div>
          <div className="flex gap-3 p-5 items-center">
            {/* {!isAdmin ? (<Button className="mx-2" onClick={handleUpgradeClick}>Upgrade</Button>): ("")} */}
            <div className={`flex rounded bg-white border border-[2px] overflow-hidden ${
                  darkMode ? "border-white" : "border-gray-500"
                }`}>
              <button
                onClick={handleLightTheme}
                className={`w-10 h-10 flex items-center justify-center ${
                  darkMode ? "" : "bg-blue-600 text-white"
                }`}
              >
                <FiSun className={`text-xl ${darkMode? "text-black" : "text-white"}`} />
              </button>
              <button
                onClick={handleDarkTheme}
                className={`w-10 h-10 flex items-center justify-center ${
                  darkMode ? "bg-blue-600 text-white" : ""
                }`}
              >
                <FiMoon className={`text-xl ${darkMode? "text-white" : "text-black"}`} />
              </button>
            </div>
            <Button className="mx-2" onClick={handleUpgradeClick}>
              Upgrade
            </Button>
            {/* <NavbarButton>
              <SearchIcon />
            </NavbarButton>
            <NavbarButton>
              <RingIcon />
            </NavbarButton> */}
            <NavbarButton
              onClick={() =>
                (window.location.href = "https://braincapital.ai/")
              }
            >
              <HomeIcon className="dark:text-white text-black"/>
            </NavbarButton>
            <ProfileMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
