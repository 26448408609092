// "use strict";
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.selectVideo = void 0);
const video_1 = require("../utils/video"),
  selectVideo = async ({
    src: e,
    droppedPos: t,
    targetElement: i,
    store: o,
  }) => {
    var d;
    let { width: s, height: l } = await (0, video_1.getVideoSize)(e);
    const r = Math.min(o.width / s, o.height / l, 1);
    (s *= r), (l *= r);
    const c = ((null == t ? void 0 : t.x) || o.width / 2) - s / 2,
      h = ((null == t ? void 0 : t.y) || o.height / 2) - l / 2;
    null === (d = o.activePage) ||
      void 0 === d ||
      d.addElement({ type: "video", src: e, width: s, height: l, x: c, y: h });
  };
exports.selectVideo = selectVideo;
