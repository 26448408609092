// "use strict";
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.Shape = exports.Animation = void 0);
const mobx_state_tree_1 = require("mobx-state-tree"),
  animations_1 = require("../utils/animations"),
  mobx_1 = require("mobx"),
  node_model_1 = require("./node-model");
(exports.Animation = mobx_state_tree_1.types.model("Animation", {
  delay: 0,
  duration: 500,
  enabled: !0,
  type: mobx_state_tree_1.types.enumeration("Type", ["enter", "exit"]),
  name: "none",
  data: mobx_state_tree_1.types.frozen({}),
})),
  (exports.Shape = node_model_1.Node.named("Shape")
    .props({
      x: 0,
      y: 0,
      width: 100,
      height: 100,
      rotation: 0,
      opacity: 1,
      animations: mobx_state_tree_1.types.array(exports.Animation),
      blurEnabled: !1,
      blurRadius: 10,
      brightnessEnabled: !1,
      brightness: 0,
      sepiaEnabled: !1,
      grayscaleEnabled: !1,
      shadowEnabled: !1,
      shadowBlur: 5,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowColor: "black",
      shadowOpacity: 1,
      visible: !0,
      draggable: !0,
      resizable: !0,
      selectable: !0,
      contentEditable: !0,
      styleEditable: !0,
      alwaysOnTop: !1,
      showInExport: !0,
    })
    .preProcessSnapshot((e) => {
      const t = Object.assign(Object.assign({}, e), {
        x: e.x || 0,
        y: e.y || 0,
      });
      return (
        "width" in e && (t.width = t.width || 1),
        "height" in e && (t.height = t.height || 1),
        e.locked &&
          ((t.draggable = !1),
          (t.contentEditable = !1),
          (t.styleEditable = !1),
          (t.resizable = !1)),
        t
      );
    })
    .views((e) => {
      const t = (0, mobx_1.observable)({
          x: e.x,
          y: e.y,
          width: e.width,
          height: e.height,
          rotation: e.rotation,
          opacity: e.opacity,
          color: e.color,
          fontSize: e.fontSize,
        }),
        i = (0, mobx_1.action)((e) => {
          Object.assign(t, e);
        });
      return {
        get a() {
          const { currentTime: a } = e.store;
          if (
            (i({
              x: e.x,
              y: e.y,
              width: e.width,
              height: e.height,
              rotation: e.rotation,
              opacity: e.opacity,
              color: e.color,
              fontSize: e.fontSize,
            }),
            0 === a)
          )
            return t;
          const n = a - e.page.startTime;
          if (n > e.page.duration) return t;
          if (n < 0) return t;
          const o = e.store.animatedElementsIds;
          if (o.length && !o.includes(e.id)) return t;
          const s = e.animations.find((e) => "enter" === e.type);
          (null == s ? void 0 : s.enabled) && n < s.delay && i({ opacity: 0 });
          if (
            (null == s ? void 0 : s.enabled) &&
            n >= s.delay &&
            n <= s.delay + s.duration
          ) {
            const t = n - s.delay,
              a = (0, animations_1.animate)({
                element: e,
                animation: s,
                dTime: t,
              });
            i(a);
          }
          const r = e.animations.find((e) => "exit" === e.type);
          if (
            (null == r ? void 0 : r.enabled) &&
            n >= e.page.duration - r.duration
          ) {
            const t = n - (e.page.duration - r.duration),
              a = (0, animations_1.animate)({
                element: e,
                animation: r,
                dTime: t,
              });
            i(a);
          }
          return t;
        },
        animated: (t) => e.a[t],
      };
    })
    .actions((e) => ({
      setAnimation(t, i) {
        const a = e.animations.find((e) => e.type === t);
        a
          ? Object.assign(a, i)
          : e.animations.push(Object.assign({ type: t }, i));
      },
    })));
