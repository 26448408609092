import React from "react";

const TextInput: {
  className?: string;
} & React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  className,
  ...props
}) => {
  const classNames = `p-2  bg-[#F4F4F4] dark:bg-dark-500 rounded text-black dark:text-light-600 outline-none border dark:border-dark-500 dark:focus:border-primary focus:border-light-600 ${className}`;
  return <input type="text" className={classNames} {...props} />;
};

export default TextInput;
