import React, {useState, useEffect} from 'react'
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { HashLoader } from "react-spinners";
import { generate_apikey, get_apikey } from "../../actions/profile";

const ApiKey = () => {
  const { isAdmin, UserInfo } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [apiKeyNumber, setApiKeyNumber] = useState(null);
  const [genKeyInput, setGenKeyInput] = useState("Your key comes here");
  // const [apiKey, setApiKey] = useState("Your key comes here");
  const [apiKey, setApiKey] = useState([]);
  
  useEffect(() => {
    const fetchUserInfo = async () => {
      const info = await UserInfo();
      setApiKeyNumber(info.data.apikey);
      setIsLoading(false);
    };

    fetchUserInfo();
    getKey();
    // generateKey();
  }, [UserInfo]);

  const getKey = async () => {
    setIsLoading(true);
    try {
      const res = await get_apikey();
      setApiKey(res?.data);
    } catch (e: any) {
      if (e.message.includes("401")) {
        toast.error("The token has expired. Please login again.");
      } else {
        toast.error("Failed generating api-key");
      }
    }

    setIsLoading(false);
  };
  const generateKey = async () => {
    setIsLoading(true);
    try {
      const res = await generate_apikey();
      const info = await UserInfo();
      setApiKeyNumber(info.data.apikey);
      console.log(res?.data);
      setApiKey(res?.data);
      setGenKeyInput(res?.data[res?.data.length - 1]?.api_key);
    } catch (e: any) {
      if (e.message.includes("401")) {
        toast.error("The token has expired. Please login again.");
      } else {
        toast.error("Failed generating api-key");
      }
    }
    setIsLoading(false);
  };
  return (
    <div className='flex flex-col gap-8 h-full'>
      <div className="flex flex-col mb-12">
            <p className="text-black dark:text-light-600 text-[60px] font-bold">
              Your API Key
            </p>
            <p className="px-8 text-lg text-black dark:text-light-200">
              You have{" "}
              <span
                className={`text-lightgrey dark:text-white font-bold text-[20px] border ${
                  apiKeyNumber != 0 ? "border-green-600" : "border-red-600"
                } rounded px-4 mx-1`}
              >
                {isAdmin ? "infinite" : apiKeyNumber}
              </span>{" "}
              api keys left.
            </p>
          </div>
          <div className="flex justify-end">
            <a
              href="https://documenter.getpostman.com/view/35013085/2sA3JT4eKw"
              target="_blank"
              rel="noopener noreferrer"
              className="flex border border-[2px] rounded p-2 text-[#ff0000] dark:text-white text-lg border-[#EF9781] dark:border-light-600"
            >
              View API DOC
            </a>
          </div>

          {isLoading ? (
            ""
          ) : (
            <div className='h-full'>
              <div className="flex flex-col gap-2 mb-12">
                <p className="w-full min-h-5 rounded px-8 py-2 text-lg border border-gray-200">
                  {genKeyInput}
                </p>
              </div>
              <div>
                {[...apiKey].reverse().map((key: any, index: number) =>
                  key.is_exist ? (
                    <p key={index} className="text-black dark:text-white">
                      {key.api_key}
                    </p>
                  ) : (
                    <p key={index} className=" text-[#ff0000] line-through">
                      {key.api_key}
                    </p>
                  )
                )}
              </div>
            </div>
          )}
          <div className="flex items-center justify-center mb-4 bottom-8">
            <button
              className={`flex w-1/4 mt-4 text-white text-lg font-bold py-2 px-4 rounded justify-center ${
                apiKeyNumber !== 0
                  ? "bg-blue-600 hover:bg-blue-800"
                  : "bg-gray-400 cursor-not-allowed"
              }`}
              disabled={apiKeyNumber === 0}
              onClick={generateKey}
            >
              {isLoading ? (
                <HashLoader
                  color="#e9e9e9"
                  cssOverride={{}}
                  loading
                  size={32}
                  speedMultiplier={1.5}
                />
              ) : (
                "Generate New Key"
              )}
            </button>
          </div>
    </div>
  )
}

export default ApiKey
