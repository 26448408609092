// "use strict";
var __createBinding =
    (this && this.__createBinding) ||
    (Object.create
      ? function (e, t, o, i) {
          void 0 === i && (i = o);
          var n = Object.getOwnPropertyDescriptor(t, o);
          (n && !("get" in n ? !t.__esModule : n.writable || n.configurable)) ||
            (n = {
              enumerable: !0,
              get: function () {
                return t[o];
              },
            }),
            Object.defineProperty(e, i, n);
        }
      : function (e, t, o, i) {
          void 0 === i && (i = o), (e[i] = t[o]);
        }),
  __setModuleDefault =
    (this && this.__setModuleDefault) ||
    (Object.create
      ? function (e, t) {
          Object.defineProperty(e, "default", { enumerable: !0, value: t });
        }
      : function (e, t) {
          e.default = t;
        }),
  __importStar =
    (this && this.__importStar) ||
    function (e) {
      if (e && e.__esModule) return e;
      var t = {};
      if (null != e)
        for (var o in e)
          "default" !== o &&
            Object.prototype.hasOwnProperty.call(e, o) &&
            __createBinding(t, e, o);
      return __setModuleDefault(t, e), t;
    },
  __rest =
    (this && this.__rest) ||
    function (e, t) {
      var o = {};
      for (var i in e)
        Object.prototype.hasOwnProperty.call(e, i) &&
          t.indexOf(i) < 0 &&
          (o[i] = e[i]);
      if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
        var n = 0;
        for (i = Object.getOwnPropertySymbols(e); n < i.length; n++)
          t.indexOf(i[n]) < 0 &&
            Object.prototype.propertyIsEnumerable.call(e, i[n]) &&
            (o[i[n]] = e[i[n]]);
      }
      return o;
    };
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.jsonToSVG = exports.jsonToDOM = exports.fixRatio = void 0);
const image_1 = require("./image"),
  svg = __importStar(require("./svg")),
  image_2 = require("./image"),
  figure_to_svg_1 = require("./figure-to-svg"),
  h = (e, t, ...o) => ({ type: e, props: t, children: o || [] });
function fixRatio(e) {
  var t = new DOMParser().parseFromString(e, "image/svg+xml");
  t.documentElement.setAttribute("preserveAspectRatio", "none");
  return new XMLSerializer().serializeToString(t);
}
exports.fixRatio = fixRatio;
const imageToDataURL = async (e) => {
    const t = await (0, image_2.loadImage)(e),
      o = document.createElement("canvas");
    (o.width = t.width), (o.height = t.height);
    return o.getContext("2d").drawImage(t, 0, 0), o.toDataURL("image/png");
  },
  imageToDOM = async ({ element: e, page: t, store: o }) => {
    let { src: i } = e;
    if ("svg" === e.type) {
      const t = await svg.urlToString(i);
      i = svg.replaceColors(t, new Map(Object.entries(e.colorsReplace)));
    }
    let n = "";
    e.flipX && (n += "scaleX(-1)"), e.flipY && (n += "scaleY(-1)");
    const r = {};
    if (e.clipSrc) {
      const t = await svg.urlToBase64(e.clipSrc);
      r["clip-path"] = `url(${t})`;
    }
    const a = await (0, image_2.loadImage)(i),
      s = a.width * e.cropWidth,
      l = a.height * e.cropHeight,
      g = e.width / e.height;
    let c, p;
    const d = s / l;
    "svg" === e.type
      ? ((c = s), (p = l))
      : g >= d
      ? ((c = s), (p = s / g))
      : ((c = l * g), (p = l));
    const m = c / a.width,
      u = p / a.height,
      f = c / p > e.width / e.height ? e.height / p : e.width / c,
      y = (c * f) / m,
      w = (p * f) / u,
      O = e.cropX * a.width * f,
      b = e.cropY * a.height * f,
      x = `clip-${e.id}`,
      $ = i.replace(/&/g, "&amp;");
    return h(
      "g",
      { style: { transform: n } },
      h(
        "defs",
        {},
        h(
          "clipPath",
          { id: x },
          h("rect", { x: 0, y: 0, width: e.width, height: e.height })
        )
      ),
      h("image", {
        "xlink:href": $,
        x: -O,
        y: -b,
        width: y,
        height: w,
        preserveAspectRatio: "none",
        "clip-path": `url(#${x})`,
      })
    );
  },
  textToDOM = async ({ element: e, page: t, store: o }) => {
    const i = (e, t, o, i, n) => {
        const r = document.createElement("canvas").getContext("2d");
        return (r.font = `${n} ${i} ${t}px ${o}`), r.measureText(e).width;
      },
      n = ((e, t, o, n, r, a) => {
        const s = [];
        return (
          e.split("\n").forEach((e) => {
            const l = e.split(" ");
            let h = "";
            for (let e = 0; e < l.length; e++) {
              const g = h + l[e] + " ";
              i(g, o, n, r, a) > t && e > 0
                ? (s.push(h.trim()), (h = l[e] + " "))
                : (h = g);
            }
            s.push(h.trim());
          }),
          s
        );
      })(e.text, e.width, e.fontSize, e.fontFamily, e.fontWeight, e.fontStyle),
      r = e.fontSize * e.lineHeight,
      a =
        "center" === e.align ? "middle" : "right" === e.align ? "end" : "start",
      s = n.map((t, o) =>
        h("tspan", {
          x:
            "center" === e.align
              ? e.width / 2
              : "right" === e.align
              ? e.width
              : 0,
          dy: 0 === o ? 0 : r,
          innerHTML: t,
        })
      );
    return h(
      "g",
      {},
      h(
        "text",
        {
          fill: e.fill,
          y: e.fontSize,
          "font-size": e.fontSize + "px",
          "text-anchor": a,
          "font-family": e.fontFamily,
          "font-style": e.fontStyle,
          "font-weight": e.fontWeight,
          "text-decoration": e.textDecoration,
          "line-height": e.lineHeight,
          "letter-spacing": e.letterSpacing + "em",
          "stroke-width": e.strokeWidth,
          stroke: e.stroke,
        },
        ...s
      )
    );
  },
  getLineHead = ({ element: e, type: t }) => {
    const o = {
      "stroke-width": e.height,
      stroke: e.color,
      "line-cap": "round",
      "stroke-linejoin": "round",
      opacity: e.opacity,
    };
    return "arrow" === t || "triangle" === t
      ? h(
          "polyline",
          Object.assign(
            {
              points: `${3 * e.height},${2 * -e.height} 0,0 ${3 * e.height},${
                2 * e.height
              }`,
            },
            o
          )
        )
      : "bar" === t
      ? h(
          "polyline",
          Object.assign({ points: `0,${2 * -e.height} 0,${2 * e.height}` }, o)
        )
      : "circle" === t
      ? h("circle", Object.assign({ r: e.height }, o))
      : "square" === t
      ? h(
          "polyline",
          Object.assign(
            {
              points: `${-e.height},${-e.height} ${-e.height},${e.height} ${
                e.height
              },${e.height} ${e.height},${-e.height}`,
            },
            o
          )
        )
      : null;
  },
  lineToDOM = async ({ element: e, page: t, store: o }) =>
    h(
      "g",
      {},
      h("line", {
        x1: 0,
        y1: e.height / 2,
        x2: e.width,
        y2: e.height / 2,
        stroke: e.color,
        "stroke-width": e.height,
      }),
      h(
        "g",
        { transform: `translate(0 ${e.height / 2})` },
        getLineHead({ element: e, type: e.startHead })
      ),
      h(
        "g",
        { transform: `translate(${e.width} ${e.height / 2}) rotate(180)` },
        getLineHead({ element: e, type: e.endHead })
      )
    ),
  groupToDOM = async ({ element: e, page: t, store: o, elementHook: i }) => {
    const n = await Promise.all(
        e.children.map((e) =>
          elementToDOM({ element: e, page: t, store: o, elementHook: i })
        )
      ),
      r = h(
        "g",
        {
          opacity: e.opacity,
          transform: `translate(${e.x}, ${e.y}) rotate(${e.rotation})`,
          style: { "transform-origin": "top left" },
        },
        ...n
      );
    return (i && i({ dom: r, element: e })) || r;
  };
function removeSvgTags(e) {
  let t = e.replace(/<svg[^>]*>/, "");
  return (t = t.replace(/<\/svg>/, "")), t;
}
const figureToDOM = async ({
    element: e,
    page: t,
    store: o,
    elementHook: i,
  }) => {
    const n = removeSvgTags((0, figure_to_svg_1.figureToSvg)(e)),
      r = h("g", { innerHTML: n });
    return (i && i({ dom: r, element: e })) || r;
  },
  type = {
    image: imageToDOM,
    svg: imageToDOM,
    text: textToDOM,
    line: lineToDOM,
    figure: figureToDOM,
    group: groupToDOM,
  };
async function elementToDOM({ element: e, page: t, store: o, elementHook: i }) {
  let n = await type[e.type];
  n ||
    ((n = () => h("g", {})),
    console.error(`SVG export does not support ${e.type} type...`));
  const r = await n({ element: e, page: t, store: o }),
    a = [];
  e.blurEnabled && a.push(`blur(${e.blurRadius / 2}px)`),
    e.brightnessEnabled && a.push(`brightness(${100 * e.brightness + 100}%)`),
    e.sepiaEnabled && a.push("sepia()"),
    e.grayscaleEnabled && a.push("grayscale()"),
    e.shadowEnabled &&
      a.push(
        `drop-shadow(${e.shadowOffsetX}px ${e.shadowOffsetY}px ${e.shadowBlur}px ${e.shadowColor})`
      );
  const s = h(
    "g",
    {
      className: "element",
      id: e.id,
      transform: `translate(${e.x}, ${e.y}) rotate(${e.rotation})`,
      display: e.visible ? void 0 : "none",
      style: { "transform-origin": "top left", filter: a.join(" ") },
    },
    r
  );
  return (i && i({ dom: s, element: e })) || s;
}
async function pageToDOM({ page: e, store: t, elementHook: o }) {
  const i = await Promise.all(
    e.children.map((i) =>
      elementToDOM({ element: i, page: e, store: t, elementHook: o })
    )
  );
  let n = {};
  if (
    e.background.indexOf("url") >= 0 ||
    e.background.indexOf("http") >= 0 ||
    e.background.indexOf(".jpg") >= 0 ||
    e.background.indexOf(".png") >= 0 ||
    e.background.indexOf(".jpeg") >= 0
  ) {
    const o = await (0, image_2.loadImage)(e.background),
      i = await (0, image_2.cropImage)(
        e.background,
        Object.assign(
          { width: t.width, height: t.height, x: 0, y: 0 },
          (0, image_1.getCrop)(
            { width: t.width, height: t.height },
            { width: o.width, height: o.height }
          )
        )
      );
    n = Object.assign(Object.assign({}, n), {
      "background-image": `url(${i})`,
    });
  }
  return h(
    "g",
    {
      className: "page",
      style: Object.assign(Object.assign({}, n), { background: e.background }),
    },
    h("rect", {
      x: 0,
      y: 0,
      width: t.width,
      height: t.height,
      fill: e.background,
    }),
    ...i
  );
}
async function jsonToDOM({ json: e, elementHook: t }) {
  const o = await Promise.all(
      e.pages.map((o) => pageToDOM({ page: o, store: e, elementHook: t }))
    ),
    i = [];
  e.pages.forEach((e) => {
    e.children.forEach((e) => {
      "text" === e.type &&
        -1 === i.indexOf(e.fontFamily) &&
        i.push(e.fontFamily);
    });
  });
  const n = i.map((t) =>
    e.fonts.find((e) => e.fontFamily === t)
      ? h("style", {}, "")
      : h(
          "defs",
          {},
          h("style", {
            type: "text/css",
            innerHTML:
              `@import url('https://fonts.googleapis.com/css?family=${t}:bi,normal,i,b');`.replace(
                /&/g,
                "&amp;"
              ),
          })
        )
  );
  return h(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      viewBox: `0 0 ${e.width} ${e.height}`,
    },
    ...n,
    ...o
  );
}
exports.jsonToDOM = jsonToDOM;
const serializeProp = (e, t) =>
    "object" == typeof t
      ? `${e}="${Object.keys(t)
          .map((e) => `${e}:${t[e]};`)
          .join(" ")}"`
      : null == t || "" === t
      ? ""
      : `${e}="${t}"`,
  domToSVG = ({ dom: e, nestLevel: t = 0 }) => {
    if ("string" == typeof e) return e;
    if (!e) return "";
    const o = e.props,
      { innerHTML: i } = o,
      n = __rest(o, ["innerHTML"]),
      r = " ".repeat(t);
    return `${r}<${e.type} ${Object.keys(n)
      .map((e) => serializeProp(e, n[e]))
      .join(" ")}>${
      i ||
      "\n" +
        e.children.map((e) => domToSVG({ dom: e, nestLevel: t + 1 })).join("")
    }${r}</${e.type}>\n`;
  };
async function jsonToSVG({ json: e, elementHook: t }) {
  const o = await jsonToDOM({ json: e, elementHook: t });
  return domToSVG({ dom: o });
}
exports.jsonToSVG = jsonToSVG;
