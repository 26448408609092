const unauthenticatedUrl = process.env.REACT_UNAUTHENTICATED_URL;
const authenticatedUrl = process.env.REACT_AUTHENTICATED_URL;
const apiBaseUrl = process.env.REACT_BASE_URL;
const loginUrl = process.env.REACT_LOGIN_URL;
const registerUrl = process.env.REACT_REGISTER_URL;
const stripeKey = process.env.REACT_STRIPE_KEY;

export {
  unauthenticatedUrl,
  authenticatedUrl,
  apiBaseUrl,
  loginUrl,
  registerUrl,
  stripeKey,
};
