import React, { useState, useEffect } from 'react'
import { get_ads } from "../../actions/profile";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import Loading from "../../components/Loading";
import ReactDOM from "react-dom";
import { useContext } from 'react';
import { IPContext } from '../../hooks/IPContext';
import { IoClose, IoAdd, IoRemove } from "react-icons/io5";

const Ads = () => {
  const { isAdmin, UserInfo } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [ads, setAds] = useState([]);
  const [showDetails, setShowDetails] = useState(-1);
  const [selectedAd, setSelectedAd] = useState(null);
  const { darkMode } = useContext(IPContext);
  const [selected, setSelected] = useState(1);

  const pdfFiles = selectedAd ? JSON.parse(selectedAd.pdfFiles_url) as string[] : [];

  useEffect(() => {
    const fetchUserInfo = async () => {
      const info = await UserInfo();
      setIsLoading(false);
    };

    fetchUserInfo();
    getAds();
  }, [UserInfo]);


  const getAds = async () => {
    setIsLoading(true);
    try {
      const res = await get_ads();
      setAds(res?.data);
      console.log(res)
    } catch (e: any) {
      if (e.message.includes("401")) {
        toast.error("The token has expired. Please login again.");
      } else {
        toast.error("Failed getting ads");
      }
    }

    setIsLoading(false);
  };

  const downloadImage = (img_url: string) => {
    console.log('downloading', img_url);

    const img = document.createElement('a');
    img.href = img_url;
    img.download = img_url;
    img.click();
  }

  return (
    <div>
      {isLoading ? (<Loading loading={isLoading} />) : (
        <div className="overflow-y-auto grid grid-cols-3 gap-5 mb-5 max-h-[70vh] p-5 rounded-lg">
          {/* {[...ads].reverse().map((ad: any, index: number) =>
            <img src={ad.result_source} alt={`Ad ${index}`} style={{ width: '100%', height: 'auto' }} />
          )} */}
          {[...ads].reverse().map((ad: any, index: number) => (
            <div
              key={index}
              className="relative cursor-pointer"
              onMouseEnter={() => setShowDetails(index)}
              onMouseLeave={() => setShowDetails(-1)}
            >
              <img
                src={ad.result_source}
                alt={`Template ${index + 1}`}
                className="rounded-lg h-[1/2] object-cover border dark:border-light-600 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none"
              />
              {showDetails === index && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 gap-2">
                  <button
                    className="p-2 bg-gray-700 text-white rounded"
                    onClick={() => setSelectedAd(ad)}
                  >
                    View Details
                  </button>
                  <button 
                    className="p-2 bg-gray-700 text-white rounded"
                    onClick={() => downloadImage(ad.result_source)}
                  >
                    Download
                  </button>
                </div>
              )}
            </div>
          ))}

          {selectedAd && ReactDOM.createPortal(
            <div className={`${darkMode ? "dark" : ""} w-screen h-screen left-0 top-0 fixed  backdrop-contrast-50`}>

              <div className="w-[80vw] h-[80vh] fixed left-[10vw] top-[10vh] backdrop-contrast-50 overflow-none bg-white flex flex-col rounded-lg shadow-lg">
                <div className="flex p-2 gap-3 items-center border-b border-slate-200 dark:border-[#36363C] dark:bg-dark-300">
                  <div className="flex-1 text-[24px] pl-3 "> Ad Details </div>
                  <div
                    className="w-[32px] h-[32px] cursor-pointer z-20"
                    onClick={() => {
                      setSelectedAd(null);
                    }}
                  >
                    <IoClose size={32} />
                  </div>
                </div>  
                <div className="flex-1 flex overflow-auto p-4 gap-4 dark:bg-dark-200">
                  <div className="w-1/3 flex flex-col overflow-y-auto gap-4">
                    <div className="flex flex-col border border-slate-200 dark:border-[#36363C] rounded-lg">
                      <div className="text-[18px] p-2 bg-slate-200 dark:border-[#36363C] cursor-pointer flex justify-between items-center dark:bg-dark-300"
                        onClick={() => setSelected(selected === 1 ? 0 : 1)}>
                          <div>Input Text</div>
                          {selected == 1 ? <IoRemove /> : <IoAdd />}
                      </div>
                      <div className="w-full h-[1px] bg-slate-200 dark:bg-[#36363C]"></div>
                      {
                        selected == 1 && <div className="pl-3 p-2">
                          {selectedAd.inputText}
                        </div>
                      }
                    </div>
                    <div className="flex flex-col border border-slate-200 dark:border-[#36363C] rounded-lg">
                      <div className="text-[18px] p-2 bg-slate-200 dark:border-[#36363C] cursor-pointer flex justify-between items-center dark:bg-dark-300"
                        onClick={() => setSelected(selected === 2 ? 0 : 2)}>
                          <div>Input Image</div>
                          {selected == 2 ? <IoRemove /> : <IoAdd />}
                      </div>
                      <div className="w-full h-[1px] bg-slate-200 dark:bg-[#36363C]"></div>
                      {
                        selected == 2 && <div className="pl-3 p-2">
                          <img src={selectedAd.img_path_url} alt="Selected" className='rounded object-cover border dark:border-light-600 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none'/>
                        </div>
                      }
                    </div>
                    <div className="flex flex-col border border-slate-200 dark:border-[#36363C] rounded-lg">
                      <div className="text-[18px] p-2 bg-slate-200 dark:border-[#36363C] cursor-pointer flex justify-between items-center dark:bg-dark-300"
                        onClick={() => setSelected(selected === 3 ? 0 : 3)}>
                          <div>Input PDF</div>
                          {selected == 3 ? <IoRemove /> : <IoAdd />}
                      </div>
                      <div className="w-full h-[1px] bg-slate-200 dark:bg-[#36363C]"></div>
                      {
                        selected == 3 && <div className="pl-3 p-2 overflow-auto">{
                          pdfFiles.map((pdfFile, index) => {
                            const parts = pdfFile.split('/');
                            return <a target='blank' href={pdfFile}>
                              {parts[parts.length - 1].slice(15)}
                            </a>
                          })
                          // for showing pdf
                          //
                          // pdfFiles.map((pdfFile, index) => 
                          // <div className="relative" key={index}>
                          //   <button className='absolute right-4 top-4'>download</button>
                          //   <iframe src={pdfFile} className='w-full'/>
                          // </div>
                          // )
                        }</div>
                      }
                    </div>
                    {/* <div className="flex flex-col border border-slate-200 dark:border-[#36363C] rounded-lg">
                      <div className="text-[18px] p-2 bg-slate-200 dark:border-[#36363C] cursor-pointer flex justify-between items-center dark:bg-dark-300"
                        onClick={() => setSelected(4)}>
                          <div>Input Rule</div>
                          {selected == 4 ? <IoRemove /> : <IoAdd />}
                      </div>
                      <div className="w-full h-[1px] bg-slate-200 dark:bg-[#36363C]"></div>
                      {
                        selected == 4 && <div className="pl-3 p-2">Generate image of a luxurious room</div>
                      }
                    </div> */}
                  </div>
                  <div className="h-full w-2/3 overflow-y-auto margin-auto p-4">
                    <img src={selectedAd.result_source} alt="Selected" className='rounded object-cover border dark:border-light-600 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none'/>
                  </div>
                </div>
              </div>
            </div>,
            document.getElementById("root") as Element
          )}

        </div>
      )
      }
    </div>)
}

export default Ads
