/* eslint-disable */
// "use strict";
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.setAPI =
    exports.iconscoutDownload =
    exports.iconscoutList =
    exports.svgapiDownload =
    exports.svgapiList =
    exports.unsplashDownload =
    exports.unsplashList =
    exports.templateList =
    exports.removeBackground =
    exports.editorShapesList =
    exports.getGoogleFontImage =
    exports.getGoogleFontsListAPI =
    exports.URLS =
    exports._dangerous =
    exports.getAPI =
    exports.getDomain =
    exports.API =
    exports.URL =
      void 0);
const validate_key_1 = require("./validate-key");
(exports.URL = "https://api.polotno.com"),
  (exports.API = "https://api.polotno.com/api");
let DOMAIN = exports.URL;
const getDomain = () => DOMAIN;
exports.getDomain = getDomain;
const getAPI = () => DOMAIN + "/api";
exports.getAPI = getAPI;
const _dangerous = (e) => {
  DOMAIN = e;
};
exports._dangerous = _dangerous;
const showedWarnings = {},
  warn = (e, t) => {
    showedWarnings[e] || ((showedWarnings[e] = !0), console.error(t));
  },
  replaceAll = (e, t, o) => e.replace(new RegExp(t, "g"), o),
  ICONSCOUT_MESSAGE =
    "API for iconscout is provided as a demonstration.\nFor production usage you have to use your own API endpoint.\nhttps://iconscout.com/developers, https://iconscout.com/legal/api-license-development-agreement\nhttps://editor.com/docs/server-api";
exports.URLS = {
  unsplashList: ({ query: e, page: t = 1 }) =>
    `${(0,
    exports.getAPI)()}/get-unsplash?query=${e}&per_page=20&page=${t}&KEY=${(0,
    validate_key_1.getKey)()}`,
  unsplashDownload: (e) =>
    `${(0, exports.getAPI)()}/download-unsplash?id=${e}&KEY=${(0,
    validate_key_1.getKey)()}`,
  svgapiList: ({ query: e, page: t = 0 }) =>
    `${(0,
    exports.getAPI)()}/get-svgapi?query=${e}&page=${t}&per_page=20&KEY=${(0,
    validate_key_1.getKey)()}`,
  svgapiDownload: (e) =>
    `${(0, exports.getAPI)()}/download-svgapi?path=${e}&KEY=${(0,
    validate_key_1.getKey)()}`,
  iconscoutList: ({ query: e, page: t = 1 }) => (
    warn("iconscout", ICONSCOUT_MESSAGE),
    `${(0, exports.getAPI)()}/get-iconscout?query=${e}&page=${t}&KEY=${(0,
    validate_key_1.getKey)()}`
  ),
  iconscoutDownload: (e) => (
    warn("iconscout", ICONSCOUT_MESSAGE),
    `${(0, exports.getAPI)()}/download-iconscout?uuid=${e}&KEY=${(0,
    validate_key_1.getKey)()}`
  ),
  templateList: ({ query: e, page: t = 1, sizeQuery: o }) =>
    `${(0,
    exports.getAPI)()}/get-templates?${o}&query=${e}&per_page=30&page=${t}&KEY=${(0,
    validate_key_1.getKey)()}`,
};
const getGoogleFontsListAPI = () =>
  `${(0, exports.getAPI)()}/get-google-fonts?KEY=${(0,
  validate_key_1.getKey)()}`;
exports.getGoogleFontsListAPI = getGoogleFontsListAPI;
const getGoogleFontImage = (e) => {
  return `${(0, exports.getDomain)()}/google-fonts-previews/black/${
    ((t = e), (o = " "), (s = "-"), t.replace(new RegExp(o, "g"), s))
  }.png`;
  var t, o, s;
};
exports.getGoogleFontImage = getGoogleFontImage;
const editorShapesList = () =>
  `${(0, exports.getAPI)()}/get-basic-shapes?KEY=${(0,
  validate_key_1.getKey)()}`;
exports.editorShapesList = editorShapesList;
const removeBackground = () =>
  `${(0, exports.getAPI)()}/remove-image-background?KEY=${(0,
  validate_key_1.getKey)()}`;
exports.removeBackground = removeBackground;
const templateList = (e) => exports.URLS.templateList(e);
exports.templateList = templateList;
const unsplashList = (e) => exports.URLS.unsplashList(e);
exports.unsplashList = unsplashList;
const unsplashDownload = (e) => exports.URLS.unsplashDownload(e);
exports.unsplashDownload = unsplashDownload;
const svgapiList = (e) => exports.URLS.svgapiList(e);
exports.svgapiList = svgapiList;
const svgapiDownload = (e) => exports.URLS.svgapiDownload(e);
exports.svgapiDownload = svgapiDownload;
const iconscoutList = (e) => exports.URLS.iconscoutList(e);
exports.iconscoutList = iconscoutList;
const iconscoutDownload = (e) => exports.URLS.iconscoutDownload(e);
exports.iconscoutDownload = iconscoutDownload;
const setAPI = (e, t) => {
  exports.URLS[e] = t;
};
exports.setAPI = setAPI;
