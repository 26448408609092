import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { update_mode, sendmsg } from "../../actions/subscription";
import { useSearchParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const SuccessPage = () => {
  const { setSubScriptionLevel } = useAuth();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const id = searchParams.get("id");
  const name = searchParams.get("name") || "";
  const price = searchParams.get("price") || "";
  const isYearly = searchParams.get("isYearly") || false;
  const productId = searchParams.get("productId") || "";
  const token = searchParams.get('token')

  const navigate = useNavigate();

  const sendMsg = async () => {
    try {

      await sendmsg({ planname: name, token: token, subscriptionId: productId });

    } catch (error) {
      console.error("Error during send success message to the user:", error);
      throw error; // Rethrow the error for the caller to handle
    }
  };

  useEffect(() => {
    console.log(id)
    if (Number(id) !== 0)
      sendMsg();
    setIsLoading(false);
    
  }, []); // Empty dependency array means this effect runs once on mount

  const onsubmit = async () => {
    try {
      let credit, apikey;
      switch (id) {
        case "0":
          credit = 30;
          apikey = 2;
          break;
        case "1":
        case "4":
          credit = 300;
          apikey = 20;
          break;
        case "2":
        case "5":
          credit = 1000;
          apikey = 40;
          break;
        default:
          credit = 2000;
          apikey = 100;
      }
      await update_mode({ id: id, credit: credit, apikey: apikey, subscriptionId: productId });
    } catch (error) {
      console.error("Error during updating user subscription mode:", error);
      throw error; // Rethrow the error for the caller to handle
    }
    setSubScriptionLevel(Number(id))
    navigate("/pixelplusai");
  };
  return (
    <div>
      {isLoading ? (
        <div className="flex items-center justify-center mt-20">
          <HashLoader color="#e9e9e9"
        cssOverride={{}}
        loading
        size={120}
        speedMultiplier={1.5}/>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center mt-8 gap-8">
          <div className="w-2/3 rounded-lg bg-light-600 dark:bg-dark-200 text-white border dark:border-[#36363C] p-4 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
            <div className="gap-4 flex flex-col p-8">
              <div className="flex items-center gap-4">
                <img src="/image/Group 5157.png" alt="Brain Capital AI Logo" />
                <p className="text-[32px] md:text-[28px] font-michroma text-[#FF0000] dark:text-light-600 font-[600]">
                  Brain Capital AI
                </p>
              </div>
              <div>
                <p className="text-[24px] md: text-[20px] ml-12 text-black dark:text-light-600">
                  Success Paid: <span className="text-[#FF0000] dark:text-light-600">${Number(price).toLocaleString()} </span> USD
                </p>
              </div>
            </div>
            <hr className="border-[#36363C]" />
            <div className="bg-light-600 dark:bg-[#191D29] p-8 flex flex-col gap-4">
              <p className="text-[24px] md:text-[20px] text-black dark:text-light-600 font-[500]">Payment Details</p>
              <div className="dark:bg-[#262939] px-[40px] rounded text-[18px] text-black dark:text-light-600 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between border-b border-gray-600 py-[24px] border-dashed">
                    <span>Payment ID</span>
                    <span>{productId?.substring(4)}</span>
                  </div>
                  <div className="flex justify-between border-b border-gray-600 py-[24px] border-dashed">
                    <span>Total Amount</span>
                    <span>${Number(price).toLocaleString()}</span>
                  </div>
                  <div className="flex justify-between border-b border-gray-600 py-[24px] border-dashed">
                    <span>Paid for</span>
                    <span>
                      {name} {isYearly === "true" ? "Yearly" : "Monthly"} Plan
                    </span>
                  </div>
                  <div className="flex justify-between py-[24px] border-dashed">
                    <span>Paid to</span>
                    <span>Brain Capital AI</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button className="w-1/3 text-[24px] text-light-600 font-[500] [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none" onClick={onsubmit}>
            Start {name} Plan
          </Button>
        </div>
      )}
    </div>
  );
};

export default SuccessPage;
