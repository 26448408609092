import React, { useEffect, useState, useRef, useContext } from "react";
import {
  loadStripe,
  Stripe,
  StripeElements,
  Appearance,
} from "@stripe/stripe-js";
import { stripeKey } from "../../config";
import { useSubScriptionContext } from "../../hooks/useSubScription";
import {IPContext} from '../../hooks/IPContext';

interface PaymentProps {
  clientSecret: string;
}

const Payment = ({ clientSecret }: PaymentProps) => {
  const { id, name, price, isYearly, productId } = useSubScriptionContext();
  let stripe: Stripe | null = null;
  const stripePromise = loadStripe(stripeKey);
  const elementsRef = useRef<StripeElements | null>(null);
  const { darkMode } = useContext(IPContext);

  const [errorMessage, setErrorMessage] = useState("");
  const appearance: Appearance = darkMode ? {
    theme: "night",
    variables: {
      colorPrimary: "rgb(48 107 243)",
    },
    rules: {
      ".Checkbox": {
        display: "none",
      },
    },
  } : {
    theme: "stripe",
    variables: {
      colorPrimary: "rgb(48 107 243)",
    },
    rules: {
      ".Checkbox": {
        display: "none",
      },
    },
  };
  const options = {
    layout: "tabs",
    //   defaultCollapsed: false,
    payment_method_configuration: 'pmc_1PGddSL5zCDIYaQGu1YuGe37'
  };
  
  stripePromise.then((s) => {
    stripe = s;
    const elements = stripe?.elements({ clientSecret, appearance });
    elementsRef.current = elements ? elements : null;
    const paymentElement = elements?.create("payment", {
      layout: "tabs",
    });
    paymentElement?.mount("#payment-element");   
  });

  useEffect(() => {
    const form = document.getElementById("payment-form");
    var div = document.getElementById("Field-linkOptInCheckbox");

    // Check if div is not null before hiding it
    if (div) {
      div.style.display = "none";
    }

    const handleSubmit = async (event: Event) => {
      event.preventDefault();

      if (!stripe || !elementsRef.current) {
        return;
      }
      // const urlParams = new URLSearchParams(window.location.search);
      // const token = urlParams.get('token');

      const token = sessionStorage.getItem("auth-token");

      const queryParams = new URLSearchParams({
        id: id.toString(),
        name: name.toString(),
        price: price.toString(),
        productId: productId.toString(),
        isYearly: isYearly.toString(),
        token: token ? token : "",
      }).toString();

      // const returnURL = `http://localhost:3000/pixelplusai/subscription/success?${queryParams}`;
      // const returnURL = `https://testbcai.eastus2.cloudapp.azure.com/pixelplusai/subscription/success?${queryParams}`;
      const returnURL = `https://app.braincapital.ai/pixelplusai/subscription/success?${queryParams}`;

      const { error } = await stripe?.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements: elementsRef.current,
        confirmParams: {
          return_url: returnURL,
        },
      });

      if (error) {
        setErrorMessage(error.message ? error.message : "");
      } else {
        // handle successful payment
      }
    };
    form?.addEventListener("submit", handleSubmit);

    // Cleanup function to remove event listener
    return () => {
      form?.removeEventListener("submit", handleSubmit);
    };
  }, [stripe, elementsRef.current, id, price, isYearly, productId]);
  return (
    <form id="payment-form" className="flex flex-col gap-4">
      <div id="payment-element"></div>
      <button
        id="submit"
        className="text-white w-full bg-primary p-4 rounded text-lg"
      >
        Purchase Plan
      </button>
      <div
        id="error-message"
        className={`text-red-500 p-2 w-1/2 rounded ${
          errorMessage ? "border border-red-500" : ""
        }`}
      >
        {errorMessage}
      </div>
    </form>
  );
};

export default Payment;
