import React, { useState, useContext } from "react";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import { Link, useNavigate } from "react-router-dom";
import LoginNavbar from "../../../components/LoginNavbar";
import { forgotpassword } from "../../../actions/auth";
import { toast } from "react-toastify";
import { IPContext } from "../../../hooks/IPContext";

type ForgotPasswordState = {
  email: string;
};

const ForgotPassword = () => {
  const { darkMode } = useContext(IPContext)
  const navigate = useNavigate();
  const [forgotPasswordState, setForgotPasswordState] =
    useState<ForgotPasswordState>({
      email: "",
    });

  const onChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForgotPasswordState((prv) => ({
      ...prv,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async () => {
    // Here you would typically call a function to send a reset password email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(forgotPasswordState.email)) {
      toast.error("Please enter a valid email address!");
      return;
    }
    try {
      console.log(forgotPasswordState.email);
      await forgotpassword(forgotPasswordState.email);
      toast.success("Check your email address to reset password.");
      navigate("/login");
    } catch (e: any) {
      if (e.message.includes("404")) {
        toast.error("The email address is incorrect!");
      } else {
        toast.error("Failed sending request.");
      }
      console.error(e);
    }

    navigate("/reset-password"); // navigate to reset password page
  };

  return (
    <div className={`${darkMode? "dark": ""}`}>
      <LoginNavbar />
      <div className="w-full h-[100vh] flex items-center justify-center bg-light-500 dark:bg-dark-300">
        <div className="w-[500px] flex flex-col gap-7">
          <div>
            <h3 className="text-center text-[1.7rem] text-[#ff0000] dark:text-light-600 font-[600]">
              Forgot Password
            </h3>
            <p className="text-center text-black dark:text-light-200">Please enter your email address</p>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="text-black dark:text-light-600">
              Email:{" "}
            </label>
            <TextInput
              id="email"
              name="email"
              placeholder="@mail.com"
              value={forgotPasswordState?.email}
              onChange={onChangeState}
              className="px-4 border-[1px] border-solid dark:border-[#3B4451] border-light-600"
            />
          </div>
          <Button
            className="w-full flex justify-center items-center"
            onClick={onSubmit}
          >
            <p className="flex items-center gap-2 text-light-600">Confirm your Email</p>
          </Button>
          <div className="flex justify-center gap-2">
            <p className="text-center text-black dark:text-light-200">Remember your password?</p>
            <Link to="/login">
              <p className="text-primary">Sign in</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
