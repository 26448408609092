/* eslint-disable */
// "use strict";
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.unstable_setAnimationsEnabled =
    exports.unstable_setTextVerticalResizeEnabled =
    exports.unstable_setTextOverflow =
    exports.unstable_setForceTextFit =
    exports.unstable_useHtmlTextRender =
    exports.unstable_setRemoveBackgroundEnabled =
    exports.useDownScaling =
    exports.setColorsPresetFunc =
    exports.setAPI =
    exports.getGoogleFontImage =
    exports.getGoogleFontsListAPI =
    exports.unstable_registerNextDomDrop =
    exports.getTranslations =
    exports.setTranslations =
    exports.setFontLoadTimeout =
    exports.setAssetLoadTimeout =
    exports.onLoadError =
    exports.unstable_registerToolbarComponent =
    exports.unstable_registerTransformerAttrs =
    exports.unstable_registerShapeComponent =
    exports.unstable_registerShapeModel =
    exports.unstable_setImageLoaderHook =
    exports.setTransformerStyle =
    exports.setHighlighterStyle =
    exports.toggleFadeInAnimation =
    exports.setUploadFunc =
    exports.setGoogleFontsVariants =
    exports.replaceGlobalFonts =
    exports.removeGlobalFont =
    exports.addGlobalFont =
    exports.setGoogleFonts =
      void 0);
var fonts_1 = require("./utils/fonts");
Object.defineProperty(exports, "setGoogleFonts", {
  enumerable: !0,
  get: function () {
    return fonts_1.setGoogleFonts;
  },
}),
  Object.defineProperty(exports, "addGlobalFont", {
    enumerable: !0,
    get: function () {
      return fonts_1.addGlobalFont;
    },
  }),
  Object.defineProperty(exports, "removeGlobalFont", {
    enumerable: !0,
    get: function () {
      return fonts_1.removeGlobalFont;
    },
  }),
  Object.defineProperty(exports, "replaceGlobalFonts", {
    enumerable: !0,
    get: function () {
      return fonts_1.replaceGlobalFonts;
    },
  }),
  Object.defineProperty(exports, "setGoogleFontsVariants", {
    enumerable: !0,
    get: function () {
      return fonts_1.setGoogleFontsVariants;
    },
  });
var upload_panel_1 = require("./side-panel/upload-panel");
Object.defineProperty(exports, "setUploadFunc", {
  enumerable: !0,
  get: function () {
    return upload_panel_1.setUploadFunc;
  },
});
var use_fadein_1 = require("./canvas/use-fadein");
Object.defineProperty(exports, "toggleFadeInAnimation", {
  enumerable: !0,
  get: function () {
    return use_fadein_1.toggleFadeInAnimation;
  },
});
var highlighter_1 = require("./canvas/highlighter");
Object.defineProperty(exports, "setHighlighterStyle", {
  enumerable: !0,
  get: function () {
    return highlighter_1.setHighlighterStyle;
  },
});
var page_1 = require("./canvas/page");
Object.defineProperty(exports, "setTransformerStyle", {
  enumerable: !0,
  get: function () {
    return page_1.setTransformerStyle;
  },
});
var image_element_1 = require("./canvas/image-element");
Object.defineProperty(exports, "unstable_setImageLoaderHook", {
  enumerable: !0,
  get: function () {
    return image_element_1.setImageLoaderHook;
  },
});
var group_model_1 = require("./model/group-model");
Object.defineProperty(exports, "unstable_registerShapeModel", {
  enumerable: !0,
  get: function () {
    return group_model_1.registerShapeModel;
  },
});
var element_1 = require("./canvas/element");
Object.defineProperty(exports, "unstable_registerShapeComponent", {
  enumerable: !0,
  get: function () {
    return element_1.registerShapeComponent;
  },
});
var page_2 = require("./canvas/page");
Object.defineProperty(exports, "unstable_registerTransformerAttrs", {
  enumerable: !0,
  get: function () {
    return page_2.registerTransformerAttrs;
  },
});
var toolbar_1 = require("./toolbar/toolbar");
Object.defineProperty(exports, "unstable_registerToolbarComponent", {
  enumerable: !0,
  get: function () {
    return toolbar_1.registerToolbarComponent;
  },
});
var loader_1 = require("./utils/loader");
Object.defineProperty(exports, "onLoadError", {
  enumerable: !0,
  get: function () {
    return loader_1.onLoadError;
  },
}),
  Object.defineProperty(exports, "setAssetLoadTimeout", {
    enumerable: !0,
    get: function () {
      return loader_1.setAssetLoadTimeout;
    },
  }),
  Object.defineProperty(exports, "setFontLoadTimeout", {
    enumerable: !0,
    get: function () {
      return loader_1.setFontLoadTimeout;
    },
  });
var l10n_1 = require("./utils/l10n");
Object.defineProperty(exports, "setTranslations", {
  enumerable: !0,
  get: function () {
    return l10n_1.setTranslations;
  },
}),
  Object.defineProperty(exports, "getTranslations", {
    enumerable: !0,
    get: function () {
      return l10n_1.getTranslations;
    },
  });
var page_3 = require("./canvas/page");
Object.defineProperty(exports, "unstable_registerNextDomDrop", {
  enumerable: !0,
  get: function () {
    return page_3.registerNextDomDrop;
  },
});
var api_1 = require("./utils/api");
Object.defineProperty(exports, "getGoogleFontsListAPI", {
  enumerable: !0,
  get: function () {
    return api_1.getGoogleFontsListAPI;
  },
}),
  Object.defineProperty(exports, "getGoogleFontImage", {
    enumerable: !0,
    get: function () {
      return api_1.getGoogleFontImage;
    },
  }),
  Object.defineProperty(exports, "setAPI", {
    enumerable: !0,
    get: function () {
      return api_1.setAPI;
    },
  });
var color_picker_1 = require("./toolbar/color-picker");
Object.defineProperty(exports, "setColorsPresetFunc", {
  enumerable: !0,
  get: function () {
    return color_picker_1.setColorsPresetFunc;
  },
});
var flags_1 = require("./utils/flags");
Object.defineProperty(exports, "useDownScaling", {
  enumerable: !0,
  get: function () {
    return flags_1.useDownScaling;
  },
});
var flags_2 = require("./utils/flags");
Object.defineProperty(exports, "unstable_setRemoveBackgroundEnabled", {
  enumerable: !0,
  get: function () {
    return flags_2.useRemoveBackground;
  },
});
var flags_3 = require("./utils/flags");
Object.defineProperty(exports, "unstable_useHtmlTextRender", {
  enumerable: !0,
  get: function () {
    return flags_3.useHtmlTextRender;
  },
});
var flags_4 = require("./utils/flags");
Object.defineProperty(exports, "unstable_setForceTextFit", {
  enumerable: !0,
  get: function () {
    return flags_4.setForceTextFit;
  },
});
var flags_5 = require("./utils/flags");
Object.defineProperty(exports, "unstable_setTextOverflow", {
  enumerable: !0,
  get: function () {
    return flags_5.setTextOverflow;
  },
});
var flags_6 = require("./utils/flags");
Object.defineProperty(exports, "unstable_setTextVerticalResizeEnabled", {
  enumerable: !0,
  get: function () {
    return flags_6.setTextVerticalResizeEnabled;
  },
});
var flags_7 = require("./utils/flags");
Object.defineProperty(exports, "unstable_setAnimationsEnabled", {
  enumerable: !0,
  get: function () {
    return flags_7.setAnimationsEnabled;
  },
});
