import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { unauthenticatedUrl } from "./config";
import useAuth from "./hooks/useAuth";

const ProtectedRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate(unauthenticatedUrl);
    }
  }, [isAuthenticated, navigate, isLoading]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default ProtectedRoute;
