// "use strict";
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.getJsPDF = void 0);
const url = "https://unpkg.com/jspdf@2.2.0/dist/jspdf.umd.min.js";
let jsPDF;
function getJsPDF() {
  return jsPDF
    ? Promise.resolve(jsPDF)
    : new Promise((e) => {
        var s = document.createElement("script");
        (s.onload = function () {
          (jsPDF = window.jspdf.jsPDF), e(jsPDF);
        }),
          (s.src = url),
          document.head.appendChild(s);
      });
}
exports.getJsPDF = getJsPDF;
