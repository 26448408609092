import ContentEditable from "react-contenteditable";
import styled from "styled-components";

export const TextArea = styled(ContentEditable)`
  cursor: text;
  padding: 20px;
  overflow-y: auto;
  color: black;
  &:focus {
    outline: none;
  }
`;

export const TextContainer = styled.div<{ placeholder?: string, darkMode? : boolean }>`
  display: flex;
  overflow-y: auto;

  [contenteditable]:empty::before {
    content: "${(props) => props.placeholder}";
    color: ${(props) => props.darkMode ? "#9fa2af" : "#757373"};
  }
`;
