import React from "react";

interface SeparatorProps {
  color?: string;
  direction?: "horizontal" | "vertical";
  text?: string;
}

const Separator: React.FC<SeparatorProps> = ({
  color = "border-dark-700",
  direction = "horizontal",
  text,
}) => {
  const lineStyle =
    direction === "horizontal" ? "w-1/2 mx-2 h-px" : "h-1/2 mx-2 w-px";
  const lineColor = color;

  return (
    <div className="flex items-center justify-between">
      <hr className={`${lineStyle} ${lineColor}`} />
      {text && <span className="text-black dark:text-light-200">{text}</span>}
      <hr className={`${lineStyle} ${lineColor}`} />
    </div>
  );
};

export default Separator;
