import { FaPlus } from "react-icons/fa";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import { usePPAContext } from "../../../hooks/usePPAContext";
import { TextArea, TextContainer } from "../../../components/TextArea";
import React, {
  ClipboardEvent,
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  ClipboardEventHandler,
} from "react";
import { ContentEditableEvent } from "react-contenteditable";
import { HexColorPicker } from "react-colorful";
import { MdDelete } from "react-icons/md";
import ReactDOM from "react-dom";
import Loading from "../../../components/Loading";

const ElementsDetails = () => {
  const {
    pdfFiles,
    isBusy,
    rule,
    setRule,
    colors,
    addColor,
    removeColor,
    addFont,
    fonts,
    removeFont,
    setPdfFiles,
    goToStep,
  } = usePPAContext();
  const [currentColorName, setCurrentColorName] = useState<string>("");
  const [currentColor, setCurrentColor] = useState<string>("#FFFFFF");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const colorPanelRef = useRef<HTMLDivElement | null>(null);
  const [input, setInput] = useState(rule);

  const onTextInput = useCallback(
    (e: ContentEditableEvent) => {
      if (!isBusy) {
        const trimmedValue = e.target.value.replace(/&nbsp;/g, " ").trim();
        setInput(trimmedValue);
      }
    },
    [isBusy, setInput]
  );

  useEffect(() => {
    setRule(input);
  }, [input, setRule]);

  const onColorClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const onDeleteColor = (colorName: string) => () => {
    removeColor(colorName);
  };

  const onAddColor = () => {
    if (currentColorName || currentColor) {
      addColor({
        colorName: currentColorName,
        colorCode: currentColor,
      });
      setCurrentColor("#ffffff");
      setCurrentColorName("");
    }
  };

  const onUploadFont = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      Array.from(files).forEach((file) => {
        const fontName = file.name.split(".").slice(0, -1).join(".");
        const reader = new FileReader();
        reader.onload = (e) => {
          const fontFace = new FontFace(
            fontName,
            e.target?.result as ArrayBuffer
          );
          fontFace.load().then((loadedFontFace) => {
            addFont({ fontName, fontFace: loadedFontFace, fontFile: file });
          });
        };
        reader.readAsArrayBuffer(file);
      });
    }
  };

  const onDeleteFont = (fontName: string) => () => {
    removeFont(fontName);
  };

  const onUploadPDF = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setPdfFiles(files);
      
    }
  };

  const onDiscard = () => {
    setRule("");
    setPdfFiles(null);
    while (colors.length > 0) {
      removeColor(colors[0].colorName);
    }
    while (fonts.length > 0) {
      removeFont(fonts[0].fontName);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        colorPanelRef.current &&
        !colorPanelRef.current.contains(event.target as Node)
      ) {
        setAnchorEl(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCopy = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const text = window.getSelection()?.toString() || "";
    event.clipboardData.setData("text/plain", text);
  };

  // const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
  //   event.preventDefault();
  //   const text: string = event.clipboardData.getData("text/plain");
  //   setInput((prevInput) => prevInput + text);
  // };

  const handlePaste: ClipboardEventHandler = (event) => {
    event.preventDefault();

    const text = event.clipboardData?.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  };

  return (
    <>
      <div className="bg-white dark:bg-dark-200 w-full p-5 border dark:border-dark-700 rounded justify-between flex items-center [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
        <input
          type="file"
          accept=".pdf"
          onChange={onUploadPDF}
          style={{ display: "none" }}
          id="pdf-upload"
          multiple
        />

        {pdfFiles && pdfFiles.length > 0 ? (
          <p className="text-[#FF0000] dark:text-light-200">{pdfFiles[0].name}</p>
        ) : (
          <p className="text-[#FF0000] dark:text-light-200">No file selected</p>
        )}
        <Button
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("pdf-upload")?.click();
          }}
          disabled={isBusy}
          className="text-light-600"
        >
          From PDF
        </Button>
      </div>
      <div className="relative rounded overflow-y-auto flex-1 w-full [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
        <Loading
          loading={isBusy}
          className={"absolute rounded w-full h-full border dark:border-dark-700"}
        />
        <div className="w-full h-full flex-1 overflow-clip flex justify-between gap-3">
          <div className="bg-light-600 dark:bg-dark-200 w-1/3 h-full rounded p-5 flex flex-col overflow-clip border dark:border-dark-700 justify-between gap-3">
            <h3 className="text-[#FF0000] dark:text-light-600">Brand Colors</h3>
            <div className="w-full flex-1 overflow-y-auto overflow-x-clip flex flex-col">
              <div className="w-full flex-col flex items-center justify-between gap-3 py-3">
                {colors.map((color, index) => (
                  <div
                    key={index}
                    className="w-full flex gap-1 items-center justify-between"
                  >
                    <span
                      className="w-[35px] h-[35px] rounded cursor-pointer"
                      style={{
                        backgroundColor: color.colorCode,
                      }}
                    ></span>
                    <TextInput
                      placeholder="Color Name"
                      className="min-w-0 flex-1 py-[0.35rem]"
                      value={color.colorName}
                      readOnly
                    />
                    <TextInput
                      placeholder="Color Code"
                      className="min-w-0 flex-1 py-[0.35rem]"
                      value={color.colorCode}
                      readOnly
                    />
                    <Button
                      className="px-2"
                      variant="outline"
                      onClick={onDeleteColor(color.colorName)}
                      disabled={isBusy}
                    >
                      <MdDelete />
                    </Button>
                  </div>
                ))}
                <div className="w-full flex gap-1 items-center justify-between">
                  <span
                    className="w-[35px] h-[35px] rounded cursor-pointer border border-2 border-light-200 dark:border-light-600"
                    style={{
                      backgroundColor: currentColor,
                    }}
                    onClick={onColorClick}
                  ></span>
                  <TextInput
                    placeholder="Color Name"
                    className="min-w-0 flex-1 py-[0.35rem]"
                    value={currentColorName}
                    onChange={(e) => setCurrentColorName(e.target.value)}
                    disabled={isBusy}
                  />
                  <TextInput
                    placeholder="Color Code"
                    className="min-w-0 flex-1 py-[0.35rem]"
                    value={currentColor}
                    onChange={(e) => setCurrentColor(e.target.value)}
                    disabled={isBusy}
                  />
                  <Button
                    className="px-2 text-light-600"
                    disabled={!currentColor || !currentColorName || isBusy}
                    onClick={onAddColor}
                  >
                    <FaPlus />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-light-600 dark:bg-dark-200 w-1/3 h-full rounded p-5 flex flex-col overflow-clip border dark:border-dark-700 justify-between gap-3">
            <h3 className="text-[#FF0000] dark:text-light-600">
              Fonts <span className="text-[#ff7575] dark:text-light-400">(Upload font files)</span>
            </h3>
            <div className="w-full flex-1 overflow-clip flex flex-col gap-5">
              <input
                type="file"
                accept=".ttf,.otf,.woff,.woff2"
                onChange={onUploadFont}
                style={{ display: "none" }}
                id="font-upload"
                multiple
              />
              <div className="w-full flex-1 flex flex-col items-center gap-3 overflow-y-auto overflow-x-hidden py-3">
                {fonts &&
                  fonts.length > 0 &&
                  fonts.map((font) => (
                    <div
                      key={font.fontName}
                      className="w-full flex gap-1 items-center justify-between"
                    >
                      <TextInput
                        placeholder="Font Name"
                        className="flex-1 py-[0.35rem] min-w-0"
                        value={font.fontName}
                        style={{
                          fontFamily: font.fontFace.family,
                        }}
                        readOnly
                      />
                      <Button
                        className="px-2"
                        variant="outline"
                        onClick={onDeleteFont(font.fontName)}
                        disabled={isBusy}
                      >
                        <MdDelete />
                      </Button>
                    </div>
                  ))}
                <div className="w-full flex gap-1 items-center justify-between">
                  <TextInput
                    placeholder="Font Name"
                    className="flex-1 py-[0.35rem] min-w-0"
                    readOnly
                  />
                  <Button
                    className="px-2 text-light-600"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("font-upload")?.click();
                    }}
                    disabled={isBusy}
                  >
                    <FaPlus />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-light-600 dark:bg-dark-200 w-1/3 h-full rounded p-5 flex flex-col overflow-clip border dark:border-dark-700 justify-between gap-3">
            <h3 className="text-[#FF0000] dark:text-light-600">Rules</h3>
            <TextContainer
              className={`rounded flex-1 overflow-clip mt-3 border border-1 border-[#EBE6E6] ${
                isBusy
                  ? "dark:bg-dark-400 cursor-not-allowed"
                  : "cursor-text bg-[#F4F4F4] dark:bg-dark-600 "
              }`}
              placeholder="Write rules here"
            >
              <TextArea
                html={input}
                onChange={onTextInput}
                onCopy={handleCopy}
                onPaste={handlePaste}
                className={`w-full border border-transparent focus:border-[#FF0000] dark:focus:border-primary rounded dark:text-light-200`}
                disabled={isBusy}
              />
            </TextContainer>
          </div>
        </div>
      </div>
      <div className="bg-light-600 dark:bg-dark-200 w-full p-5 border dark:border-dark-700 rounded flex flex-row-reverse items-center gap-3">
        <Button onClick={goToStep("creation")} className="text-light-600">Next Step</Button>
        <Button variant="outline" onClick={onDiscard}>
          Discard
        </Button>
      </div>
      {anchorEl !== null &&
        ReactDOM.createPortal(
          <div
            ref={colorPanelRef}
            className="absolute z-50"
            style={{
              left: anchorEl.getBoundingClientRect().left,
              top: anchorEl.getBoundingClientRect().top + 40,
            }}
          >
            <HexColorPicker color={currentColor} onChange={setCurrentColor} />
          </div>,
          document.getElementById("root") as Element
        )}
    </>
  );
};

export default ElementsDetails;
