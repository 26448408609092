import React, { useState } from "react";
import { usePPAContext } from "../../../hooks/usePPAContext";
import SummaryItem from "./SummaryItem";

const PPASidePanel = () => {
  const { summaries } = usePPAContext();
  const [save, setSave] = useState("saving...");

  return (
    <React.Fragment>
      <div className="">
        <h3 className="text-[#FF0000] dark:text-light-600 text-[1.3rem] font-bold">
          Auto-generated Ad Content&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="text-light-200 text-[0.7rem]">{save}</span>
        </h3>
      </div>
      <div className="bg-dark-700 w-full h-[1px]"></div>
      <div className="overflow-y-auto gap-2 flex flex-col pr-2">
        {summaries.map((summary, index) => (
          <div key={index}>
            <SummaryItem index={index} summary={summary} setSave={setSave} />
            <div className="bg-dark-700 w-full h-[1px]"></div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default PPASidePanel;
