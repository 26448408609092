// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    min-height: -webkit-fill-available;
  }
  
  body {
    padding: 0;
    margin: 0;
    /* background-color: #383e47; */
    padding-bottom: env(safe-area-inset-bottom);
  }

  .bp5-navbar {
    z-index: 0 !important;
  }
  
  .bp5-dark {
    background-color: #30404d;
  }
  #root {
    width: 100vw;
    height: 100%;
  }
  
  #tool_bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 35px;
    z-index: 9999; /* Adjust this value as needed */
    background-color: #2f343c;
    border-top: solid 1px lightgrey;
  }
  
  @media (max-width: 500px) {
    .bp5-dark .bp5-navbar {
      box-shadow: none;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/editor/index.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;EACpC;;EAEA;IACE,UAAU;IACV,SAAS;IACT,+BAA+B;IAC/B,2CAA2C;EAC7C;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,eAAe;IACf,SAAS;IACT,WAAW;IACX,YAAY;IACZ,aAAa,EAAE,gCAAgC;IAC/C,yBAAyB;IACzB,+BAA+B;EACjC;;EAEA;IACE;MACE,gBAAgB;IAClB;EACF","sourcesContent":["html {\n    min-height: -webkit-fill-available;\n  }\n  \n  body {\n    padding: 0;\n    margin: 0;\n    /* background-color: #383e47; */\n    padding-bottom: env(safe-area-inset-bottom);\n  }\n\n  .bp5-navbar {\n    z-index: 0 !important;\n  }\n  \n  .bp5-dark {\n    background-color: #30404d;\n  }\n  #root {\n    width: 100vw;\n    height: 100%;\n  }\n  \n  #tool_bottom {\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    height: 35px;\n    z-index: 9999; /* Adjust this value as needed */\n    background-color: #2f343c;\n    border-top: solid 1px lightgrey;\n  }\n  \n  @media (max-width: 500px) {\n    .bp5-dark .bp5-navbar {\n      box-shadow: none;\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
