import { usePPAContext, } from "../../../hooks/usePPAContext";
import ReactDOM from "react-dom";
import Button from "../../../components/Button";
import { MdClose, MdOutlineNavigateNext } from "react-icons/md";
import AnimatedButton from "../../../components/AnimatedButton";
import { FaDownload } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { IPContext } from '../../../hooks/IPContext';

const ImageViewer = ({
  index,
  onNext,
  onPrevious,
  onClose,
}: {
  index: number;
  onNext: () => void;
  onPrevious: () => void;
  onClose: () => void;
}) => {
  const { generatedImages } = usePPAContext();
  const { generatedHTML } = usePPAContext();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const { darkMode } = useContext(IPContext);

  const handleClose = () => {
    onClose();
  };

  const handleDownloadImage = () => {
    const imageData = generatedImages[index];
    const downloadLink = document.createElement("a");
    downloadLink.href = `data:image/png;base64,${imageData}`;
    downloadLink.download = `result${index}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDownloadHTML = () => {
    const HTMLData = generatedHTML[index];
    const blob = new Blob([HTMLData], { type: "text/html" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `result${index}.html`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return index >= 0
    ? ReactDOM.createPortal(
      <div className={`${darkMode? "dark": ""}`}><div className="w-[100vw] h-[100vh] fixed left-0 top-0 backdrop-contrast-50 flex justify-center items-center gap-9 overflow-auto">
      <AnimatedButton onClick={onPrevious}>
        <MdOutlineNavigateNext
          style={{ transform: "rotate(180deg)" }}
          color="white"
          size="5em"
        />
      </AnimatedButton>
      <div className="p-5 bg-light-600 dark:bg-dark-100 rounded flex flex-col gap-5 shadow max-h-[90vh] overflow-auto">
        <div className="text-[1.5rem] text-[#ff0000] dark:text-light-600 flex justify-between items-center font-[600]">
          Result {index + 1}
          <AnimatedButton onClick={handleClose}>
            <MdClose className="text-black dark:text-light-600"/>
          </AnimatedButton>
        </div>
        <div className="flex-1 relative">
          <img
            className="rounded max-h-[70vh] max-w-[calc(100vw-40px)] object-contain"
            src={`data:image/png;base64,${generatedImages[index]}`}
            alt="Result image"
          />
          <Button className="absolute top-5 right-5 text-light-600" onClick={() => { navigate(`/pixelplusai/design-editor?action=edit_template&index=${index}`);}}>
            <FaEdit />
          </Button>
          <Button
            className="absolute top-16 right-5 text-light-600"
            // onClick={() => setShowDropdown(!showDropdown)} // Toggle dropdown visibility
            onClick={() => handleDownloadImage()} // Toggle dropdown visibility
          >
            <FaDownload />
          </Button>
          {showDropdown && (
            <div className="absolute top-20 right-5 bg-white shadow-md rounded border border-gray-200">
              <ul className="text-sm font-medium text-gray-700">
                <li
                  className="p-3 hover:bg-gray-100 cursor-pointer flex items-center justify-between"
                  onClick={() => {
                    handleDownloadImage();
                    setShowDropdown(false); // Hide dropdown after click
                  }}
                >
                  Download as an image
                  <FaDownload className="ml-2" />
                </li>
                <li
                  className="p-3 hover:bg-gray-100 cursor-pointer flex items-center justify-between"
                  onClick={() => {
                    handleDownloadHTML();
                    setShowDropdown(false); // Hide dropdown after click
                  }}
                  style={{ pointerEvents: 'none', opacity: 0.5 }}
                >
                  Download HTML
                  <FaDownload className="ml-2" />
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="flex justify-center items-center">
          <p className="font-michroma font-bold text-black dark:text-light-200">
            {index + 1} / {generatedImages.length}
          </p>
        </div>
      </div>
      <AnimatedButton onClick={onNext}>
        <MdOutlineNavigateNext color="white" size="5em" />
      </AnimatedButton>
    </div></div>
      ,
        document.getElementById("root") as Element
      )
    : null;
};

export default ImageViewer;
