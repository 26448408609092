import React, { useContext, useState } from "react";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import ReCAPTCHA from "react-google-recaptcha";
import LoginNavbar from "../../../components/LoginNavbar";
import Loading from "../../../components/Loading";
import { unauthenticatedUrl } from "../../../config";
import { toast } from "react-toastify";
import { IPContext } from "../../../hooks/IPContext";
import axios, { AxiosError } from "axios";

type SignupState = {
  username: string;
  email: string;
  password: string;
  confirmPass: string;
};

const sendEmail = async (email: string, username: string, token: string) => {
  try {
    const response = await axios.post(
      "/auth/confirm_email",
      { email, username, token },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error("Error sending email:", axiosError);
    throw axiosError;
  }
};

const Register = () => {
  const navigate = useNavigate();
  const { signup, isLoading, setLoading } = useAuth();
  const [signupState, setSignupState] = useState<SignupState>({
    username: "",
    email: "",
    password: "",
    confirmPass: "",
  });

  const { ip, darkMode } = useContext(IPContext);

  const onChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignupState((prv) => ({
      ...prv,
      [e.target.name]: e.target.value,
    }));
  };

  const [captchaValue, setCaptchaValue] = useState<string | null>(null);

  const handleCaptchaResponse = (value: string | null) => {
    setCaptchaValue(value);
  };

  const onSubmit = async () => {
    if (!captchaValue) {
      toast.error("Please verify you are not a robot!");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(signupState.email)) {
      toast.error("Please enter a valid email address!");
      return;
    }
    
    if (
      signupState.password &&
      signupState.confirmPass &&
      signupState.password !== signupState.confirmPass
    ) {
      toast.error("Passwords do not match!");
      return;
    }

    try {
      setLoading(true);
      const token = await signup(
        signupState.email,
        signupState.password,
        signupState.username
      );

      if (typeof token === 'undefined') {
        throw new Error('Token is undefined');
      }

      // Only send email if signup is successful
      try {
        await sendEmail(signupState?.email, signupState?.username, token);
        toast.success('Your request to register is sent successfully, Please verify your email to get started!');
        navigate(unauthenticatedUrl);
      } catch (error) {
        console.error("Error sending email:", error);
        toast.error("Error sending email. Please try again.");
      } finally {
        setLoading(false);
      }
    } catch (error: any) {
      console.error("Error during signup:", error);
      toast.error(error.message);
      return;
    }
  };

  return (
    <div className={`${darkMode? "dark": ""}`}>
      <LoginNavbar />
      <div className="w-full h-[100vh] flex items-center justify-center bg-light-500 dark:bg-dark-300">
        <div className="w-[500px] flex flex-col gap-7">
          <div>
            <h3 className="text-center text-[1.7rem] text-[#ff0000] dark:text-light-600">
              Welcome to Brain Capital AI
            </h3>
            <p className="text-center text-black dark:text-light-200">To get started, please register</p>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="username" className="text-black dark:text-light-600">
              Username:
            </label>
            <TextInput
              id="username"
              name="username"
              placeholder="Username"
              value={signupState?.username}
              onChange={onChangeState}
              className="px-4 border-[1px] border-solid dark:border-[#3B4451] border-light-600"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="text-black dark:text-light-600">
              Email:
            </label>
            <TextInput
              id="email"
              name="email"
              placeholder="Email"
              value={signupState?.email}
              onChange={onChangeState}
              className="px-4 border-[1px] border-solid dark:border-[#3B4451] border-light-600"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="password" className="text-black dark:text-light-600">
              Password:
            </label>
            <TextInput
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              value={signupState?.password}
              onChange={onChangeState}
              className="px-4 border-[1px] border-solid dark:border-[#3B4451] border-light-600"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="confirmPass" className="text-black dark:text-light-600">
              Confirm Password:
            </label>
            <TextInput
              type="password"
              id="confirmPass"
              name="confirmPass"
              placeholder="Password"
              value={signupState?.confirmPass}
              onChange={onChangeState}
              className="px-4 border-[1px] border-solid dark:border-[#3B4451] border-light-600"
            />
          </div>
          <div className="flex items-center justify-center mt-4">
            <ReCAPTCHA
              // sitekey="6LczlNMpAAAAADL8icI3nHGRk9kCulitgXkNVWHC"
              sitekey="6LdLlNMpAAAAALtJr4ENP4babCcBtqLtihKo-Fli"
              onChange={handleCaptchaResponse}
            />
          </div>
          <Button
            className="w-full flex justify-center items-center mt-6"
            onClick={onSubmit}
            disabled={isLoading}
          >
            <p className="flex items-center gap-2 text-light-600">Request to Register</p>
          </Button>
          <div className="flex justify-center gap-2">
            <p className="text-center text-black dark:text-light-200">Already have an account?</p>
            <Link to="/login">
              <p className="text-primary">Sign in</p>
            </Link>
          </div>
        </div>
      </div>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: This will add a semi-transparent dark overlay
          }}
        >
          <Loading loading={isLoading} />
        </div>
      )}
    </div>
  );
};

export default Register;
