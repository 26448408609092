import React, { useEffect } from "react";
import { createStore } from "./resource/model/store";
import { createProject, ProjectContext } from "./project";
import { useSearchParams } from "react-router-dom";
import "./index.css";
import App from "./App";
import { usePPAContext } from "../../hooks/usePPAContext";
import ErrorBoundary from "../../ErrorBoundary";

const store = createStore({ key: "ypaaMt9xcNGlxKDkzW5F" });
window.store = store;
store.clear();
store.addPage();
const project = createProject({ store });
window.project = project;

const Editor = () => {
  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");
  const index = searchParams.get("index");
  const { generatedJson, generatedURLs } = usePPAContext();

  useEffect(() => {
    const loadDesign = async () => {
      if (action === "edit_template" && index) {
        try {
          const generated = generatedJson[Number(index)];
          const generated_json = JSON.parse(generated);
          await project.createNewDesign();
          store.loadJSON(generated_json);
          project.save();
        } catch (err) {
          console.error(err);
        }
      } else {
        // If there are no parameters in the URL, create a new project and store
        store.clear();
        store.addPage();
        const project = createProject({ store });
        window.project = project;
      }
    };

    loadDesign();
  }, [searchParams, action]);

  return (
    <ErrorBoundary>
      <ProjectContext.Provider value={project}>
        <App store={store} isEdit={action !== "edit_template"} index={index} />
      </ProjectContext.Provider>
    </ErrorBoundary>
  );
};

export default Editor;
