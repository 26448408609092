import axios, { AxiosResponse } from "axios";

export const generate_apikey = async (): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/user/generate-apikey", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  }
  catch (error) {
    throw new Error("Error in generating apikey:" + error);
  }
};
export const get_apikey = async (): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/user/get-apikey", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw new Error("Error in getting apikey:" + error);
  }
};

export const get_ads = async (): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/results", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw new Error("Error in getting ads:" + error);
  }
};