export function cleanBase64Strings(jsonString: string) {
  const data = JSON.parse(jsonString);
  let shapeSectionCounter = 1; // Initialize a counter for image sections
  let textSectionCounter = 1; 
  let imageSectionCounter = 1;

  Object.keys(data.pages[0].children).forEach((key) => {
    if (data.pages[0].children[key].type == "image") {
      data.pages[0].children[key].src = "";
      data.pages[0].children[key].typeid = `image_section_${imageSectionCounter++}`;
    }
    if (data.pages[0].children[key].type == "text") {
      data.pages[0].children[key].typeid = `text_section_${textSectionCounter++}`; 
    }
    if (data.pages[0].children[key].type == "figure") {
      data.pages[0].children[key].typeid = `shape_section_${shapeSectionCounter++}`; 
    }
  });

  return data;
}