import Dropbox from "../../../components/Dropbox";
import Separator from "../../../components/Separator";
import TextInput from "../../../components/TextInput";
import AnimationLayout from "../../../layouts/AnimationLayout";
import { BsImages } from "react-icons/bs";
import PPAImagesPanel from "./PPAImagesShowPanel";
import { usePPAContext } from "../../../hooks/usePPAContext";
import Button from "../../../components/Button";
import Slider from "rc-slider";
import { ClockLoader } from "react-spinners";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import PPAImageMatching from "./PPAImageMatching";
import Loading from "../../../components/Loading";

const PPAImages = () => {
  const {
    summaries,
    confidence,
    setConfidence,
    imageFiles,
    setImageFiles,
    isBusy,
    doMatch,
    goToStep,
    matches,
  } = usePPAContext();

  const onMatch = () => {
    doMatch();
  };

  useEffect(() => {
    if (!summaries || summaries.length <= 0) {
      toast.warning("You must complete previous step");
      goToStep("content")();
    }
  }, [summaries]);

  return (
    <>
      <AnimationLayout className="gap-3">
        {matches && matches.length > 0 ? (
          <div className="bg-light-600 dark:bg-dark-200 flex-1 h-full rounded p-10 flex overflow-clip border dark:border-dark-700 justify-between gap-3 ">
            <PPAImageMatching />
          </div>
        ) : (
          <div className="flex w-full gap-3">
            <div className="hidden md:flex bg-light-600 dark:bg-dark-200 min-w-[300px] flex-1 h-full rounded p-10 flex-col overflow-clip border dark:border-dark-700 justify-between gap-3">
              <div className="mb-[20px] pl-[20px]">
                <h3 className="text-[#FF0000] dark:text-light-600 text-[1.3rem] font-bold">
                  Upload Images
                </h3>
                <p className="text-black dark:text-light-200">Upload images for ad creation</p>
              </div>
              <div className="flex-1 overflow-clip mb-2">
                <Dropbox
                  className="w-full h-full"
                  title="Drag and drop images here"
                  icon={<BsImages />}
                  description="Supports: SVG, PNG, JPG or GIF (MAX. 800x400px)"
                  onChange={setImageFiles}
                  accept=".png,.jpg,.bmp,.svg,.gif,.jpeg"
                />
              </div>
              <Separator text="Or" />
              <div className="flex flex-col items-center justify-center">
                <p className="text-black dark:text-light-600 mb-3">Embed any image from URL</p>
                <TextInput
                  className="w-full"
                  placeholder="Please your channel URL..."
                  disabled={isBusy}
                />
              </div>
              <div className="flex flex-col items-center justify-center mt-10">
                <div className="flex items-center mb-3 gap-5">
                  <p className="text-black dark:text-light-600">Confidence Level</p>
                  <span className="px-2 py-1 border border-dark-600 rounded text-black dark:text-light-200">
                    {confidence}
                  </span>
                </div>
                <Slider
                  step={0.1}
                  min={0}
                  max={1}
                  value={confidence}
                  onChange={(value: number | number[]) =>
                    setConfidence(value as number)
                  }
                  className="bg-transparent px-0"
                  railStyle={{ backgroundColor: "#222436", height: 10 }}
                  trackStyle={{ backgroundColor: "#306BF3", height: 10 }}
                  handleStyle={{
                    borderColor: "#306BF3",
                    height: 20,
                    width: 20,
                    marginLeft: -5,
                    marginTop: -5,
                    backgroundColor: "#306BF3",
                    boxShadow: "none",
                    opacity: 100,
                  }}
                  style={{ paddingTop: 0, height: "1px"}}
                  disabled={isBusy}
                />
              </div>
            </div>

            {imageFiles && (
              <div className="relative rounded lg:w-[600px] xl:w-[800px] border dark:border-dark-700">
                <Loading
                  loading={isBusy}
                  className={"absolute rounded w-full h-full"}
                />
                <div className="bg-light-600 dark:bg-dark-200 w-full h-full rounded p-10 flex flex-col overflow-clip justify-between gap-3">
                  <PPAImagesPanel />
                  <div className="w-full flex flex-row-reverse gap-3">
                    <Button
                      className="w-[fit-content] text-light-600"
                      onClick={onMatch}
                      disabled={isBusy}
                    >
                      Generate Ad Pixel+AI
                    </Button>
                    <Button
                      onClick={() => {
                        setImageFiles(null);
                      }}
                      variant="outline"
                      disabled={isBusy}
                    >
                      Discard
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </AnimationLayout>
    </>
  );
};

export default PPAImages;
