// "use strict";
var __rest =
  (this && this.__rest) ||
  function (e, t) {
    var r = {};
    for (var o in e)
      Object.prototype.hasOwnProperty.call(e, o) &&
        t.indexOf(o) < 0 &&
        (r[o] = e[o]);
    if (null != e && "function" == typeof Object.getOwnPropertySymbols) {
      var l = 0;
      for (o = Object.getOwnPropertySymbols(e); l < o.length; l++)
        t.indexOf(o[l]) < 0 &&
          Object.prototype.propertyIsEnumerable.call(e, o[l]) &&
          (r[o[l]] = e[o[l]]);
    }
    return r;
  };
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.registerShapeModel =
    exports.TYPES_MAP =
    exports.GroupElement =
    exports.ElementTypes =
    exports.forEveryChild =
      void 0);
const mobx_state_tree_1 = require("mobx-state-tree"),
  nanoid_1 = require("nanoid"),
  node_model_1 = require("./node-model"),
  shape_model_1 = require("./shape-model"),
  text_model_1 = require("./text-model"),
  image_model_1 = require("./image-model"),
  video_model_1 = require("./video-model"),
  line_model_1 = require("./line-model"),
  svg_model_1 = require("./svg-model"),
  figure_model_1 = require("./figure-model"),
  forEveryChild = (e, t) => {
    if (e.children)
      for (const r of e.children) {
        if (!0 === t(r)) break;
        (0, exports.forEveryChild)(r, t);
      }
  };
exports.forEveryChild = forEveryChild;
const additionalTypesUnion = [...new Array(20)].map((e, t) =>
  mobx_state_tree_1.types.late(() => ADDITIONAL_TYPES[t])
);
(exports.ElementTypes = mobx_state_tree_1.types.union(
  {
    dispatcher: (e) => {
      const t = exports.TYPES_MAP[e.type];
      if (!t) throw new Error(`Unknown element type: "${e.type}"`);
      return t;
    },
  },
  svg_model_1.SVGElement,
  text_model_1.TextElement,
  image_model_1.ImageElement,
  line_model_1.LineElement,
  video_model_1.VideoElement,
  figure_model_1.FigureElement,
  mobx_state_tree_1.types.late(() => exports.GroupElement),
  ...additionalTypesUnion
)),
  (exports.GroupElement = node_model_1.Node.named("Group")
    .props({
      type: "group",
      children: mobx_state_tree_1.types.array(exports.ElementTypes),
    })
    .views((e) => ({
      get draggable() {
        let t = !0;
        return (
          (0, exports.forEveryChild)(e, (e) => {
            e.draggable || (t = !1);
          }),
          t
        );
      },
      get resizable() {
        let t = !0;
        return (
          (0, exports.forEveryChild)(e, (e) => {
            e.resizable || (t = !1);
          }),
          t
        );
      },
      get contentEditable() {
        let t = !0;
        return (
          (0, exports.forEveryChild)(e, (e) => {
            e.contentEditable || (t = !1);
          }),
          t
        );
      },
      get styleEditable() {
        let t = !0;
        return (
          (0, exports.forEveryChild)(e, (e) => {
            e.styleEditable || (t = !1);
          }),
          t
        );
      },
      get locked() {
        let t = !0;
        return (
          (0, exports.forEveryChild)(e, (e) => {
            e.locked || (t = !1);
          }),
          t
        );
      },
    }))
    .actions((e) => ({
      set(t) {
        var {
            draggable: r,
            contentEditable: o,
            styleEditable: l,
            resizable: i,
          } = t,
          n = __rest(t, [
            "draggable",
            "contentEditable",
            "styleEditable",
            "resizable",
          ]);
        void 0 !== r &&
          (0, exports.forEveryChild)(e, (e) => {
            e.set({ draggable: r });
          }),
          void 0 !== o &&
            (0, exports.forEveryChild)(e, (e) => {
              e.set({ contentEditable: o });
            }),
          void 0 !== l &&
            (0, exports.forEveryChild)(e, (e) => {
              e.set({ styleEditable: l });
            }),
          void 0 !== i &&
            (0, exports.forEveryChild)(e, (e) => {
              e.set({ resizable: i });
            }),
          Object.assign(e, n);
      },
      addElement(t, { skipSelect: r = !1 } = {}) {
        const o = exports.TYPES_MAP[t.type];
        if (!o)
          return void console.error("Can not find model with type " + t.type);
        t.children &&
          t.children.forEach((e) => {
            e.id = (0, nanoid_1.nanoid)(10);
          });
        const l = o.create(Object.assign({ id: (0, nanoid_1.nanoid)(10) }, t));
        return (
          e.children.push(l),
          l.selectable && !r && e.store.selectElements([l.id]),
          l
        );
      },
      setElementZIndex(t, r) {
        const o = e.children.find((e) => e.id === t);
        o &&
          ((0, mobx_state_tree_1.detach)(o),
          e.children.remove(o),
          e.children.splice(r, 0, o));
      },
    })));
const ADDITIONAL_TYPES = [];
function registerShapeModel(e, t) {
  const r = e.type;
  if (!r) throw new Error('You must pass "type" attribute to custom model.');
  let o = shape_model_1.Shape.named(r).props(e);
  t && (o = t(o)), (exports.TYPES_MAP[r] = o), ADDITIONAL_TYPES.push(o);
}
(exports.TYPES_MAP = {
  svg: svg_model_1.SVGElement,
  text: text_model_1.TextElement,
  image: image_model_1.ImageElement,
  group: exports.GroupElement,
  line: line_model_1.LineElement,
  video: video_model_1.VideoElement,
  figure: figure_model_1.FigureElement,
}),
  (exports.registerShapeModel = registerShapeModel);
