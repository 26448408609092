import React from 'react';
import AnimationLayout from "../../../layouts/AnimationLayout";

const PPACallToActionButton = () => {
  return (
    <AnimationLayout>
      <div>This is PPACallToActionButton page</div>
      <div>This is PPACallToActionButton page</div>
      <div>This is PPACallToActionButton page</div>
    </AnimationLayout>
  );
};

export default PPACallToActionButton;
