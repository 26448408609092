/* eslint-disable */
// "use strict";
var __createBinding =
    (this && this.__createBinding) ||
    (Object.create
      ? function (e, t, n, i) {
          void 0 === i && (i = n);
          var o = Object.getOwnPropertyDescriptor(t, n);
          (o && !("get" in o ? !t.__esModule : o.writable || o.configurable)) ||
            (o = {
              enumerable: !0,
              get: function () {
                return t[n];
              },
            }),
            Object.defineProperty(e, i, o);
        }
      : function (e, t, n, i) {
          void 0 === i && (i = n), (e[i] = t[n]);
        }),
  __setModuleDefault =
    (this && this.__setModuleDefault) ||
    (Object.create
      ? function (e, t) {
          Object.defineProperty(e, "default", { enumerable: !0, value: t });
        }
      : function (e, t) {
          e.default = t;
        }),
  __importStar =
    (this && this.__importStar) ||
    function (e) {
      if (e && e.__esModule) return e;
      var t = {};
      if (null != e)
        for (var n in e)
          "default" !== n &&
            Object.prototype.hasOwnProperty.call(e, n) &&
            __createBinding(t, e, n);
      return __setModuleDefault(t, e), t;
    };
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.htmlToCanvas = exports.detectSize = void 0);
const rasterizeHTML = __importStar(require("rasterizehtml")),
  fonts_1 = require("./fonts"),
  resetStyle =
    "\n<style>\n  html, body {\n    padding: 0;\n    margin: 0;\n  }\n  p {\n    margin: 0;\n    padding: 0\n  }\n  ul, ol {\n    list-style-position: inside;\n    margin: 0;\n  }\n</style>\n";
let hidden;
const getHiddenElement = () => (
  hidden ||
    ((hidden = document.createElement("div")),
    (hidden.id = "editor-hidden-do-not-touch"),
    (hidden.style.overflow = "hidden"),
    (hidden.style.position = "relative"),
    document.body.appendChild(hidden),
    (hidden.innerHTML =
      "<style>\n#editor-hidden-do-not-touch p {\n  margin: 0;\n  padding: 0\n}\n#editor-hidden-do-not-touch ul, #editor-hidden-do-not-touch ol {\n  list-style-position: inside;\n  margin: 0;\n}\n</style>")),
  hidden
);
function detectSize(e) {
  const t = document.createElement("div");
  (t.innerHTML = e),
    (t.style.display = "inline-block"),
    (t.style.position = "fixed"),
    (t.style.top = "0px"),
    (t.style.left = "0px"),
    (t.style.zIndex = "1000");
  const n = getHiddenElement();
  n.appendChild(t);
  const i = t.getBoundingClientRect();
  return n.removeChild(t), { width: i.width, height: i.height };
}
async function htmlToCanvas({
  html: e,
  width: t,
  height: n,
  fontFamily: i,
  padding: o,
  font: d,
  pixelRatio: l,
}) {
  const r = document.createElement("canvas");
  if ("Arial" !== i && !d) {
    const t = (0, fonts_1.getGoogleFontsVariants)();
    e += `<link type="text/css" href="https://fonts.googleapis.com/css?family=${i}:${t}" rel="stylesheet">`;
  }
  if (d) {
    const t = d.styles || (d.url ? [{ src: `url("${d.url}")` }] : []);
    (e += "<style>"),
      t.forEach((t) => {
        e += `\n      @font-face {\n        font-family: '${i}';\n        src: ${
          t.src
        };\n        font-style: ${
          t.fontStyle || "normal"
        };\n        font-weight: ${t.fontWeight || "normal"};\n      }\n    `;
      }),
      (e += "</style>");
  }
  e += resetStyle;
  const s = await rasterizeHTML.drawHTML(
    `<div style="padding: ${o}px;">${e}</div>`,
    r,
    { width: t + 2 * o, height: n + 2 * o }
  );
  (r.width = (t + 2 * o) * l), (r.height = (n + 2 * o) * l);
  const a = r.getContext("2d");
  return (
    null == a ||
      a.drawImage(s.image, 0, 0, s.image.width * l, s.image.height * l),
    r
  );
}
(exports.detectSize = detectSize), (exports.htmlToCanvas = htmlToCanvas);
