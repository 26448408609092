export const updateSVGInHTML = (htmlContent: string) => {
  // Create a DOM parser
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  // Find all SVG elements
  const svgs = doc.querySelectorAll("svg");

  svgs.forEach((svg, index) => {
    // Find the path with the linear gradient fill
    const path = svg.querySelector("path[fill^='linear-gradient']");
    if (path) {
      // Extract the gradient details
      const fillValue = path.getAttribute("fill");
      const gradientMatch = fillValue.match(/linear-gradient\((\d+)deg,\s*(.+)\)/);
      if (gradientMatch) {
        let angle = parseInt(gradientMatch[1], 10);
        angle = (angle + 180) % 360; // Adjust the angle to match SVG's coordinate system
        const colors = gradientMatch[2].split(/,\s*(?![^(]*\))/); // Split by comma but not within parentheses

        // Create a new linearGradient element with a unique ID
        const gradientId = `updatedGradient-${index}`;
        const linearGradient = doc.createElementNS("http://www.w3.org/2000/svg", "linearGradient");
        linearGradient.setAttribute("id", gradientId);

        // Calculate x1, y1, x2, y2 based on the adjusted angle
        const x1 = 50 + 50 * Math.cos((angle - 90) * (Math.PI / 180));
        const y1 = 50 + 50 * Math.sin((angle - 90) * (Math.PI / 180));
        const x2 = 50 + 50 * Math.cos((angle + 90) * (Math.PI / 180));
        const y2 = 50 + 50 * Math.sin((angle + 90) * (Math.PI / 180));

        linearGradient.setAttribute("x1", `${x1}%`);
        linearGradient.setAttribute("y1", `${y1}%`);
        linearGradient.setAttribute("x2", `${x2}%`);
        linearGradient.setAttribute("y2", `${y2}%`);

        // Add stop elements for each color
        colors.forEach((color) => {
          const stop = doc.createElementNS("http://www.w3.org/2000/svg", "stop");
          const colorMatch = color.trim().match(/(rgba?\([^)]+\)|#[0-9a-fA-F]{3,6}|[a-zA-Z]+)\s*(\d+(\.\d+)?%)/);
          if (colorMatch) {
            const [_, colorValue, offset] = colorMatch;
            stop.setAttribute("offset", offset);
            stop.setAttribute("style", `stop-color:${colorValue}; stop-opacity:1`);
            linearGradient.appendChild(stop);
          }
        });

        // Append the gradient to defs
        const defs = svg.querySelector("defs");
        if (!defs) {
          const newDefs = doc.createElementNS("http://www.w3.org/2000/svg", "defs");
          svg.insertBefore(newDefs, svg.firstChild);
          newDefs.appendChild(linearGradient);
        } else {
          defs.appendChild(linearGradient);
        }

        // Update the path fill to use the new gradient
        path.setAttribute("fill", `url(#${gradientId})`);
      }
    }
  });

  // Serialize the updated HTML back to a string
  const serializer = new XMLSerializer();
  return serializer.serializeToString(doc);
};