import { MdEdit, MdDelete } from "react-icons/md";
import AnimatedButton from "../../../components/AnimatedButton";
import React, { useEffect, useRef, useState } from "react";
import { usePPAContext } from "../../../hooks/usePPAContext";

interface SummaryItemProps {
  index: number;
  summary: string;
  setSave: (saveStatus: string) => void;
}

const SummaryItem: React.FC<SummaryItemProps> = ({
  index,
  summary,
  setSave,
}) => {
  const { updateSummary, deleteSummary } = usePPAContext();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editTimeout, setEditTimeout] = useState<number | null>(null);

  const onEdit = () => {
    setIsEdit(true);
    setSave("Saving now...");
  };

  const handleSave = () => {
    if (editTimeout) clearTimeout(editTimeout);
    setEditTimeout(
      window.setTimeout(() => {
        setSave("Saved");
      }, 1000)
    );
  };

  const onSave = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateSummary(index, e.target.value);
    handleSave();
  };

  const onBlur = () => {
    setIsEdit(false);
    handleSave();
  };

  const onDelete = () => {
    deleteSummary(index);
    setSave("");
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto"; // Reset the height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
    }
  }, [summary, isEdit]);

  useEffect(() => {
    return () => {
      if (editTimeout) clearTimeout(editTimeout);
    };
  }, []);

  return (
    <div
      className={`flex flex-col justify-between gap-[10px] p-2 border-2 ${
        isEdit ? "bg-dark-500 rounded border-dark-800" : "border-transparent"
      }`}
    >
      <div className="flex flex-row justify-between">
        <p className="dark:text-light-200 text-black">Headline {index + 1}</p>
        <div className="flex flex-row gap-2">
          <AnimatedButton onClick={() => onEdit()}>
            <MdEdit />
          </AnimatedButton>
          <AnimatedButton onClick={() => onDelete()}>
            <MdDelete />
          </AnimatedButton>
        </div>
      </div>
      {isEdit ? (
        <textarea
          ref={textareaRef}
          className="cursor-text overflow-hidden outline-none border border-transparent rounded bg-dark-500 text-[14px]"
          value={summary}
          onChange={onSave}
          onBlur={onBlur}
          autoFocus
        />
      ) : (
        <p
          style={{ wordBreak: "break-word" }}
          className="text-[14px] font-normal leading-[20px] overflow-wrap text-black dark:text-light-200"
        >
          {summary}
        </p>
      )}
    </div>
  );
};

export default SummaryItem;
