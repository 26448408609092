import React from 'react';
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { HashLoader } from "react-spinners";
import { IPContext } from '../../hooks/IPContext';

// Define the type for a log entry
interface LogEntry {
  id: number;
  email: string;
  status: string;
  ip_address: string;
  activity: string;
  log: string;
  time: string;
}

const Viewlogs = () => {
  const navigate = useNavigate();
  const { darkMode } = useContext(IPContext);
  // Use the LogEntry type for the state
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 12;
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    setIsBusy(true);
    axios
      .get("/logs") // Adjust the URL as needed
      .then((response) => {
        // Ensure the response data is of type LogEntry[]
        setLogs(response.data.data as LogEntry[]);
        setIsBusy(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the logs", error);
        setIsBusy(false);
      });
  }, []);

  // Calculate the current logs to display
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentLogs = logs.slice(indexOfFirstRecord, indexOfLastRecord);

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers: number[] = [];
  for (let i = 1; i <= Math.ceil(logs.length / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className={`${darkMode? "dark": ""}`}>
      <div className="absolute w-full dark:bg-dark-300 bg-[#EFEFEF]"><div className="flex flex-1 flex-col m-10 p-5 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none rounded-[5px] bg-light-600 dark:bg-dark-200 overflow-auto shadow">
        <div className="flex justify-end mb-5">
          <Button variant="outline" onClick={() => navigate("/")}>
            Back
          </Button>
        </div>

        {isBusy ? (
          <div className="flex items-center justify-center h-4/5 flex-col gap-12">
            <HashLoader color="#e9e9e9" size={150} speedMultiplier={1.5} />
            <h1 className="text-2xl">Loading ...</h1>
          </div>
        ) : (
          <div>
            <table className="min-w-full">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>IP Address</th>
                  <th>Activity</th>
                  <th>Log</th>
                  <th>Time(PST)</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {currentLogs.map((log) => (
                  <tr key={log.id}>
                    <td>{log.id}</td>
                    <td>{log.email}</td>
                    <td>{log.status}</td>
                    <td>{log.ip_address}</td>
                    <td>{log.activity}</td>
                    <td>{log.log}</td>
                    <td>{log.time}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="flex mt-2 pagination justify-end space-x-2">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="p-2 disabled:opacity-50 border-[1px] rounded border-white-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          {pageNumbers.map((number) => {
            if (
              number === currentPage ||
              number === currentPage + 1 ||
              number === currentPage - 1 ||
              number === 1 ||
              number === pageNumbers.length
            ) {
              return (
                <button
                  key={number}
                  className={`p-2 rounded border-[1px] ${
                    number === currentPage
                      ? "bg-blue-500 text-white"
                      : "text-grey"
                  }`}
                  onClick={() => paginate(number)}
                >
                  {number}
                </button>
              );
            } else if (
              number === currentPage - 2 ||
              number === currentPage + 2
            ) {
              return (
                <span key={number} className="p-2">
                  ...
                </span>
              );
            } else {
              return null;
            }
          })}
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === pageNumbers.length}
            className="p-2 disabled:opacity-50 rounded border-[1px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div></div>
      
    </div>
  );
};

export default Viewlogs;
