import React, { useEffect, useState } from "react";
import AnimationLayout from "../../../layouts/AnimationLayout";
import { usePPAContext } from "../../../hooks/usePPAContext";
import { toast } from "react-toastify";
import UploadPdf from "./UploadPdf";
import ElementsDetails from "./ElementDetails";

const PPAElements = () => {
  const { pdfFiles, matches, goToStep, fonts, colors } = usePPAContext();
  const [isSelectPdf, setIsSelectPdf] = useState<boolean>(true);

  const handleSkip = () => {
    setIsSelectPdf(false);
  };

  useEffect(() => {
    if (!matches || matches.length <= 0) {
      toast.warning("You must complete previous step");
      goToStep("images")();
    }
  }, [matches]);

  useEffect(() => {
    if (
      (pdfFiles && pdfFiles.length > 0) ||
      fonts.length > 0 ||
      colors.length > 0
    ) {
      console.log(pdfFiles)
      setIsSelectPdf(false);
    }
  }, [pdfFiles, fonts, colors, setIsSelectPdf]);

  return (
    <AnimationLayout className="gap-3 flex-row">
      {isSelectPdf ? (
        <div className="bg-light-600 dark:bg-dark-200 w-full h-full rounded p-10 flex flex-col overflow-clip border dark:border-dark-700 justify-between gap-3">
          <UploadPdf onSkip={handleSkip} />
        </div>
      ) : (
        <div className="w-full h-full flex flex-col overflow-clip justify-between gap-3 relative">
          <ElementsDetails />
        </div>
      )}
    </AnimationLayout>
  );
};

export default PPAElements;
