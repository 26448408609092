/* eslint-disable */
// "use strict";
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.figureToSvg =
    exports.subTypeToPathDataFunc =
    exports.TYPES =
    exports.crossPath =
    exports.speechBubblePath =
    exports.hexagonPath =
    exports.pentagonPath =
    exports.diamondPath =
    exports.starPath =
    exports.circleData =
    exports.rightTriangleData =
    exports.triangleData =
    exports.rectData =
      void 0);
const rectData = ({ width: t, height: h, cornerRadius: e }) => {
  if (e > 0) {
    const i = Math.min(e, t / 2, h / 2);
    return `M 0 ${i} A ${i} ${i} 0 0 1 ${i} 0 L ${
      t - i
    } 0 A ${i} ${i} 0 0 1 ${t} ${i} L ${t} ${h - i} A ${i} ${i} 0 0 1 ${
      t - i
    } ${h} L ${i} ${h} A ${i} ${i} 0 0 1 0 ${h - i} Z`;
  }
  return `M 0 0 L ${t} 0 L ${t} ${h} L 0 ${h} Z`;
};
exports.rectData = rectData;
const triangleData = ({ width: t, height: h }) =>
  `M ${t / 2} 0 L ${t} ${h} L 0 ${h} Z`;
exports.triangleData = triangleData;
const rightTriangleData = ({ width: t, height: h }) =>
  `M 0 0 L ${t} 0 L 0 ${h} Z`;
exports.rightTriangleData = rightTriangleData;
const circleData = ({ width: t, height: h }) =>
  `M ${t / 2} ${h / 2} m -${t / 2}, 0 a ${t / 2},${h / 2} 0 1,0 ${t},0 a ${
    t / 2
  },${h / 2} 0 1,0 -${t},0`;
function starPath({ width: t, height: h }) {
  const e = t / 2,
    i = h / 2,
    a = t / 2,
    C = h / 2,
    g = a / 2,
    d = C / 2;
  let o = "",
    w = Math.PI / 5;
  for (let t = 0; t < 10; t++) {
    const h = t % 2 == 0 ? C : d,
      c = e + (t % 2 == 0 ? a : g) * Math.sin(t * w),
      p = i - h * Math.cos(t * w);
    0 === t ? (o = `M ${c} ${p}`) : (o += ` L ${c} ${p}`);
  }
  return o + " Z";
}
function diamondPath({ width: t, height: h }) {
  const e = t / 2,
    i = h / 2;
  return `M ${e} 0 L ${t} ${i} L ${e} ${h} L 0 ${i} Z`;
}
function pentagonPath({ width: t, height: h }) {
  const e = t / 2,
    i = h / 2,
    a = t / 2,
    C = h / 2;
  let g = "",
    d = (2 * Math.PI) / 5;
  for (let t = 0; t < 5; t++) {
    const h = C,
      o = e + a * Math.sin(t * d),
      w = i - h * Math.cos(t * d);
    0 === t ? (g = `M ${o} ${w}`) : (g += ` L ${o} ${w}`);
  }
  return g + " Z";
}
function hexagonPath({ width: t, height: h }) {
  const e = t / 2,
    i = h / 2,
    a = t / 2,
    C = h / 2;
  let g = "",
    d = (2 * Math.PI) / 6;
  for (let t = 0; t < 6; t++) {
    const h = C,
      o = e + a * Math.sin(t * d),
      w = i - h * Math.cos(t * d);
    0 === t ? (g = `M ${o} ${w}`) : (g += ` L ${o} ${w}`);
  }
  return g + " Z";
}
function getScaledPath({ path: t, aspectRatio: h, width: e, height: i }) {
  return { path: t, scaleX: e / h.width, scaleY: i / h.height };
}
function speechBubblePath({ width: t, height: h }) {
  const e = 0.1 * Math.min(t, h),
    i = 0.2 * t,
    a = 0.2 * h,
    C = 0.5 * t;
  let g = `M ${e},0`;
  return (
    (g += " H " + (t - e)),
    (g += ` A ${e},${e} 0 0 1 ${t},${e}`),
    (g += " V " + (h - e - a)),
    (g += ` A ${e},${e} 0 0 1 ${t - e},${h - a}`),
    (g += ` H ${C + i / 2}`),
    (g += ` L ${C},${h}`),
    (g += ` L ${C - i / 2},${h - a}`),
    (g += ` H ${e}`),
    (g += ` A ${e},${e} 0 0 1 0,${h - e - a}`),
    (g += ` V ${e}`),
    (g += ` A ${e},${e} 0 0 1 ${e},0`),
    (g += " Z"),
    g
  );
}
function crossPath({ width: t, height: h }) {
  const e = 0.3 * t,
    i = 0.3 * h,
    a = t / 2,
    C = h / 2,
    g = a - e / 2,
    d = a + e / 2,
    o = C - i / 2,
    w = C + i / 2;
  let c = `M ${g},0 `;
  return (
    (c += `H ${d} `),
    (c += `V ${o} `),
    (c += `H ${t} `),
    (c += `V ${w} `),
    (c += `H ${d} `),
    (c += `V ${h} `),
    (c += `H ${g} `),
    (c += `V ${w} `),
    (c += "H 0 "),
    (c += `V ${o} `),
    (c += `H ${g} `),
    (c += "V 0 "),
    (c += "Z"),
    c
  );
}
function subTypeToPathDataFunc(t) {
  return exports.TYPES[t] || exports.rectData;
}
function createSvgFromPath(t, h) {
  const {
      width: e,
      height: i,
      fill: a,
      stroke: C,
      strokeWidth: g,
      dash: d,
    } = t,
    o = (d || []).map((t) => t * g);
  let w = h(t),
    c = 1,
    p = 1;
  "string" != typeof w && ((c = w.scaleX), (p = w.scaleY), (w = w.path));
  const s = `clip-${t.id || Math.random()}`;
  return `\n    <svg xmlns="http://www.w3.org/2000/svg" width="${e}" height="${i}">\n    <defs>\n    <clipPath id="${s}">\n      <path d="${w}" />\n    </clipPath>\n  </defs>\n\n  \x3c!-- Path for the fill --\x3e\n  <path d="${w}" fill="${a}" transform="scale(${c}, ${p})"/>\n\n  \x3c!-- Path for the stroke, clipped by the star path --\x3e\n  <path d="${w}" fill="none" stroke="${C}" stroke-width="${
    2 * g
  }" clip-path="url(#${s})" transform="scale(${c}, ${p})" stroke-dasharray="${o.join(
    " "
  )}"/>\n    </svg>\n  `;
}
function figureToSvg(t) {
  const { subType: h } = t,
    e = subTypeToPathDataFunc(h);
  return e
    ? createSvgFromPath(t, e)
    : (console.error(
        `editor error: Can not convert ${t.subType} figure to svg.`
      ),
      (0, exports.rectData)(t));
}
(exports.circleData = circleData),
  (exports.starPath = starPath),
  (exports.diamondPath = diamondPath),
  (exports.pentagonPath = pentagonPath),
  (exports.hexagonPath = hexagonPath),
  (exports.speechBubblePath = speechBubblePath),
  (exports.crossPath = crossPath),
  (exports.TYPES = {
    rect: exports.rectData,
    circle: exports.circleData,
    star: starPath,
    triangle: exports.triangleData,
    rightTriangle: exports.rightTriangleData,
    diamond: diamondPath,
    pentagon: pentagonPath,
    hexagon: hexagonPath,
    speechBubble: speechBubblePath,
    cross: crossPath,
    // arc: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M234 117C234 85.97 221.673 56.21 199.731 34.269C177.79 12.327 148.03 0 117 0C85.97 0 56.2103 12.327 34.2685 34.268C12.3268 56.21 0 85.97 0 117H117H234Z",
    //     aspectRatio: { width: 234, height: 117 },
    //     width: t,
    //     height: h,
    //   }),
    // cloud: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M216.41 57.006C216.961 54.084 217.249 51.069 217.249 47.986C217.249 21.4841 195.972 0 169.725 0C153.666 0 139.467 8.043 130.865 20.3575C122.606 13.3566 111.954 9.1402 100.327 9.1402C74.3322 9.1402 53.2123 30.2136 52.8094 56.364H52.8035C23.6407 56.364 0 80.235 0 109.682C0 139.129 23.6407 163 52.8035 163H208.197C237.359 163 261 139.129 261 109.682C261 83.056 241.672 60.99 216.41 57.006Z",
    //     aspectRatio: { width: 261, height: 163 },
    //     width: t,
    //     height: h,
    //   }),
    rightArrow: ({ width: t, height: h }) => {
      const e = 0.6 * t;
      return `M 0 ${h / 4} L ${e} ${h / 4} L ${e} 0 L ${t} ${
        h / 2
      } L ${e} ${h} L ${e} ${0.75 * h} L 0 ${0.75 * h} Z`;
    },
    leftArrow: ({ width: t, height: h }) => {
      const e = 0.4 * t;
      return `M ${t} ${h / 4} L ${e} ${h / 4} L ${e} 0 L 0 ${
        h / 2
      } L ${e} ${h} L ${e} ${0.75 * h} L ${t} ${0.75 * h} Z`;
    },
    downArrow: ({ width: t, height: h }) => {
      const e = 0.6 * h;
      return `M ${t / 4} 0 L ${t / 4} ${e} L 0 ${e} L ${
        t / 2
      } ${h} L ${t} ${e} L ${0.75 * t} ${e} L ${0.75 * t} 0 Z`;
    },
    upArrow: ({ width: t, height: h }) => {
      const e = 0.4 * h;
      return `M ${t / 4} ${h} L ${t / 4} ${e} L 0 ${e} L ${
        t / 2
      } 0 L ${t} ${e} L ${0.75 * t} ${e} L ${0.75 * t} ${h} Z`;
    },
    // asterisk1: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M45.5265 218.498C51.5466 222.87 58.5359 224.98 65.4742 224.98C75.916 224.98 86.221 220.2 92.853 211.217C92.872 211.198 92.89 211.178 92.907 211.157C92.923 211.136 92.939 211.115 92.954 211.094L92.977 211.061C92.993 211.039 93.008 211.017 93.023 210.996L116.491 178.725L139.959 210.996C139.982 211.031 140.006 211.058 140.029 211.088C140.04 211.102 140.05 211.116 140.061 211.132C151.115 226.188 172.355 229.505 187.473 218.515C202.608 207.526 205.992 186.278 195.074 171.104L195.05 171.066C195.03 171.034 195.014 171.01 194.989 170.985L171.572 138.696L209.512 126.346C227.334 120.545 237.129 101.305 231.33 83.476C225.532 65.648 206.315 55.8493 188.476 61.6334L150.537 73.933L150.52 34.0064C150.502 15.2594 135.248 0 116.508 0C97.768 0 82.514 15.2594 82.497 34.0234L82.463 73.916L44.5233 61.6163C44.5083 61.6163 44.49 61.613 44.4716 61.6078C44.4477 61.6014 44.4232 61.5918 44.4041 61.5823C26.5992 55.8664 7.45076 65.665 1.66886 83.46C-4.11314 101.254 5.61427 120.426 23.3511 126.278C23.3675 126.283 23.3822 126.289 23.3963 126.295L23.4588 126.319L23.4873 126.329L61.4098 138.679L37.9929 170.968C37.9844 170.985 37.9718 171.002 37.9591 171.019C37.9462 171.036 37.9336 171.053 37.9251 171.07C26.9903 186.244 30.3915 207.509 45.5265 218.498Z",
    //     aspectRatio: { width: 233, height: 225 },
    //     width: t,
    //     height: h,
    //   }),
    // asterisk2: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M46.0556 112L0 85.388L33.9456 26.6121L80.028 53.1781L80.054 0H147.946L147.972 53.1781L194.054 26.6121L228 85.388L181.944 112L228 138.612L194.054 197.388L147.972 170.822L147.946 224H80.054L80.028 170.822L33.9456 197.388L0 138.612L46.0556 112Z",
    //     aspectRatio: { width: 228, height: 224 },
    //     width: t,
    //     height: h,
    //   }),
    bookmark: ({ width: t, height: h }) =>
      getScaledPath({
        path: "M0 0H199V238L99.5 142.8L0 238V0Z",
        aspectRatio: { width: 199, height: 238 },
        width: t,
        height: h,
      }),
    // butterfly: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M94.5 100.713C91.935 150.916 50.703 190.909 0 191.493V0C50.703 0.5845 91.935 40.5767 94.5 90.78C97.031 41.2385 137.217 1.6407 187 0.0438004V191.45C137.217 189.853 97.031 150.255 94.5 100.713Z",
    //     aspectRatio: { width: 187, height: 192 },
    //     width: t,
    //     height: h,
    //   }),
    // cylinder: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M169 36.8497V218.15C169 238.502 131.168 255 84.5 255C37.832 255 0 238.502 0 218.15V36.8497C0 16.4982 37.832 0 84.5 0C131.168 0 169 16.4982 169 36.8497Z",
    //     aspectRatio: { width: 169, height: 255 },
    //     width: t,
    //     height: h,
    //   }),
    diamond2: ({ width: t, height: h }) =>
      getScaledPath({
        path: "M0 55.5L124 185L248 55.5L189.307 0H58.6933L0 55.5Z",
        aspectRatio: { width: 248, height: 185 },
        width: t,
        height: h,
      }),
    door: ({ width: t, height: h }) =>
      getScaledPath({
        path: "M140 69.697C140 31.2044 108.66 0 70 0C31.34 0 0 31.2044 0 69.697V207H140V69.697Z",
        aspectRatio: { width: 140, height: 207 },
        width: t,
        height: h,
      }),
    // drop1: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M167 150.687C167 196.328 129.407 234 83.139 234C37.593 234 0 196.328 0 150.687C0 100.7 51.329 36.9474 83.139 0C114.948 39.1207 167 98.526 167 150.687Z",
    //     aspectRatio: { width: 167, height: 234 },
    //     width: t,
    //     height: h,
    //   }),
    // drop2: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M73 239C53.64 239 35.071 231.315 21.3812 217.634C7.691 203.954 0 185.4 0 166.054C0 136.091 13.8696 113.633 30.768 86.27C43.912 64.9877 58.888 40.7377 70.597 7.6816C71.281 5.7496 72.989 0 72.989 0C72.989 0 72.993 0.0124998 73 0.0363998C73.007 0.0124998 73.011 0 73.011 0C73.011 0 74.719 5.7496 75.403 7.6816C87.112 40.7377 102.088 64.9877 115.232 86.27C132.13 113.633 146 136.091 146 166.054C146 185.4 138.309 203.954 124.619 217.634C111.025 231.218 92.225 239 73 239Z",
    //     aspectRatio: { width: 146, height: 239 },
    //     width: t,
    //     height: h,
    //   }),
    // explosion: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M165.802 184.465L118.997 260L125.343 182.079L34.9058 219.45L99.164 159.817L0 163.792L78.538 134.373L0 74.7401L86.471 89.052V0L118.997 89.052L165.802 10.3364L149.143 97.798L217.368 29.419L165.802 112.905L245.927 81.101L165.802 143.914H261L165.802 184.465Z",
    //     aspectRatio: { width: 261, height: 260 },
    //     width: t,
    //     height: h,
    //   }),
    // flag: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M0 142.161C0 142.161 51.9956 118.626 103 137.469C159.992 158.525 206 141.31 206 141.31V11.1373C206 11.1373 160 28.3218 103.016 7.28273C52 -11.553 0 12.0974 0 12.0974V142.161Z",
    //     aspectRatio: { width: 206, height: 148 },
    //     width: t,
    //     height: h,
    //   }),
    // flower: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M135.351 66.1893C196.683 -5.00591 256.536 100.304 164.908 117.361C256.536 135.16 195.944 239.728 135.351 168.532C166.386 257.526 45.9394 256.785 76.975 168.532C15.643 239.728 -44.2109 135.16 47.4173 117.361C-44.9498 100.304 15.643 -4.26431 76.975 66.1893C45.2005 -22.0631 166.386 -22.0631 135.351 66.1893ZM105.793 104.012C113.183 104.012 119.833 109.945 119.833 117.361C119.833 124.777 113.183 131.452 105.793 131.452C98.404 131.452 92.492 124.777 92.492 117.361C92.492 109.945 98.404 104.012 105.793 104.012Z",
    //     aspectRatio: { width: 212, height: 235 },
    //     width: t,
    //     height: h,
    //   }),
    // frame: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M0 0H240L200 88L240 176H0L40 88L0 0Z",
    //     aspectRatio: { width: 240, height: 176 },
    //     width: t,
    //     height: h,
    //   }),
    heart1: ({ width: t, height: h }) =>
      getScaledPath({
        path: "M215.886 56.157C215.886 24.729 191.112 0 160.584 0C135.933 0 115.08 16.983 107.937 39.93C100.788 16.986 79.935 0.591 55.284 0.591C24.75 0.591 0 24.615 0 56.055C0 71.919 6.321 83.127 16.494 95.121L107.988 189.447L199.377 95.121C209.559 83.127 215.886 72.009 215.886 56.157Z",
        aspectRatio: { width: 216, height: 190 },
        width: t,
        height: h,
      }),
    // home: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M0 62.143L95.5 0L191 62.143V195H0V62.143Z",
    //     aspectRatio: { width: 191, height: 195 },
    //     width: t,
    //     height: h,
    //   }),
    home2: ({ width: t, height: h }) =>
      getScaledPath({
        path: "M206 94.076L103 0L0 94.076H31.2377V189H175.607V94.076H206Z",
        aspectRatio: { width: 206, height: 189 },
        width: t,
        height: h,
      }),
    // hourglass: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M0.392782 0H187.607C193.014 43.3688 140.804 53.797 140.804 97.5C140.804 141.203 193.014 151.631 187.607 195H0.392782C-5.01452 151.631 47.196 141.203 47.196 97.5C47.196 53.797 -5.01452 43.3688 0.392782 0Z",
    //     aspectRatio: { width: 188, height: 195 },
    //     width: t,
    //     height: h,
    //   }),
    house: ({ width: t, height: h }) =>
      getScaledPath({
        path: "M0 92.367L95 0L190 92.367V223H0V92.367Z",
        aspectRatio: { width: 190, height: 223 },
        width: t,
        height: h,
      }),
    // keyhole: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M115 57.0494C115 25.5387 89.416 0 57.863 0C26.311 0 0.726898 25.5414 0.726898 57.0494C0.726898 78.652 12.759 97.451 30.505 107.136L0 219H114.845L84.448 107.524C102.608 97.962 115 78.959 115 57.0494Z",
    //     aspectRatio: { width: 115, height: 219 },
    //     width: t,
    //     height: h,
    //   }),
    // kiss: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M124.5 17.1468C80.036 -36.5162 0 52.9218 0 52.9218C0 52.9218 44.4643 120 124.5 120C204.536 120 249 52.9218 249 52.9218C249 52.9218 168.964 -36.5162 124.5 17.1468Z",
    //     aspectRatio: { width: 249, height: 120 },
    //     width: t,
    //     height: h,
    //   }),
    // leaf: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M204.414 0.0151567C204.414 0.0151567 206.837 93.9509 150.394 150.394C93.9507 206.837 0.0151569 204.413 0.0151569 204.413C0.0151569 204.413 -2.40835 110.478 54.0347 54.0349C110.478 -2.40834 204.414 0.0151567 204.414 0.0151567Z",
    //     aspectRatio: { width: 205, height: 205 },
    //     width: t,
    //     height: h,
    //   }),
    lightning1: ({ width: t, height: h }) =>
      getScaledPath({
        path: "M111.5 0L0 142H51.433L31.5 253L143 111H91.567L111.5 0Z",
        aspectRatio: { width: 143, height: 253 },
        width: t,
        height: h,
      }),
    lightning2: ({ width: t, height: h }) =>
      getScaledPath({
        path: "M126 0H43L0 120.531H36L6 227L110 80.354H73L126 0Z",
        aspectRatio: { width: 126, height: 227 },
        width: t,
        height: h,
      }),
    // magnet: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M88.402 0H0V67.669H88.402C105.271 67.669 118.957 81.308 118.957 98C118.957 114.689 105.271 128.331 88.402 128.331H0V196H88.402C142.747 196 187 152.072 187 98C187 43.9279 142.747 0 88.402 0Z",
    //     aspectRatio: { width: 187, height: 196 },
    //     width: t,
    //     height: h,
    //   }),
    // mithosis: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M150.996 136.45C137.141 128.926 117.559 128.7 103.532 135.896C93.843 140.867 82.875 143.67 71.2577 143.67C31.9033 143.67 0 111.508 0 71.835C0 32.161 31.9033 0 71.2577 0C83.467 0 94.959 3.0955 105.004 8.5504C118.859 16.0745 138.441 16.3 152.467 9.1038C162.157 4.1328 173.125 1.3303 184.742 1.3303C224.097 1.3303 256 33.492 256 73.165C256 112.839 224.097 145 184.742 145C172.533 145 161.041 141.905 150.996 136.45Z",
    //     aspectRatio: { width: 256, height: 145 },
    //     width: t,
    //     height: h,
    //   }),
    // orangeRicky: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M69 0H0V69V138V207H69H138V138H69V69V0Z",
    //     aspectRatio: { width: 138, height: 207 },
    //     width: t,
    //     height: h,
    //   }),
    // party: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M134.622 0L144.682 68.2539L174.415 5.9977L163.91 74.1846L210.671 23.4578L180.535 85.519L240.169 50.8289L193.079 101.25L260.29 85.679L200.43 119.98L269.245 124.911L201.934 140.044L266.237 165.04L197.456 159.661L251.535 202.5L187.396 177.086L226.445 233.962L172.646 190.771L193.197 256.631L154.518 199.501L154.743 268.492L134.622 202.5L114.501 268.492L114.727 199.501L76.0482 256.631L96.5985 190.771L42.7992 233.962L81.8489 177.086L17.7091 202.5L71.7885 159.661L3.0072 165.04L67.3112 140.044L0 124.911L68.8149 119.98L8.9545 85.679L76.1658 101.25L29.0753 50.8289L88.7105 85.519L58.5743 23.4578L105.335 74.1846L94.8305 5.9977L124.562 68.2539L134.622 0Z",
    //     aspectRatio: { width: 270, height: 269 },
    //     width: t,
    //     height: h,
    //   }),
    // pillow: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M176 0.00169945C157.812 16.9062 125.203 28.174 88.001 28.174C50.798 28.174 18.1881 16.9054 0 0V258.421C18.1882 241.516 50.798 230.248 88 230.248C125.202 230.248 157.812 241.516 176 258.421V0.00169945Z",
    //     aspectRatio: { width: 176, height: 259 },
    //     width: t,
    //     height: h,
    //   }),
    // polygon: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M110 0L219.119 63V189L110 252L0.880798 189V63L110 0Z",
    //     aspectRatio: { width: 220, height: 252 },
    //     width: t,
    //     height: h,
    //   }),
    // rainbow: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M186.907 141H282C282 63.128 218.872 0 141 0C63.1278 0 0 63.128 0 141H95.093C95.093 115.646 115.646 95.093 141 95.093C166.354 95.093 186.907 115.646 186.907 141Z",
    //     aspectRatio: { width: 282, height: 141 },
    //     width: t,
    //     height: h,
    //   }),
    // rhodeIsland: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M69 0H0V69V138H69V207H138V138V69H69V0Z",
    //     aspectRatio: { width: 138, height: 207 },
    //     width: t,
    //     height: h,
    //   }),
    // shell: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M109 0C169.199 0 218 48.743 218 108.87C185.392 108.87 158.958 135.273 158.958 167.842C158.958 179.736 158.958 191 158.958 191H59.042C59.042 191 59.042 179.736 59.042 167.842C59.042 135.273 32.6077 108.87 0 108.87C0 48.743 48.8007 0 109 0Z",
    //     aspectRatio: { width: 218, height: 191 },
    //     width: t,
    //     height: h,
    //   }),
    // shield1: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M203.184 30.7277C142.457 30.7277 102.853 0 102.853 0C102.853 0 63.249 30.7277 3.05098 30.7277C3.05098 30.7277 -3.81372 128.209 3.05098 162.115C9.38758 193.902 102.853 249 102.853 249C102.853 249 196.319 193.902 203.184 162.115C209.52 128.738 203.184 30.7277 203.184 30.7277Z",
    //     aspectRatio: { width: 206, height: 249 },
    //     width: t,
    //     height: h,
    //   }),
    // shield2: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M0 12.9585C64.624 -4.3195 136.376 -4.3195 201 12.9585C201 51.0564 201 89.154 201 127.252C201 184.396 100.498 240 100.498 240C100.498 240 0 184.406 0 127.252C0 89.154 0 51.0564 0 12.9585Z",
    //     aspectRatio: { width: 201, height: 240 },
    //     width: t,
    //     height: h,
    //   }),
    skewedRectangle: ({ width: t, height: h }) =>
      getScaledPath({
        path: "M85.9799 0H290L204.02 141H0L85.9799 0Z",
        aspectRatio: { width: 290, height: 141 },
        width: t,
        height: h,
      }),
    // softFlower: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M109 0C80.073 0 56.6234 23.4498 56.6234 52.3766C56.6234 54.722 54.722 56.6234 52.3766 56.6234C23.4498 56.6234 0 80.073 0 109C0 137.927 23.4498 161.377 52.3766 161.377C54.722 161.377 56.6234 163.278 56.6234 165.623C56.6234 194.55 80.073 218 109 218C137.927 218 161.377 194.55 161.377 165.623C161.377 163.278 163.278 161.377 165.623 161.377C194.55 161.377 218 137.927 218 109C218 80.073 194.55 56.6234 165.623 56.6234C163.278 56.6234 161.377 54.722 161.377 52.3766C161.377 23.4498 137.927 0 109 0Z",
    //     aspectRatio: { width: 218, height: 218 },
    //     width: t,
    //     height: h,
    //   }),
    softStar: ({ width: t, height: h }) =>
      getScaledPath({
        path: "M130.424 3.17662C130.927 -1.05888 137.073 -1.05888 137.576 3.17662L141.792 38.7086C147.236 84.59 183.41 120.764 229.291 126.208L264.823 130.424C269.059 130.927 269.059 137.073 264.823 137.576L229.291 141.792C183.41 147.236 147.236 183.41 141.792 229.291L137.576 264.823C137.073 269.059 130.927 269.059 130.424 264.823L126.208 229.291C120.764 183.41 84.59 147.236 38.7086 141.792L3.17662 137.576C-1.05888 137.073 -1.05888 130.927 3.17662 130.424L38.7086 126.208C84.59 120.764 120.764 84.59 126.208 38.7086L130.424 3.17662Z",
        aspectRatio: { width: 268, height: 268 },
        width: t,
        height: h,
      }),
    stairs1: ({ width: t, height: h }) =>
      getScaledPath({
        path: "M0 0H67.667V67.667H135.333V135.333H203V203H0V0Z",
        aspectRatio: { width: 203, height: 203 },
        width: t,
        height: h,
      }),
    stairs2: ({ width: t, height: h }) =>
      getScaledPath({
        path: "M50 0H0V200H200V150H150V100H100V50H50V0Z",
        aspectRatio: { width: 200, height: 200 },
        width: t,
        height: h,
      }),
    // teewee: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M69 0H138V69H207V138H138H69H0V69H69V0Z",
    //     aspectRatio: { width: 207, height: 138 },
    //     width: t,
    //     height: h,
    //   }),
    // blob1: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M172.979 19.611C191.285 33.0858 204.505 54.442 210.861 78.5949C217.471 103.002 217.471 130.46 205.776 151.816C194.335 173.427 171.454 189.444 147.047 196.054C122.639 202.919 96.7069 200.631 75.3506 190.715C54.2486 180.546 37.7229 163.003 23.4855 142.155C9.5022 121.307 -2.19289 97.4087 0.349524 74.7813C3.14617 52.1538 19.9261 30.7976 40.2654 17.8313C60.6046 4.61077 84.5033 -0.474048 108.148 0.0344341C131.538 0.542916 154.928 6.13621 172.979 19.611Z",
    //     aspectRatio: { width: 216, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob10: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M188.684 8.95173C205.601 24.3309 200.988 63.0866 191.453 99.3816C182.225 135.677 168.076 169.511 146.238 175.355C124.399 181.507 94.871 159.668 66.5733 143.059C37.9679 126.449 10.5929 115.068 2.59569 95.0754C-5.4015 75.0824 5.97912 46.1694 24.4342 30.4826C42.8893 14.7958 68.4188 12.0276 100.715 6.79864C132.704 1.5697 171.767 -6.42749 188.684 8.95173Z",
    //     aspectRatio: { width: 200, height: 177 },
    //     width: t,
    //     height: h,
    //   }),
    // blob11: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M137.368 29.4923C148.97 46.7605 146.542 72.1231 148.161 99.1046C149.779 126.086 155.715 154.956 144.113 174.383C132.781 193.81 103.911 203.793 80.4369 198.666C56.963 193.27 38.6156 173.034 28.3626 153.607C17.8398 134.181 15.4115 115.833 9.47555 91.5498C3.53961 67.2664 -5.63411 37.3169 4.61887 20.0488C15.1417 2.51078 45.0911 -1.80626 72.8821 0.622074C100.673 2.78059 126.036 12.2241 137.368 29.4923Z",
    //     aspectRatio: { width: 152, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob12: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M158.391 17.923C172.491 28.5703 180.261 48.1384 187.743 70.5842C194.937 93.3177 202.419 118.641 194.649 138.785C187.167 158.928 164.146 173.317 138.535 184.54C112.923 196.05 84.4347 204.108 58.8235 197.777C33.2123 191.446 10.191 170.439 2.99688 146.267C-4.48504 121.807 3.57241 94.181 10.4788 68.282C17.0974 42.0953 22.565 17.6352 37.8166 7.27563C53.0682 -3.37171 78.1038 0.081487 100.837 2.67138C123.859 5.26127 144.29 7.27563 158.391 17.923Z",
    //     aspectRatio: { width: 198, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob13: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M149.668 1.37595C170.663 5.22017 193.728 17.3442 198.755 34.7911C204.078 52.2379 191.363 75.3032 178.943 93.9329C166.819 112.563 154.99 126.757 140.501 145.386C126.011 164.312 108.86 187.673 92.5957 186.194C76.3317 185.011 60.9549 159.285 45.8737 140.655C30.4968 122.321 15.7114 110.788 7.43151 95.1157C-0.848339 79.4432 -2.32688 59.0392 3.5873 41.5924C9.50148 23.8498 23.1041 8.76868 38.7767 3.44592C54.745 -1.87685 73.3746 2.55879 91.7086 2.26308C110.338 2.26308 128.968 -2.17256 149.668 1.37595Z",
    //     aspectRatio: { width: 200, height: 187 },
    //     width: t,
    //     height: h,
    //   }),
    // blob14: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M137.838 28.6426C150.711 40.5617 165.014 46.5213 178.363 58.2021C191.474 69.8828 203.87 87.0464 198.864 99.6807C193.858 112.315 171.689 120.42 157.862 135.676C144.275 151.171 139.269 173.579 126.873 182.161C114.477 190.505 94.9294 185.022 76.8123 178.109C58.4568 171.196 41.5316 163.091 28.1822 150.456C14.5943 137.822 4.58225 120.42 1.24488 101.826C-2.09248 83.2322 1.00651 62.9697 13.8792 52.4809C26.7519 41.992 49.1598 41.0385 65.6083 30.788C82.0567 20.5376 92.0688 0.990158 103.034 0.0366268C113.762 -0.916905 125.204 16.9618 137.838 28.6426Z",
    //     aspectRatio: { width: 200, height: 187 },
    //     width: t,
    //     height: h,
    //   }),
    // blob15: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M185.203 1.52939C197.679 7.62217 200 32.2835 200 51.7223C200 71.1612 197.389 85.3777 197.679 100.175C198.259 114.681 201.741 129.768 198.259 143.114C194.778 156.46 184.623 168.356 171.567 173.868C158.801 179.671 143.134 179.091 130.368 174.739C117.312 170.387 106.867 162.263 86.8482 160.812C66.539 159.362 36.9454 164.294 18.9572 155.3C0.968966 146.306 -5.41395 123.095 5.03083 105.977C15.4756 88.5692 42.4579 77.5442 55.5139 59.5559C68.2798 41.8578 67.1192 17.4867 76.9837 9.6531C86.5581 2.10965 107.158 11.3939 128.627 9.6531C150.097 8.20244 172.728 -4.27327 185.203 1.52939Z",
    //     aspectRatio: { width: 200, height: 179 },
    //     width: t,
    //     height: h,
    //   }),
    // blob16: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M158.441 55.3221C167.253 80.2399 160.264 105.462 147.501 134.938C134.738 164.717 116.81 199.055 97.0576 199.967C77.3057 201.182 55.7305 168.668 34.4592 133.722C13.1879 99.0802 -7.77953 62.3112 2.85612 36.4818C13.4918 10.6523 56.0344 -3.93369 90.0685 0.928322C123.799 6.09421 149.324 30.7081 158.441 55.3221Z",
    //     aspectRatio: { width: 163, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob17: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M168.917 36.7587C178.871 53.2457 175.138 76.5764 172.961 106.129C170.472 135.37 169.85 170.832 150.563 187.63C131.276 204.739 93.3252 203.495 65.0173 187.63C36.7095 172.077 18.0449 142.213 7.77943 107.373C-2.79714 72.2213 -4.35252 32.7147 14.312 14.0502C32.9765 -4.3033 72.1721 -1.50361 103.591 4.7179C135.32 10.6283 158.962 20.2717 168.917 36.7587Z",
    //     aspectRatio: { width: 176, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob18: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M178.188 21.1464C193.012 36.3069 196.381 62.9219 198.739 88.1893C200.76 113.12 201.771 136.366 190.316 149.168C178.862 162.307 155.279 164.665 135.065 167.697C115.188 170.73 99.0168 174.098 72.4018 177.131C46.1237 180.163 9.40176 182.521 1.65309 168.371C-6.09559 154.221 15.4659 123.564 23.5515 92.9059C31.6371 62.2481 26.2467 31.2534 38.3751 15.4192C50.1665 -0.0781831 79.8136 -1.08888 107.776 0.595609C136.076 1.9432 163.028 5.64909 178.188 21.1464Z",
    //     aspectRatio: { width: 200, height: 180 },
    //     width: t,
    //     height: h,
    //   }),
    // blob19: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M148.621 13.3857C171.945 24.3818 194.937 41.3756 192.938 60.702C190.605 80.3615 163.282 101.687 149.953 123.346C136.958 145.005 137.958 166.664 128.295 174.661C118.631 182.325 98.3054 175.994 73.6476 181.991C48.9899 187.989 20.0004 205.983 11.0036 197.986C1.67366 189.989 12.6697 156.001 12.3365 131.343C12.0033 106.685 0.340809 91.0243 0.00759636 75.0301C-0.325616 59.0359 10.3372 42.3753 25.3318 28.7135C39.9931 15.0518 58.9863 4.05578 80.3119 1.05687C101.638 -2.27526 124.962 2.38972 148.621 13.3857Z",
    //     aspectRatio: { width: 194, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob2: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M140.464 18.6393C156.081 28.3718 169.887 39.9149 178.714 54.853C187.541 69.5649 191.389 87.6717 189.578 104.421C187.768 121.396 180.525 137.239 172.377 153.535C164.455 169.832 155.854 186.58 142.048 194.276C128.241 201.971 109.456 200.613 92.4804 197.671C75.2789 194.502 60.1144 189.749 45.1762 182.733C30.4644 175.716 16.2052 166.21 8.28346 153.083C0.361703 139.729 -1.44898 122.527 1.04072 106.91C3.30408 91.2931 9.64147 76.8076 15.9789 60.5114C22.0899 44.2153 28.201 26.1084 40.6495 14.7916C53.0979 3.24849 71.8838 -1.27823 89.7643 0.306124C107.871 1.66414 124.846 9.13322 140.464 18.6393Z",
    //     aspectRatio: { width: 191, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob20: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M150.035 13.2464C157.376 24.9386 153.026 48.323 157.92 65.7254C163.087 83.1277 177.498 94.2761 182.392 108.415C187.287 122.555 182.392 139.957 173.147 156.272C163.63 172.587 149.763 187.814 132.089 195.155C114.414 202.225 93.2053 201.137 73.6276 194.611C54.3219 188.357 36.9196 176.937 25.7712 161.438C14.8947 145.667 10.5441 126.09 5.92163 105.696C1.57105 85.0311 -3.05145 63.5501 2.65869 45.0601C8.64075 26.5701 24.6835 11.0711 43.1735 4.54524C61.6635 -1.98064 82.6007 0.194657 102.722 1.01039C122.844 1.82613 142.421 1.55421 150.035 13.2464Z",
    //     aspectRatio: { width: 185, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob21: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M152.611 0.184799C172.596 -0.723643 197.73 1.39606 199.85 13.8114C201.969 26.2268 181.075 48.9379 174.716 68.0151C168.357 87.0924 176.533 102.536 174.11 114.649C171.991 127.064 159.575 136.148 146.252 139.782C132.928 143.719 118.696 141.902 105.977 139.176C93.562 136.451 82.6606 132.212 63.2805 131.909C43.9004 131.606 16.0416 135.24 5.44306 125.853C-5.15543 116.465 1.50648 94.0572 10.2881 76.4939C18.7669 59.2335 29.6682 46.8182 38.7526 35.3112C48.1398 23.5015 56.3158 12.903 66.9143 8.36078C77.5128 4.12138 91.1394 6.24108 104.766 5.63546C118.696 4.72701 132.928 1.09324 152.611 0.184799Z",
    //     aspectRatio: { width: 200, height: 143 },
    //     width: t,
    //     height: h,
    //   }),
    // blob22: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M195.507 15.5715C206.818 33.1668 193.622 69.3001 190.166 101.663C186.71 134.34 193.308 163.561 181.996 178.014C170.685 192.153 141.464 191.525 114.443 189.326C87.1072 187.44 62.2852 183.67 40.6052 169.531C19.2395 155.077 1.6441 130.255 0.0730908 103.862C-1.18372 77.4694 13.898 49.5054 35.2638 31.91C56.6296 14.0004 84.5936 6.45958 116.328 2.37494C148.063 -1.70969 184.196 -2.3381 195.507 15.5715Z",
    //     aspectRatio: { width: 200, height: 191 },
    //     width: t,
    //     height: h,
    //   }),
    // blob23: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M135.742 9.14189C157.815 19.2326 179.258 38.4681 176.105 56.7576C172.951 74.7317 145.202 92.0751 127.858 109.734C110.83 127.077 104.523 145.052 87.4953 164.918C70.4672 184.468 42.7177 206.227 27.5816 198.343C12.4455 190.46 9.60747 153.566 6.45411 126.762C2.98542 100.274 -0.798607 84.1917 0.147399 67.1636C0.77807 50.4508 6.76944 32.4767 19.0675 20.8093C31.0503 9.14189 49.6551 3.78119 70.4672 1.2585C90.964 -1.26418 113.668 -0.633508 135.742 9.14189Z",
    //     aspectRatio: { width: 177, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob24: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M165.663 24.3715C171.555 44.4059 158.003 76.2254 151.816 96.2598C145.923 116.294 147.691 124.544 148.28 135.445C148.575 146.346 147.396 159.309 140.62 170.8C133.549 181.996 120.88 191.424 104.381 196.432C87.8821 201.441 67.553 202.03 54.8841 192.602C41.9207 183.469 36.6174 164.023 25.1271 143.105C13.3421 122.187 -4.33535 99.7953 0.967891 85.0641C6.27113 70.6275 34.2604 63.8511 56.0626 48.5307C77.5702 33.5048 92.8907 9.93489 113.22 2.56928C133.843 -4.79633 159.77 4.04241 165.663 24.3715Z",
    //     aspectRatio: { width: 168, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob25: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M180.115 1.256C192.651 2.1962 189.83 34.163 190.771 58.2947C191.711 82.4265 196.098 98.4099 198.606 117.214C201.113 136.018 201.426 157.642 189.204 165.791C177.294 173.626 153.163 167.671 135.299 163.91C117.749 159.836 106.466 157.956 85.7818 162.97C65.0974 168.298 35.3244 180.521 18.7143 174.253C1.79068 167.985 -1.97012 142.913 0.850478 120.975C3.67107 99.0367 13.0731 79.6059 18.4009 54.8473C23.7287 30.0888 24.9823 -0.310996 40.0254 0.00240332C54.7552 0.00240332 82.9612 31.029 111.167 31.029C139.373 31.029 167.579 0.315802 180.115 1.256Z",
    //     aspectRatio: { width: 200, height: 176 },
    //     width: t,
    //     height: h,
    //   }),
    // blob26: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M142.571 0.586925C159.976 3.39414 176.258 15.1844 184.96 30.9048C193.662 46.6253 194.224 66.8372 189.171 83.6805C183.837 100.805 172.327 114.56 163.064 129.157C153.8 143.755 146.501 158.914 134.43 173.231C122.359 187.828 105.516 201.303 89.5146 199.899C73.2327 198.776 58.0738 182.775 42.9148 169.862C28.0366 157.23 13.7198 147.685 6.42103 134.491C-0.877729 121.297 -1.15845 103.892 1.64876 88.4528C4.45598 72.7324 10.0704 58.6963 18.4921 47.4674C27.1944 36.2386 38.4233 27.8169 50.4943 22.2025C62.846 16.3073 75.7592 13.5001 91.1989 8.72785C106.919 4.23631 125.166 -1.93957 142.571 0.586925Z",
    //     aspectRatio: { width: 193, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob27: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M112.602 54.9337C129.225 71.8646 155.391 78.9448 158.777 89.719C162.471 100.493 143.693 114.654 127.07 135.586C110.447 156.519 96.2866 184.224 71.0442 194.998C46.1096 205.465 10.4008 199.308 2.0893 178.375C-6.22223 157.443 12.5557 121.734 18.4045 92.1817C24.5612 62.6296 17.7889 38.9263 26.4082 21.9954C34.7197 5.06454 58.423 -5.40183 73.5069 2.9097C88.8986 10.9134 95.9788 38.0028 112.602 54.9337Z",
    //     aspectRatio: { width: 160, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob28: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M125.281 68.5835C142.15 82.9923 165.344 90.021 181.862 108.998C198.028 127.624 207.516 158.902 192.405 166.634C177.293 174.716 137.581 159.605 112.629 156.442C87.326 152.928 77.1344 161.713 59.9142 165.579C43.0453 169.445 19.1478 168.391 11.7677 157.496C4.73907 146.25 14.2278 124.813 11.4163 94.2382C8.2534 63.6634 -6.85825 23.9514 3.68476 8.48836C14.2278 -7.32615 50.4254 1.10826 73.9715 16.2199C97.5175 31.683 108.412 54.1747 125.281 68.5835Z",
    //     aspectRatio: { width: 200, height: 169 },
    //     width: t,
    //     height: h,
    //   }),
    // blob29: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M162.987 10.2348C169.16 25.1809 154.864 58.6471 147.066 79.4417C139.268 100.236 138.293 108.359 134.719 114.532C131.47 120.706 125.297 124.93 119.448 143.45C113.6 161.97 107.426 194.461 98.004 199.335C88.5814 204.209 75.5849 181.14 55.7651 167.494C35.6203 153.522 8.65241 148.648 1.8292 135.327C-4.994 122.33 8.65241 100.561 20.0244 82.041C31.3964 63.5208 40.1691 48.2498 53.4906 35.5782C66.8122 22.9065 84.0326 13.159 106.452 6.33584C128.871 -0.812285 156.489 -4.71127 162.987 10.2348Z",
    //     aspectRatio: { width: 165, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob3: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M158.095 64.2637C176.543 95.19 192.277 129.1 181.697 155.686C171.117 182.001 133.951 201.533 99.4982 199.905C64.7739 198.549 33.0338 176.032 15.4004 145.377C-2.23296 114.994 -5.75963 76.2002 10.2461 46.6303C26.2518 17.0605 61.5185 -3.557 89.732 0.512241C118.217 4.58149 139.377 33.0662 158.095 64.2637Z",
    //     aspectRatio: { width: 186, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob30: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M146.076 34.4585C157.052 54.582 155.833 80.1936 164.675 100.622C173.212 121.355 191.201 136.905 189.372 149.711C187.542 162.212 165.285 171.969 146.99 175.018C129.001 178.372 114.671 174.713 103.39 175.018C92.1083 175.018 83.876 178.982 66.8015 186.299C49.7271 193.617 23.8105 204.288 14.3586 198.19C4.90665 191.787 11.6145 168.615 10.3949 147.882C8.87037 127.148 -0.581561 108.549 0.0282404 89.3407C0.638042 70.1319 11.6145 50.6182 28.384 32.934C44.8487 15.5546 67.4113 0.309578 89.974 0.00467694C112.537 -0.300224 135.099 14.335 146.076 34.4585Z",
    //     aspectRatio: { width: 190, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob31: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M134.218 44.0392C153.568 36.4264 184.97 26.9105 195.755 33.2544C206.222 39.9156 195.755 62.1194 181.481 76.3933C166.89 90.35 148.175 96.0596 145.638 116.36C143.1 136.661 156.74 171.235 151.664 183.923C146.589 196.928 123.434 187.413 105.036 178.531C86.6388 169.967 73.3165 161.72 52.0643 158.548C30.812 155.058 1.62986 156.644 0.0438693 145.225C-1.22492 133.806 25.4197 109.382 29.2261 86.8609C32.7152 64.3398 13.6834 44.0392 11.7802 26.9105C9.877 9.46461 24.7853 -4.80928 42.5484 1.53467C59.9942 7.87863 79.9777 34.5232 93.6172 45.308C107.257 56.4099 114.869 51.3347 134.218 44.0392Z",
    //     aspectRatio: { width: 200, height: 191 },
    //     width: t,
    //     height: h,
    //   }),
    // blob32: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M200 100.339C200 149.831 145.763 197.966 95.5932 197.966C45.7627 197.966 0 149.831 0 100.339C0 50.8475 45.7627 0 95.5932 0C145.763 0 200 50.8475 200 100.339Z",
    //     aspectRatio: { width: 200, height: 198 },
    //     width: t,
    //     height: h,
    //   }),
    // blob4: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M183.427 38.712C198.21 63.2519 196.141 95.1833 189.341 126.523C182.836 157.863 171.305 188.908 150.609 197.482C130.208 205.76 100.642 192.16 69.8936 177.968C39.4405 164.072 7.80478 150.176 1.30024 128.593C-5.2043 107.01 13.718 77.7393 35.3012 51.7211C56.5888 25.9986 81.1287 3.23272 108.921 0.276112C137.009 -2.38484 168.349 14.4678 183.427 38.712Z",
    //     aspectRatio: { width: 195, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob5: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M166.251 30.6913C183.816 48.5185 195.875 71.8512 199.021 98.8542C202.429 125.595 197.186 155.744 179.359 169.115C161.794 182.223 131.645 178.815 103.331 176.979C75.0171 175.144 48.8006 174.882 29.6625 161.512C10.7866 148.141 -0.748681 121.925 0.0378149 96.2326C0.824311 70.8025 14.1947 46.159 33.0706 28.3318C52.2087 10.5045 76.8522 -0.5064 100.971 0.0179307C125.091 0.804426 148.423 12.864 166.251 30.6913Z",
    //     aspectRatio: { width: 200, height: 179 },
    //     width: t,
    //     height: h,
    //   }),
    // blob6: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M149.146 50.5452C161.911 68.0278 171.901 81.9028 176.619 97.9979C181.336 114.37 180.781 133.241 173.011 149.891C165.519 166.541 151.366 180.693 133.051 189.851C114.736 199.008 92.2586 202.893 71.446 197.621C50.6334 192.348 30.9308 177.918 18.1658 158.216C5.12323 138.791 -0.981801 113.815 0.128204 87.7304C1.51571 61.6453 10.6733 34.1726 29.2658 17.5226C48.1359 1.14999 76.996 -4.40004 98.9186 3.6475C120.841 11.695 136.381 32.7851 149.146 50.5452Z",
    //     aspectRatio: { width: 180, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob7: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M158.662 41.8295C178.593 57.8305 204.138 64.5677 213.402 79.7266C222.666 94.6047 215.367 118.466 203.015 135.028C190.383 151.871 172.698 161.977 156.416 168.434C140.134 174.89 124.975 177.697 104.483 185.277C83.9903 192.856 58.1642 205.208 44.9704 197.629C31.4959 190.33 31.2152 163.1 21.9514 138.116C12.9684 112.851 -4.71688 90.1132 1.17822 75.5158C7.35404 61.1991 36.8295 55.5847 58.7256 40.1452C80.9024 24.7056 95.4998 -0.278374 109.816 0.00234533C124.414 0.283064 138.45 25.8285 158.662 41.8295Z",
    //     aspectRatio: { width: 218, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob8: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M153.53 11.0108C168.179 18.1858 181.931 29.5461 192.993 44.4939C203.755 59.4417 212.425 78.2759 210.631 96.5122C209.136 114.748 197.477 132.088 182.529 142.252C167.581 152.716 148.747 156.004 128.717 168.262C108.687 180.22 87.1622 201.147 69.8228 199.951C52.7823 198.456 39.6283 174.54 32.4533 153.015C25.2784 131.49 24.0826 112.357 16.6087 91.131C9.13477 70.2041 -4.31825 46.8855 1.36191 31.9377C7.04207 16.691 31.8554 9.51607 52.7823 5.33069C73.4103 0.846349 90.4507 -0.648433 106.594 0.248435C123.037 1.1453 138.882 4.13486 153.53 11.0108Z",
    //     aspectRatio: { width: 211, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
    // blob9: ({ width: t, height: h }) =>
    //   getScaledPath({
    //     path: "M140.289 20.2182C156.96 27.7956 176.661 32.0895 189.29 43.9608C201.667 55.5796 206.718 75.0283 202.424 91.6987C198.131 108.622 184.491 122.766 175.651 140.952C166.81 158.885 162.769 180.607 150.393 191.216C138.016 201.824 117.052 201.572 98.866 197.025C80.6802 192.479 65.2727 183.891 48.3497 175.808C31.4268 167.726 13.2409 160.148 5.1583 146.761C-2.67172 133.122 -0.398493 113.673 4.14797 96.7504C8.69444 80.08 15.5141 65.6828 23.3442 52.296C31.4268 38.6566 40.5197 25.775 52.6436 15.6717C65.0201 5.82101 80.6802 -1.50385 95.3299 0.264221C109.98 1.77971 123.619 12.6407 140.289 20.2182Z",
    //     aspectRatio: { width: 204, height: 200 },
    //     width: t,
    //     height: h,
    //   }),
  }),
  (exports.subTypeToPathDataFunc = subTypeToPathDataFunc),
  (exports.figureToSvg = figureToSvg);
