import React, { useState, useEffect, useRef, useContext } from "react";
import { useSubScriptionContext } from "../../hooks/useSubScription";
import { format, addMonths, addYears } from "date-fns";
import { useNavigate } from "react-router-dom";
import { customer, subscription, taxCreate } from "../../actions/subscription";
import Button from "../../components/Button";
import Payment from "./Payment";
import { loadStripe, Appearance } from "@stripe/stripe-js";
import { Elements, AddressElement } from "@stripe/react-stripe-js";
import { stripeKey } from "../../config";
import { IPContext } from "../../hooks/IPContext";

interface Address {
  name: string;
  firstName?: string;
  lastName?: string;
  address: {
    line1: string;
    line2: string | null;
    city: string;
    state: string;
    postal_code: string;
    country: string;
  };
  phone?: string;
}

const CheckoutForm = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isFormComplete, setFormComplete] = useState(false);
  const [address, setAddress] = useState<Address | null>(null);
  const [showPayment, setShowPayment] = useState(false);
  const [clientSecretValue, setClientSecretValue] = useState("");
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const { darkMode } = useContext(IPContext);

  const stripePromise = loadStripe(stripeKey);

  const navigate = useNavigate();

  const endRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    endRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const appearance: Appearance = darkMode
    ? {
        theme: "night",
        variables: {
          colorPrimary: "lightgreen",
          fontFamily: "Ideal Sans, system-ui, sans-serif",
        },
      }
    : {
        theme: "stripe",
        variables: {
          colorPrimary: "blue",
          fontFamily: "Ideal Sans, system-ui, sans-serif",
        },
      };

  const options = {
    // Fully customizable with appearance API.
    appearance: appearance,
  };

  const { id, name, price, isYearly, productId, setSubScriptionValues } =
    useSubScriptionContext();

  const today = format(new Date(), "MMMM do");
  const nextDate = isYearly
    ? format(addYears(new Date(), 1), "MM/dd/yyyy")
    : format(addMonths(new Date(), 1), "MM/dd/yyyy");

  const priceNumber = parseFloat(price);
  useEffect(() => {
    const totalAmount = isYearly
      ? (priceNumber * 12).toFixed(2)
      : priceNumber.toFixed(2);

    setTotal(Number(totalAmount));
  }, []);

  const handleContinue = async () => {
    setIsButtonDisabled(true);
    // setSubScriptionValues(id, name, total.toString(), isYearly, productId);
    const res_customer = await customer({ address: address });
    const customer_id = res_customer?.data.customer_id;

    const res_tax = await taxCreate({ address: address });
    const tax_rate_id = res_tax?.data.tax_rate_id;

    const res_subscription = await subscription({
      customerId: customer_id,
      productId: productId,
      taxRate: tax_rate_id,
    });

    const clientSecret = res_subscription?.data.clientSecret;
    const subscriptionId = res_subscription?.data.subscriptionId;
    const taxAmount = res_subscription?.data.taxAmount;
    const formattedTax = Number(taxAmount) / 100;
    const totalUpdate = total + formattedTax;

    setClientSecretValue(clientSecret);
    setTax(Number(formattedTax.toFixed(2)));
    setTotal(totalUpdate);
    setSubScriptionValues(
      id,
      name,
      totalUpdate.toString(),
      isYearly,
      subscriptionId
    );

    setShowPayment(true);

    let loadings = 0;

    // Create a new MutationObserver instance
    const observer = new MutationObserver((mutationsList, observer) => {
      // Look through all mutations that just occured
      for (let mutation of mutationsList) {
        // If the addedNodes property has one or more nodes
        if (mutation.addedNodes.length) {
          setTimeout(() => {
            endRef.current?.scrollIntoView({ behavior: "smooth" });
            if (loadings >= 2) {
              // If it's the second loading
              observer.disconnect(); // Stop observing after scrolling
            }
          }, 800 * loadings); // Adjust the delay as needed
          loadings++;
        }
      }
    });

    // Start observing the document with the configured parameters
    observer.observe(document, { childList: true, subtree: true });

    // endRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  // const [billingCycle, setBillingCycle] = useState("monthly");
  return (
    <div className="flex flex-col">
      <Button
        variant="outline"
        onClick={() => navigate(-1)}
        className="ml-auto mx-4 mt-4"
      >
        Back
      </Button>
      <div className="px-8 lg:grid lg:grid-cols-2 md:flex md:flex-col gap-12 my-8">
        <div className="flex flex-col gap-12">
          <div className="text-white bg-light-600 dark:bg-dark-200 rounded-lg p-12 border dark:border-[#36363C] [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
            <div className=" mb-4 text-[28px] text-[#FF0000] dark:text-light-600 font-[500]">
              1. Organization info
            </div>
            <div className="flex flex-col gap-8 items-center">
              <div className="w-full">
                <Elements stripe={stripePromise} options={options}>
                  <form>
                    <AddressElement
                      options={{
                        mode: "billing",
                        // defaultValues: {
                        //   name: "Jane Doe",
                        //   address: {
                        //     line1: "354 Oyster Point Blvd",
                        //     line2: "",
                        //     city: "South San Francisco",
                        //     state: "CA",
                        //     postal_code: "94080",
                        //     country: "US",
                        //   },
                        // },
                      }}
                      onChange={(event) => {
                        if (event.complete) {
                          // Extract potentially complete address
                          const newAddress = event.value;
                          setAddress(newAddress);
                          setFormComplete(true);
                          window.scrollTo({ top: 1500, behavior: "smooth" });
                        } else {
                          setFormComplete(false);
                        }
                      }}
                    />
                  </form>
                </Elements>
              </div>
              {isFormComplete && (
                <button
                  className={`w-1/2 bg-primary p-4 text-[20px] rounded ${
                    isButtonDisabled ? "cursor-not-allowed" : ""
                  }`}
                  onClick={handleContinue}
                  disabled={isButtonDisabled}
                >
                  Continue
                </button>
              )}
            </div>
          </div>
          <hr className="border-[#36363C] border-[1px]" />
          <div className="flex flex-col bg-light-600 dark:bg-dark-200 rounded-lg p-12 border dark:border-[#36363C] gap-8 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
            <p className="text-[28px] font-[500] text-[#FF0000] dark:text-white">
              2. Payment method
            </p>
            {showPayment && (
              <>
                <div>
                  <Payment clientSecret={clientSecretValue} />
                </div>
              </>
            )}
          </div>
          <div ref={endRef}></div>
        </div>

        <div className="flex flex-col bg-light-600 dark:bg-dark-200 rounded-lg border dark:border-[#36363C] text-white self-start [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
          <div className="flex flex-col gap-4 mb-4 px-12 pt-8">
            <div className="flex items-center gap-4">
              <img
                src="/image/Group 5157.png"
                alt="Brain Capital AI Logo"
                className="w-[40px]"
              />
              <p className="text-black dark:text-light-600 font-[600] text-[28px]">Brain Capital AI</p>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-[24px] font-[500] text-[#FF0000] dark:text-light-600">
                {name} Plan
              </span>
              {/* <select
              className="bg-dark-200 text-white rounded p-2 text-sm"
              value={billingCycle}
              onChange={(e) => setBillingCycle(e.target.value)}
            >
              <option value="monthly">Billed monthly</option>
              <option value="yearly">Billed yearly</option>
            </select> */}
              <p className="dark:bg-dark-200 text-black dark:text-white rounded p-2 text-sm">
                {isYearly ? "Billed Yearly" : "Billed monthly"}
              </p>
            </div>
          </div>

          <div className="bg-[#EFEFEF] dark:bg-dark-400 mb-4 border dark:border-[#36363C] px-12 py-8">
            <p className="mb-2 text-black dark:text-light-600">
              New plan cost starting {today}
            </p>
            <div className="flex justify-between text-[22px] mb-8 text-black dark:text-light-600">
              $
              {isYearly
                ? Number((total - tax) / 12).toLocaleString()
                : Number(total - tax).toLocaleString()}{" "}
              x 1 member x {isYearly ? 12 : 1} month{" "}
              <p>${Number(total - tax).toLocaleString()} USD</p>
            </div>
            <div className="text-sm text-black dark:text-light-200">
              You're upgrading for every active member of your team. If new
              people join or inactive members become active, they'll be added to
              your next bill.
            </div>
          </div>
          <div className="flex justify-between items-center px-12 py-4 text-[18px] text-black dark:text-light-600">
            <span>{name} Plan + this cycle</span>
            <span>${Number(total - tax).toLocaleString()}</span>
          </div>
          <hr className="border-dashed border-[#3B4451] my-2" />
          <div className="flex justify-between items-center px-12 py-4 text-[18px] text-black dark:text-light-600">
            <span>Tax</span>
            <span>${Number(tax).toLocaleString()}</span>
          </div>
          <hr className="border-[#3B4451] my-2" />
          <div className="items-center my-4 px-12 py-8">
            <div className="flex justify-between text-[20px] mb-8 text-black dark:text-light-600">
              <span>Due on {nextDate}</span>
              <span className="font-[500] text-[#FF0000] dark:text-light-600">
                ${Number(total).toLocaleString()} USD
              </span>
            </div>
            <div className="text-[16px] text-black dark:text-light-200">
              This is an estimate. You'll be charged based on the number of
              members on your team on your renewal date.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
