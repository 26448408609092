import React from 'react';
import { usePPAContext } from "../../../hooks/usePPAContext";
import { MdDelete } from "react-icons/md";
import AnimatedButton from "../../../components/AnimatedButton";

const PPAImagesPanel = () => {
  const { imageFiles, setImageFiles } = usePPAContext();

  const handleDelete = (index: number) => {
    if (imageFiles) {
      const newImageFilesArray = [...imageFiles].filter((_, i) => i !== index);
      const dataTransfer = new DataTransfer();
      newImageFilesArray.forEach((file) => dataTransfer.items.add(file));
      const newImageFiles = dataTransfer.files;
      setImageFiles(newImageFiles);
    }
  };

  return (
    <div className="flex flex-col overflow-y-auto ">
      <div>
        <h3 className="text-[#FF0000] dark:text-light-600 text-[1.3rem] font-bold">
          Project Assets
        </h3>
      </div>
      <div className="overflow-y-auto grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 overflow-x-hidden gap-5 pt-3 pr-3">
        {imageFiles &&
          Array.from(imageFiles).map((file, index) => (
            <div key={index} className="relative">
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="rounded h-full object-cover border dark:border-light-600 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none"
              />
              <AnimatedButton
                className="absolute -top-[5px] -right-[5px] text-light-600 rounded p-1 text-[1.2rem] bg-[#e41e56]"
                onClick={() => handleDelete(index)}
              >
                <MdDelete />
              </AnimatedButton>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PPAImagesPanel;
