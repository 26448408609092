// "use strict";
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.selectSvg = void 0);
const image_1 = require("../utils/image"),
  selectSvg = async ({ src: e, droppedPos: t, targetElement: i, store: s }) => {
    var l;
    if (i && "image" === i.type && i.contentEditable)
      return void i.set({ clipSrc: e });
    const { width: r, height: g } = await (0, image_1.getImageSize)(e),
      o = ((null == t ? void 0 : t.x) || s.width / 2) - r / 2,
      a = ((null == t ? void 0 : t.y) || s.height / 2) - g / 2;
    null === (l = s.activePage) ||
      void 0 === l ||
      l.addElement({ type: "svg", width: r, height: g, x: o, y: a, src: e });
  };
exports.selectSvg = selectSvg;
