import { BsCardChecklist } from "react-icons/bs";
import { GiPencilBrush } from "react-icons/gi";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSubScriptionContext } from "../../hooks/useSubScription";
import useAuth from "../../hooks/useAuth";

interface Plan {
  id: number,
  name: string;
  description: string;
  price: string;
  price_last: string;
  alert: string;
  feature_1: string;
  feature_2: string;
  productId: string;
}

interface ProductCardProps {
  plan: Plan;
  isSelected: boolean;
  isYearly: boolean;
  onCardClick: () => void; // Adjust if you need to pass parameters
}

const ProductCard: React.FC<ProductCardProps> = ({
  plan,
  isSelected,
  isYearly,
  onCardClick,
}) => {

  const { isAdmin } = useAuth();
  const { setSubScriptionValues } = useSubScriptionContext();
  const { subScriptionLevel } = useAuth();
  const ScriptionLevel = isAdmin ? 7 : subScriptionLevel;
  const navigate = useNavigate();
  const onSubmit = () => {
    if (plan.id === 0) {
      const token = sessionStorage.getItem("auth-token");
      const queryParams = new URLSearchParams({
        id: "0",
        name: "Free",
        price: "0",
        productId: "---------",
        isYearly: "",
        token: token ? token : "",
      }).toString();
      navigate(`/pixelplusai/subscription/success?${queryParams}`);
    } else {
      setSubScriptionValues(plan.id, plan.name, plan.price, isYearly, plan.productId);
      navigate("/pixelplusai/subscription/checkout");
    }
  }
  return (
    <div
      className={`border-2 ${
        (plan.id === ScriptionLevel)? ("border-green-500") : (isSelected ? "border-blue-500" : "dark:border-dark-200")
      } flex flex-col justify-between bg-light-600 dark:bg-dark-200 rounded-lg p-6 h-full items-center [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none`}
      onClick={onCardClick}
    >
      <div className="flex flex-col h-full">
        <div className="mb-4">
          <div className="flex justify-between">
            <h2 className="text-lg font-bold text-black dark:text-white">{plan.name}</h2>
            {plan.alert && (
              <div className="bg-blue-600 text-white font-bold py-1 px-3 rounded text-sm mt-1">
              {plan.alert}
            </div>
            )}
          </div>

          <div className="text-4xl font-bold text-[#FF0000] dark:text-white my-4">
            ${Number(plan.price).toLocaleString()}
            <span className="text-lg text-black dark:text-light-200">/month</span>
            {plan.price_last && (
              <p
                className={`text-[16px] font-bold ${isYearly ? "text-[#273469] dark:text-white" : "line-through dark:text-light-200 text-black"}`}
                style={{ textDecorationThickness: "2px" }}
              >
                ${plan.price_last}
              </p>
            )}
          </div>
          <p className="text-black dark:text-light-200">{plan.description}</p>
        </div>
        <div className="mt-4">
          <ul className="flex flex-col gap-4">
            <li className="text-black dark:text-light-200 flex items-start gap-2">
              <BsCardChecklist className="flex-shrink-0" />
              <p>{plan.feature_1}</p>
            </li>
            {plan.feature_2 && (
              <li className="text-black dark:text-light-200 flex items-start gap-2">
                <GiPencilBrush className="flex-shrink-0" />
                <p>{plan.feature_2}</p>
              </li>
            )}
          </ul>
        </div>
      </div>

      <div className="w-full">
        {plan.id <= (ScriptionLevel || 0) ? (
          <div className="h-[60px]"></div>
        ) : (<button className="w-full mt-4 bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded" onClick={onSubmit}>
          Get {plan.name}
        </button>)}
        
      </div>
    </div>
  );
};

export default ProductCard;
