import axios, { AxiosResponse } from "axios";
// import { User } from "../../hooks/useAuth";

export const signup = async (data: {
  email: string;
  password: string;
  username: string;
}): Promise<AxiosResponse<{ Authorization: string }>> => {
  const response = await axios.post("/user", data);
  if (response.status !== 201) {
    throw new Error(response.data.message);
  }
  return response;
};

export const login = async (data: {
  email: string;
  password: string;
  ip: string;
}): Promise<AxiosResponse<{ Authorization: string }>> => {
  return await axios.post("/auth/login", data);
};

export const verifyEmail = async (token: string): Promise<AxiosResponse<{ Authorization: string }>> => {
  return await axios.post("/auth/verify-email", {token}, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const logout = async () => {
  return await axios.post("/auth/logout");
};

export const forgotpassword = async (email: string) => {
  try {
    const response = await axios.post("/auth/forgot_password", { email }, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Error sending reset password request:", error);
    throw error;
  }
};

export const resetpassword = async (data: {
  password: string;
  token: string | null;
}) => {
  try {
    const response = await axios.post("/auth/reset_password", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Error sending reset password request:", error);
    throw error;
  }
};

export const getAlldata = async () => {
  try {
    const response = await axios.get("/user");
    return response;
  } catch (error) {
    console.error("Error fetching All data:", error);
    throw error;
  }
};

export const deleteUserbyID = async (userId: number): Promise<any> => {
  try {
    const response = await axios.delete(`/user/${userId}`);
    return response; // Return the response for further handling
  } catch (error) {
    console.error("Error during user deletion:", error);
    throw error; // Rethrow the error for the caller to handle
  }
};

export const approveRevokeUserbyID = async (userId: number): Promise<any> => {
  try {
    const response = await axios.put(`/user/update_status/${userId}`);
    return response; // Return the response for further handling
  } catch (error) {
    console.error("Error during user deletion:", error);
    throw error; // Rethrow the error for the caller to handle
  }
};

export const toggleAdminStatusByID = async (userId: number): Promise<any> => {
  try {
    const response = await axios.put(`/user/update_admin/${userId}`);
    return response; // Return the response for further handling
  } catch (error) {
    console.error("Error during toggling admin status:", error);
    throw error; // Rethrow the error for the caller to handle
  }
};

export const toggleDesignerStatusByID = async (userId: number): Promise<any> => {
  try {
    const response = await axios.put(`/user/update_designer/${userId}`);
    return response; // Return the response for further handling
  } catch (error) {
    console.error("Error during toggling designer status:", error);
    throw error; // Rethrow the error for the caller to handle
  }
};

export const updateCreditByID = async (
  userId: number,
  newCredit: number
): Promise<any> => {
  try {
    const response = await axios.put(`/user/update_credit/${userId}`, {
      credit: newCredit,
    });
    return response;
  } catch (error) {
    console.error("Error during credit update:", error);
    throw error;
  }
};

export const updateKeyByID = async (
  userId: number,
  newKey: number
): Promise<any> => {
  try {
    const response = await axios.put(`/user/update_apikey/${userId}`, {
      apikey: newKey,
    });
    return response;
  } catch (error) {
    console.error("Error during credit update:", error);
    throw error;
  }
};
