import React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Position,
  Menu,
  HTMLSelect,
  Slider,
  Popover,
  MenuItem,
} from "@blueprintjs/core";
import { downloadFile } from "../resource/utils/download";
import { updateSVGInHTML } from "../../../utilities/UpdateSVG";

export const ExportButton = observer(({ store }) => {
  const [saving, setSaving] = React.useState(false);
  const [quality, setQuality] = React.useState(1);
  
  const menu = (
    <Menu>
      <MenuItem
        icon="series-derived"
        text="Download Image"
        onClick={async () => {
          setSaving(true);
          try {
            store.pages.forEach((page, index) => {
              // do not add index if we have just one page
              const indexString =
                store.pages.length > 1 ? "-" + (index + 1) : "";
              store.saveAsImage({
                pageId: page.id,
                pixelRatio: quality,
                mimeType: "image/png",
                fileName: "bcai_result_image.png",
              });
            });
          } catch (e) {
            // throw into global error handler for reporting
            setTimeout(() => {
              throw e;
            });
            alert(
              "Something went wrong in downloading Image. Please try again."
            );
          }
          setSaving(false);
        }}
      />
      <MenuItem
        icon="floppy-disk"
        text="Download JSON"
        onClick={async () => {
          setSaving(true);
          try {
            const json = store.toJSON();
            const url =
              "data:text/json;base64," +
              window.btoa(unescape(encodeURIComponent(JSON.stringify(json))));

            downloadFile(url, "bcai_result_json.json");
          } catch (e) {
            // throw into global error handler for reporting
            setTimeout(() => {
              throw e;
            });
            alert(
              "Something went wrong in downloading JSON. Please try again."
            );
          }
          setSaving(false);
        }}
      />
      <MenuItem
        icon="code"
        text="Download HTML"
        onClick={async () => {
          setSaving(true);
          try {
            // await store.saveAsHTML("bcai_result_html.html");
            const htmlcontent = await store.toHTML();
            console.log(htmlcontent)
            const processedHtmlContent = updateSVGInHTML(htmlcontent);
            console.log(processedHtmlContent);
            const blob = new Blob([processedHtmlContent], { type: "text/html" });
            const url = URL.createObjectURL(blob);
            downloadFile(url, "bcai_result_html.html");
          
          } catch (e) {
            // throw into global error handler for reporting
            setTimeout(() => {
              throw e;
            });
            alert(
              "Something went wrong in downloading HTML. Please try again."
            );
          }
          setSaving(false);
        }}
      />
    </Menu>
  );

  return (
    <div>
      <Popover content={menu} position={Position.BOTTOM}>
        <Button icon="import" text="Export" intent="primary" className="text-[16px]"/>
      </Popover>
    </div>
  );
});
