import React from "react";
import { PropsWithChildren } from "react";
import { useTrail, animated } from "react-spring";

interface AnimationLayoutProps {
  className?: string;
}

const AnimationLayout: React.FC<PropsWithChildren<AnimationLayoutProps>> = ({
  children,
  className = "",
}) => {
  const trail = useTrail(React.Children.count(children), {
    from: { opacity: 0, transform: "translate3d(0,10px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
  });

  const classNames = `w-full h-full flex ${className} [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none`;
  return (
    <div className={classNames}>
      {React.Children.toArray(children).map((child, index) => {
        if (React.isValidElement(child)) {
          return (
            <animated.div
              className={child.props.className}
              key={index}
              style={trail[index]}
            >
              {child.props.children}
            </animated.div>
          );
        }
      })}
    </div>
  );
};

export default AnimationLayout;
