import React, { useState, useEffect, useContext } from "react";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LoginNavbar from "../../../components/LoginNavbar";
import { resetpassword } from "../../../actions/auth";
import { toast } from "react-toastify";
import { IPContext } from "../../../hooks/IPContext";

type ResetPasswordState = {
  password: string;
  confirmPassword: string;
};

const ResetPassword = () => {
  const { darkMode } = useContext(IPContext)
  const navigate = useNavigate();
  const location = useLocation();
  const [resetPasswordState, setResetPasswordState] =
    useState<ResetPasswordState>({
      password: "",
      confirmPassword: "",
    });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (!token) {
      navigate("/login");
    }
  }, [location, navigate]);

  const onChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResetPasswordState((prv) => ({
      ...prv,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async () => {
    // Here you would typically call a function to reset the password

    try {
      console.log(resetPasswordState.password);
      const params = new URLSearchParams(location.search);
      const token = params.get("token");
      const password = resetPasswordState.password;

      const data = {password, token};

      await resetpassword(data);
      toast.success("Your Password has been changed successfully.");
      navigate("/login");
    } catch (e: any) {
      if (e.message.includes("404")) {
        toast.error("The token is incorrect or expired.");
      } else {
        toast.error("Failed reseting password.");
      }
      console.error(e);
    }
    navigate("/login"); // navigate to login page
  };

  return (
    <div className={`${darkMode? "dark": ""}`}>
      <LoginNavbar />
      <div className="w-full h-[100vh] flex items-center justify-center bg-light-500 dark:bg-dark-300">
        <div className="w-[500px] flex flex-col gap-7">
          <div>
            <h3 className="text-center text-[1.7rem] text-[#ff0000] dark:text-light-600 font-[600]">
              Reset Password
            </h3>
            <p className="text-center text-black dark:text-light-200">Please enter your new password</p>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="password" className="text-black dark:text-light-600">
              New Password:{" "}
            </label>
            <TextInput
              type="password"
              id="password"
              name="password"
              placeholder="New Password"
              value={resetPasswordState?.password}
              onChange={onChangeState}
              className="px-4 border-[1px] border-solid dark:border-[#3B4451] border-light-600"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="confirmPassword" className="text-black dark:text-light-600">
              Confirm New Password:{" "}
            </label>
            <TextInput
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm New Password"
              value={resetPasswordState?.confirmPassword}
              onChange={onChangeState}
              className="px-4 border-[1px] border-solid dark:border-[#3B4451] border-light-600"
            />
          </div>
          <Button
            className="w-full flex justify-center items-center"
            onClick={onSubmit}
          >
            <p className="flex items-center gap-2 text-light-600">Reset Password</p>
          </Button>
          <div className="flex justify-center gap-2">
            <p className="text-center text-black dark:text-light-200">Remember your password?</p>
            <Link to="/login">
              <p className="text-primary">Sign in</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
