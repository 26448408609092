import { useNavigate } from "react-router-dom";
import ProductCard from "./ProductCard";
import React, { useState } from "react";
import Button from "../../components/Button";

const plan_m = [
  {
    id: 0,
    name: "Free",
    price: "0",
    description: "Perfect plan for simple design solution",
    feature_1: "30 Creative ads and a Watermark",
    feature_2: "",
  },
  {
    id: 1,
    name: "Basic",
    price: "499",
    price_last: "999/month",
    alert: "$500 Discount",
    description:
      "Perfect plan for simple design solution at an affordable price",
    feature_1: "300 Creative ads per month with basic features",
    feature_2: "",
    // product_id: "price_1PEaGjL5zCDIYaQGSoJYriPj"   test
    product_id: "price_1PLjaYL5zCDIYaQG89uuDbJM"
  },
  {
    id: 2,
    name: "Premium",
    price: "1999",
    description:
      "Suitable for medium-sized business that require a more design service",
    feature_1: "1000 Creative ads per month",
    feature_2: "Creative Agency Support to finish the Ad with human touch",
    product_id: "price_1PLjbuL5zCDIYaQG5Jx0VaI4"
  },
  {
    id: 3,
    name: "Ultimate",
    price: "2999",
    description:
      "Perfect for larger companies who need more extensive design solution",
    feature_1: "2000 Creative ads per month",
    feature_2: "Creative Agency Support to finish the Ad with human touch",
    product_id: "price_1PLjcdL5zCDIYaQGM04SFVwh"
  },
];

const plan_y = [
  {
    id: 0,
    name: "Free",
    price: "0",
    description: "Perfect plan for simple design solution",
    feature_1: "30 Creative ads and a Watermark",
    feature_2: "",
  },
  {
    id: 4,
    name: "Basic",
    price: "350",
    price_last: "499/month if paid monthly",
    alert: "30% Discount",
    description:
      "Perfect plan for simple design solution at an affordable price",
    feature_1: "300 Creative ads per month with basic features",
    feature_2: "",
    product_id: "price_1PLjf7L5zCDIYaQGo2yHK7ZQ"
  },
  {
    id: 5,
    name: "Premium",
    price: "1400",
    price_last: "1,999/month if paid monthly",
    alert: "30% Discount",
    description:
      "Suitable for medium-sized business that require a more design service",
    feature_1: "1000 Creative ads per month",
    feature_2: "Creative Agency Support to finish the Ad with human touch",
    product_id: "price_1PLjg1L5zCDIYaQGmuZpCfc7"
  },
  {
    id: 6,
    name: "Ultimate",
    price: "2100",
    price_last: "2,999/month if paid monthly",
    alert: "30% Discount",
    description:
      "Perfect for larger companies who need more extensive design solution",
    feature_1: "2000 Creative ads per month",
    feature_2: "Creative Agency Support to finish the Ad with human touch",
    product_id: "price_1PLjgcL5zCDIYaQGEZRZBDxA"
  },
];

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const [isYearly, setIsYearly] = useState(true); // Default to 'Monthly'

  const toggle = () => {
    setIsYearly(!isYearly);
  };
  const [selectedCard, setSelectedCard] = useState<number | null>(null);

  const handleCardClick = (index: number) => {
    setSelectedCard(index);
  };
  return (
    <div className="flex flex-col p-4 h-full min-h-[749px]">
      <Button variant="outline" onClick={() => navigate("/")} className="ml-auto">
        Back
      </Button>
      <div className="flex items-center justify-center space-x-2 py-12">
        <span className={` ${isYearly ? "dark:text-light-100" : "dark:text-white text-[#FF0000]"}`}>
          Monthly
        </span>
        <div
          className="flex relative w-12 h-6 bg-light-600 dark:bg-dark-200 rounded-full items-center px-[3px] border-[1px] border-gray-500"
          onClick={toggle}
        >
          <div
            className={`absolute w-4 h-4 bg-blue-600 dark:bg-green-500 rounded-full transition-transform duration-300 ${
              isYearly ? "transform translate-x-6" : ""
            }`}
          ></div>
        </div>
        <span className={` ${isYearly ? "dark:text-white text-[#FF0000] " : "text-light-100"}`}>
          Yearly
        </span>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 justify-center items-center overflow gap-4">
        {(isYearly ? plan_y : plan_m).map((plan, index) => (
          <ProductCard
            key={index}
            plan={{
              id: plan.id,
              name: plan.name,
              description: plan.description,
              price: plan.price,
              price_last: plan.price_last || "",
              alert: plan.alert || "",
              feature_1: plan.feature_1,
              feature_2: plan.feature_2,
              productId: plan.product_id || "",
            }}
            isSelected={selectedCard === index}
            onCardClick={() => handleCardClick(index)}
            isYearly={isYearly}
          />
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPage;
