import React from "react";

interface TabButtonProps {
  text: string;
  active: boolean;
  onClick: () => void;
}

const TabButton: React.FC<TabButtonProps> = ({ text, active, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`w-full p-3 flex flex-col gap-1 items-center justify-center rounded relative ${
        active ? "bg-[#E0DEDE] dark:bg-[#0C0E1A] border-[#FF0000] dark:border-primary" : "bg-white dark:bg-dark-200"
      }`}
    >
      <p className="font-bold text-[#FF0000] dark:text-white">{text}</p>
      {active && (
        <span className="absolute w-[3px] bg-[#FF0000] dark:bg-primary left-0 top-[10%] rounded h-[80%]"></span>
      )}
    </button>
  );
};

export default TabButton;