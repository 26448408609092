import axios, { AxiosResponse } from "axios";

export const customer = async (data: any): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/subscribe/create-customer", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw new Error("Error in creating customer:" + error);
  }
};

export const taxCreate = async (data: any): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/subscribe/create-tax", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw new Error("Error in creating tax rate:" + error);
  }
};

export const subscription = async (
  data: any
): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/subscribe/create-subscription", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw new Error("Error in creating subscription" + error);
  }
};

export const update_mode = async (
  data: any
): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/subscribe/update-mode", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw new Error("Error in updating user's subscription" + error);
  }
};

export const sendmsg = async (
  data: any
): Promise<AxiosResponse | null> => {
  try {
    const response = await axios.post("/subscribe/send-msg", data, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${data.token}`,
      },
    });
    return response;
  } catch (error) {
    throw new Error("Error in sending success message to the user" + error);
  }
};