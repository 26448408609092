// "use strict";
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.SVGElement = void 0);
const mobx_state_tree_1 = require("mobx-state-tree"),
  shape_model_1 = require("./shape-model");
exports.SVGElement = shape_model_1.Shape.named("SVG")
  .props({
    type: "svg",
    src: "",
    maskSrc: "",
    cropX: 0,
    cropY: 0,
    cropWidth: 1,
    cropHeight: 1,
    keepRatio: !0,
    flipX: !1,
    flipY: !1,
    width: 100,
    height: 100,
    borderColor: "black",
    borderSize: 0,
    cornerRadius: 0,
    colorsReplace: mobx_state_tree_1.types.map(mobx_state_tree_1.types.string),
  })
  .preProcessSnapshot((e) =>
    Object.assign(Object.assign({}, e), { src: e.src || e.svgSource })
  )
  .actions((e) => ({
    replaceColor(r, s) {
      e.colorsReplace.set(r, s);
    },
  }));
