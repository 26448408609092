// "use strict";
var __createBinding =
    (this && this.__createBinding) ||
    (Object.create
      ? function (e, t, o, n) {
          void 0 === n && (n = o);
          var r = Object.getOwnPropertyDescriptor(t, o);
          (r && !("get" in r ? !t.__esModule : r.writable || r.configurable)) ||
            (r = {
              enumerable: !0,
              get: function () {
                return t[o];
              },
            }),
            Object.defineProperty(e, n, r);
        }
      : function (e, t, o, n) {
          void 0 === n && (n = o), (e[n] = t[o]);
        }),
  __setModuleDefault =
    (this && this.__setModuleDefault) ||
    (Object.create
      ? function (e, t) {
          Object.defineProperty(e, "default", { enumerable: !0, value: t });
        }
      : function (e, t) {
          e.default = t;
        }),
  __importStar =
    (this && this.__importStar) ||
    function (e) {
      if (e && e.__esModule) return e;
      var t = {};
      if (null != e)
        for (var o in e)
          "default" !== o &&
            Object.prototype.hasOwnProperty.call(e, o) &&
            __createBinding(t, e, o);
      return __setModuleDefault(t, e), t;
    };
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.setAnimationsEnabled =
    exports.setTextOverflow =
    exports.setForceTextFit =
    exports.useDownScaling =
    exports.setDownScalingEnabled =
    exports.useHtmlTextRender =
    exports.useRemoveBackground =
    exports.setTextVerticalResizeEnabled =
    exports.flags =
      void 0);
const mobx = __importStar(require("mobx"));
(exports.flags = mobx.observable({
  imageDownScalingEnabled: !0,
  removeBackgroundEnabled: !0,
  htmlRenderEnabled: !1,
  forceTextFitEnabled: !1,
  textVerticalResizeEnabled: !1,
  textOverflow: "resize",
  animationsEnabled: !1,
})),
  (exports.setTextVerticalResizeEnabled = mobx.action((e) => {
    exports.flags.textVerticalResizeEnabled = e;
  })),
  (exports.useRemoveBackground = mobx.action((e) => {
    exports.flags.removeBackgroundEnabled = e;
  })),
  (exports.useHtmlTextRender = mobx.action((e) => {
    exports.flags.htmlRenderEnabled = e;
  })),
  (exports.setDownScalingEnabled = mobx.action((e) => {
    exports.flags.imageDownScalingEnabled = e;
  })),
  (exports.useDownScaling = mobx.action((e) => {
    console.warn(
      "useDownScaling is deprecated. Use setDownScalingEnabled instead."
    ),
      (0, exports.setDownScalingEnabled)(e);
  })),
  (exports.setForceTextFit = mobx.action((e) => {
    console.warn("setForceTextFit is deprecated. Use setTextOverflow instead."),
      (exports.flags.textOverflow = "change-font-size");
  })),
  (exports.setTextOverflow = mobx.action((e) => {
    exports.flags.textOverflow = e;
  })),
  (exports.setAnimationsEnabled = mobx.action((e) => {
    exports.flags.animationsEnabled = e;
  }));
