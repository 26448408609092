import React from 'react';
import { useSpring, animated } from "react-spring";
import { MouseEventHandler, ReactNode } from "react";

interface AnimatedButtonProps {
  children: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const AnimatedButton = ({
  children,
  onClick,
  className,
}: AnimatedButtonProps) => {
  const [props, set] = useSpring(() => ({
    scale: 1,
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  const handleMouseEnter = () => set({ scale: 1.2 });
  const handleMouseLeave = () => set({ scale: 1 });

  return (
    <animated.button
      className={className}
      style={props}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      {children}
    </animated.button>
  );
};

export default AnimatedButton;
