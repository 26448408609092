import React, { useState, useContext } from "react";
import Navbar from "../../components/Navbar";
import ApiKey from "./ApiKey";
import Ads from "./Ads";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { IPContext } from "../../hooks/IPContext";
import TabButton from "./TabButton";

const Profile = () => {
  const navigate = useNavigate();
  const { darkMode } = useContext(IPContext);
  const [activeTab, setActiveTab] = useState("ApiKey");

  return (
    <div className={`${darkMode ? "dark" : ""}`}>
      <div className="h-[100vh] min-h-[900px] flex flex-col bg-[#EFEFEF] dark:bg-dark-300">
        <Navbar />
        <main className="container flex-1 flex flex-col overflow-y-auto">
          {/* <BrandingBar /> */}
          <div className="flex-1 flex gap-3 py-5 overflow-y-auto">
            <div className="bg-light-600 dark:bg-dark-200 rounded border dark:border-dark-700 border-light-500 flex flex-col justify-between w-[100px] [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none h-[180px]">
              <div className="flex flex-col gap-3 mt-8">
                <TabButton
                  text="ApiKey"
                  active={activeTab === "ApiKey"}
                  onClick={() => setActiveTab("ApiKey")}
                />
                <TabButton
                  text="My Ads"
                  onClick={() => setActiveTab("Ads")}
                  active={activeTab === "Ads"}
                />
              </div>
            </div>
            <div className="flex flex-1 flex-col dark:border-dark-700 border-light-500 bg-light-600 dark:bg-dark-200 overflow-clip rounded [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none p-8 gap-2">
              <Button
                variant="outline"
                onClick={() => navigate("/")}
                className="ml-auto"
              >
                Back
              </Button>
              <div className="h-full">
                {activeTab === "ApiKey" && <ApiKey />}
                {activeTab === "Ads" && <Ads />}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Profile;
