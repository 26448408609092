import React, { useContext } from "react";
import { IPContext } from "../hooks/IPContext";
import { Outlet } from "react-router-dom";
import Navbar from '../components/Navbar';
import BrandingBar from '../components/BrandingBar';

const SubscriptionLayout = () => {
  const { darkMode } = useContext(IPContext);

  return (
    <div className={`${darkMode? "dark": ""}`}>
      <div className="flex flex-col w-full h-full bg-[#EFEFEF] dark:bg-dark-300">
        <Navbar />
        <main className="container flex-1 flex flex-col">
          <BrandingBar />
          <div className="flex-1 flex gap-3 pb-5">
            <div className="flex-1">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SubscriptionLayout;
