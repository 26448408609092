import React, { useEffect, useState, useContext } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";
import { IPContext } from "../hooks/IPContext";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

import {
  approveRevokeUserbyID,
  deleteUserbyID,
  getAlldata,
  toggleAdminStatusByID,
  toggleDesignerStatusByID,
  updateCreditByID,
  updateKeyByID,
} from "../actions/auth";
import useAuth from "../hooks/useAuth";
import Navbar from "../components/Navbar";
import { CgArrowsExchange } from "react-icons/cg";

interface AdminData {
  id: number;
  username: string;
  email: string;
  status: boolean;
  registered_on: string;
  admin: boolean;
  design: boolean;
  credit: number;
  subscription: number;
  apikey: number;
}

interface AdminTableProps {
  data: AdminData[];
}

const mockData: AdminData[] = [];

const TableRow: React.FC<{
  id: number;
  data: AdminData;
  onCheck: () => void;
  onApprove: () => void;
  onToggle_admin: () => void;
  onToggle_designer: () => void;
  onDelete: () => void;
  onUpdateCredit: (id: number, newCredit: number) => void;
  onUpdateKey: (id: number, newKey: number) => void;
  checked: boolean;
}> = ({
  id,
  data,
  onCheck,
  onApprove,
  onDelete,
  onToggle_admin,
  onToggle_designer,
  onUpdateCredit,
  onUpdateKey,
  checked,
}) => {
  const [creditValue, setCreditValue] = useState(data.credit);
  const [apiKeyValue, setApiKeyValue] = useState(data.apikey);

  const handleIncrementCredit = () => {
    const newCredit = creditValue + 5;
    setCreditValue(newCredit);
    onUpdateCredit(data.id, newCredit);
  };

  const handleDecrementCredit = () => {
    const newCredit = Math.max(0, creditValue - 5);
    setCreditValue(newCredit);
    onUpdateCredit(data.id, newCredit);
  };

  const handleIncrementKey = () => {
    const newKey = apiKeyValue + 1;
    setApiKeyValue(newKey);
    onUpdateKey(data.id, newKey);
  };

  const handleDecrementKey = () => {
    const newKey = Math.max(0, apiKeyValue - 1);
    setApiKeyValue(newKey);
    onUpdateKey(data.id, newKey);
  };

  return (
    <tr
      className={`text-black dark:text-light-500 border-t border-[1px] border-solid border-[#3B4451] ${
        data.id % 2 == 0 ? "bg-[rgba(32,_34,_36,_0.60)]" : ""
      }`}
    >
      <td className="px-[16px] py-[10px] text-start">
        <button className="text-[20px] flex items-center" onClick={onCheck}>
          {checked ? <MdOutlineCheckBox /> : <MdCheckBoxOutlineBlank />}
        </button>
      </td>
      <td className="px-[16px] py-4">
        <p className="text-start">{id + 1}</p>
      </td>
      <td className="px-[16px] py-4">
        <p className="text-start">{data.username}</p>
      </td>
      <td className="px-[16px] py-4">
        <p className="text-start">{data.email}</p>
      </td>
      <td className="px-[16px] py-4">
        <div className="flex justify-between">
          <p className="px-2 rounded-[2px] dark:text-[#FFF]">
            {data.admin ? "Admin" : "User"}
          </p>
          <button onClick={onToggle_admin} className="text-[20px]">
            <CgArrowsExchange />
          </button>
        </div>
      </td>
      <td className="px-[16px] py-4">
        <div className="flex justify-between">
          <p className="px-2 rounded-[2px] dark:text-[#FFF]">
            {data.design ? "Designer" : "User"}
          </p>
          <button onClick={onToggle_designer} className="text-[20px]">
            <CgArrowsExchange />
          </button>
        </div>
      </td>
      <td className="px-[16px] py-3">
        <div className="flex">
          <p
            className={`px-2 py-1 rounded-[2px] font-[SF_Pro] text-[13px] font-normal ${
              data.status
                ? "bg-[#B7F1D2] text-[#204C3C]"
                : "bg-[#e74c3c] text-[#FFF]"
            }`}
          >
            {data.status ? "Approve" : "Pending"}
          </p>
        </div>
      </td>
      <td className="px-[16px] py-4">
        <p className="text-start">
          {new Date(data.registered_on).toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })}
        </p>
      </td>
      <td className="px-[16px] py-4 flex flex-row justify-between">
        <button onClick={handleDecrementCredit} disabled={data.admin}>
          -
        </button>
        <p
          className={`mx-2 text-start ${
            creditValue > 0 || data.admin ? "text-[#B7F1D2]" : "text-[#e74c3c]"
          }`}
        >
          {data.admin ? "inf" : creditValue}
        </p>
        <button onClick={handleIncrementCredit} disabled={data.admin}>
          +
        </button>
      </td>
      <td className="px-[16px] py-4">
        <p className="text-center">{data.subscription}</p>
      </td>
      <td className="px-[16px] py-4 flex flex-row justify-between">
        <button onClick={handleDecrementKey} disabled={data.admin}>
          -
        </button>
        <p
          className={`mx-2 text-start ${
            apiKeyValue > 0 || data.admin ? "text-[#B7F1D2]" : "text-[#e74c3c]"
          }`}
        >
          {data.admin ? "inf" : apiKeyValue}
        </p>
        <button onClick={handleIncrementKey} disabled={data.admin}>
          +
        </button>
      </td>
      <td className="px-[16px] py-4">
        <button onClick={onApprove}>
          <p className="text-start">{data.status ? "Revoke" : "Approve"}</p>
        </button>
      </td>
      <td className="px-[16px] py-4">
        <button className="flex items-center" onClick={onDelete}>
          <FaRegTrashCan />
        </button>
      </td>
    </tr>
  );
};

const UserManagement: React.FC<AdminTableProps> = () => {
  const { isLoading, isAuthenticated, isAdmin, UserInfo } = useAuth();
  const { darkMode } = useContext(IPContext);
  const [data, setData] = useState<AdminData[]>(mockData);
  const [checkedRows, setCheckedRows] = useState<Set<number>>(new Set());
  const navigate = useNavigate();
  const toggleCheck = (id: number) => {
    setCheckedRows((prev) => {
      const newChecked = new Set(prev);
      if (newChecked.has(id)) {
        newChecked.delete(id);
      } else {
        newChecked.add(id);
      }
      return newChecked;
    });
  };

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      UserInfo();
      fetchAlldata();
    }
  }, [isLoading, isAuthenticated, isAdmin, UserInfo]);

  const fetchAlldata = async () => {
    const response = await getAlldata();
    setData(response.data);
  };

  const deleteUser = async (id: number): Promise<void> => {
    try {
      const response = await deleteUserbyID(id);
      if (response && response.status === 200) {
        await fetchAlldata();
      } else {
        console.error("Failed to delete the user");
      }
    } catch (error) {
      console.error("Error while deleting user:", error);
    }
  };

  const approveRevokeUser = async (id: number): Promise<void> => {
    try {
      const response = await approveRevokeUserbyID(id);
      if (response && response.status === 200) {
        await fetchAlldata();
      } else {
        console.error("Failed to approve the user");
      }
    } catch (error) {
      console.error("Error while approving user:", error);
    }
  };

  const toggleAdminStatus = async (id: number): Promise<void> => {
    try {
      const response = await toggleAdminStatusByID(id);
      if (response && response.status === 200) {
        await fetchAlldata();
      } else {
        console.error("Failed to update the user/admin");
      }
    } catch (error) {
      console.error("Error while updating user/admin:", error);
    }
  };

  const toggleDesignerStatus = async (id: number): Promise<void> => {
    try {
      const response = await toggleDesignerStatusByID(id);
      if (response && response.status === 200) {
        await fetchAlldata();
      } else {
        console.error("Failed to update the user/designer");
      }
    } catch (error) {
      console.error("Error while updating user/designer:", error);
    }
  };

  const updateCredit = async (id: number, newCredit: number): Promise<void> => {
    try {
      const response = await updateCreditByID(id, newCredit);
      if (response && response.status === 200) {
        await fetchAlldata();
      } else {
        console.error("Failed to update credit");
      }
    } catch (error) {
      console.error("Error while updating credit:", error);
    }
  };

  const updateKey = async (id: number, newKey: number): Promise<void> => {
    try {
      const response = await updateKeyByID(id, newKey);
      if (response && response.status === 200) {
        await fetchAlldata();
      } else {
        console.error("Failed to update APIKey");
      }
    } catch (error) {
      console.error("Error while updating APIKey:", error);
    }
  };

  return (
    <div className={`${darkMode ? "dark" : ""}`}>
      <Navbar />
      <div className="bg-[#EFEFEF] dark:bg-[#0E0E0E] min-h-[90vh] min-w-[750px]">
        {isAdmin && (
          <div className="px-5 flex flex-col py-[30px] gap-[50px]">
            <div className="flex flex-row justify-between">
              <div className="">
                <input
                  type="text"
                  id="Search"
                  name="Search"
                  placeholder="Search"
                  className="w-[500px] bg-[rgba(205,_206,_207,_0.03)] py-[6px] px-[10px] text-black dark:text-light-600 rounded-[5px] border-[1px] border-solid dark:border-[#3B4451] outline-none focus:border-light-200 dark:focus:border-primary bg-light-600"
                />
              </div>
              <div className="flex gap-[10px]">
                <div className="rounded-[5px] border-[1px] border-solid dark:border-[#3B4451] bg-light-600 dark:bg-[rgba(129,_134,_141,_0.21)] py-[6px] px-[10px] text-black dark:text-light-200">
                  Columns&nbsp;&nbsp;-
                </div>
                <div className="rounded-[5px] border-[1px] border-solid dark:border-[#3B4451] bg-light-600 dark:bg-[rgba(129,_134,_141,_0.21)] py-[6px] px-[10px] text-black dark:text-light-200">
                  10&nbsp;&nbsp;-
                </div>
                
              </div>
              <div className="">
                  <Button
                    variant="outline"
                    onClick={() => navigate("/")}
                    className="ml-auto"
                  >
                    Back
                  </Button>
                </div>
            </div>
            <div className="flex flex-col w-full rounded-[2px] border-[1px] border-solid dark:border-[#3B4451] bg-light-600 dark:bg-[rgba(217,_217,_217,_0.00)] text-[#D0D1D4] font-[SF_Pro] text-sm font-[510] [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
              <table className="w-full">
                <thead>
                  <tr className="bg-light-100 dark:bg-[#202224] text-black dark:text-light-400">
                    <th className="px-[16px] py-4 w-[30px]"></th>
                    <th className="px-[16px] py-4 text-start w-[50px]">#</th>
                    <th className="px-[16px] py-4 text-start">Username</th>
                    <th className="px-[16px] py-4 text-start">Email</th>
                    <th className="px-[16px] py-4 text-center">Admin</th>
                    <th className="px-[16px] py-4 text-center">Designer</th>
                    <th className="px-[16px] py-4 text-start">Status</th>
                    <th className="px-[16px] py-4 text-start">Created at</th>
                    <th className="px-[16px] py-4 text-start">Credit</th>
                    <th className="px-[16px] py-4 text-start">Subscription</th>
                    <th className="px-[16px] py-4 text-start">ApiKey</th>
                    <th className="px-[16px] py-4 text-start">Actions</th>
                    <th className="px-[16px] py-4"></th>
                  </tr>
                </thead>

                <tbody>
                  {data.map((item, index) => (
                    <TableRow
                      key={index}
                      id={index}
                      data={item}
                      onCheck={() => toggleCheck(index)}
                      onApprove={() => approveRevokeUser(item.id)}
                      onToggle_admin={() => toggleAdminStatus(item.id)}
                      onToggle_designer={() => toggleDesignerStatus(item.id)}
                      onDelete={() => deleteUser(item.id)}
                      onUpdateCredit={updateCredit}
                      onUpdateKey={updateKey}
                      checked={checkedRows.has(index)}
                    />
                  ))}
                </tbody>
              </table>
              <div className="border-t border-[#3B4451] p-5 flex flex-row text-[#D0D1D4] font-[SF_Pro] text-xs font-normal justify-between">
                <div className="flex flex-row gap-5 text-black dark:text-light-400">
                  <p>Items per page</p>
                  <p>10</p>
                  <p>1-10 of 100 items</p>
                </div>
                <div className="flex flex-row gap-5 text-black dark:text-light-400">
                  <p>10</p>
                  <p>of 40 pages</p>
                  <img src="/image/usermanagement/prevpage.svg" alt="prev" />
                  <img src="/image/usermanagement/nextpage.svg" alt="next" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserManagement;
