import React, {
  ButtonHTMLAttributes,
  ReactElement,
  useState,
  useContext,
} from "react";
import { MdTextFields } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { BsImages } from "react-icons/bs";
import { MdPalette, MdSmartButton, MdDesignServices } from "react-icons/md";
import { FaMagic } from "react-icons/fa";
import { PPAStep } from "../types";
import { usePPAContext } from "../hooks/usePPAContext";
import { IPContext } from "../hooks/IPContext";
import useAuth from "../hooks/useAuth";

interface StepButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactElement;
  bold?: string;
  text: string;
  active?: boolean;
}

const StepButton: React.FC<StepButtonProps> = ({
  icon,
  bold,
  text,
  active,
  ...props
}) => {
  const { isBusy } = usePPAContext();
  const { darkMode } = useContext(IPContext);
  const [hover, setHover] = useState(false);

  const { color } = useSpring({
    color: hover ? (darkMode ? "#131420" : "#E0DEDE") : "transparent",
  });

  return (
    <animated.button
      style={{
        backgroundColor: active ? (darkMode ? "#0C0E1A" : "#E0DEDE") : color,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`w-full p-3 flex flex-col gap-1 items-center justify-center rounded relative ${
        isBusy ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      {...props}
      disabled={isBusy}
    >
      <p className="text-[1.5rem] text-dark-600 dark:text-light-200">{icon}</p>
      <p className="break-words text-dark-600 dark:text-light-200">
        {bold && (
          <>
            <span className="break-words font-[750]">{bold}</span>
            <br />
          </>
        )}
        {text}
      </p>

      {active && (
        <span className="absolute w-[3px] bg-[#FF0000] dark:bg-primary left-0 top-[10%] rounded h-[80%]"></span>
      )}
    </animated.button>
  );
};

const stepData: {
  icon: ReactElement;
  bold?: string;
  text: string;
  step: PPAStep;
  disabled?: boolean;
}[] = [
  {
    icon: <MdTextFields />,
    bold: "Step1:",
    text: "Content",
    step: "content",
  },
  {
    icon: <BsImages />,
    bold: "Step2:",
    text: "Images",
    step: "images",
  },
  {
    icon: <MdPalette />,
    bold: "Step3:",
    text: "Elements",
    step: "elements",
  },
  {
    icon: <FaMagic />,
    bold: "Step4:",
    text: "Generate Ads",
    step: "creation",
  },
  {
    icon: <MdSmartButton />,
    text: "Call to Action",
    step: "button",
    disabled: true,
  },
];
const StepBar = () => {
  const { currentStep, goToStep } = usePPAContext();
  const { darkMode } = useContext(IPContext);
  const [mHover, setMHover] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isDesigner } = useAuth();

  return (
    <div className="bg-light-600 dark:bg-dark-200 rounded border dark:border-dark-700 border-light-500 flex flex-col justify-between w-[100px] [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
      <div className="flex flex-col gap-3">
        {isDesigner && (
          <animated.button
            style={{
              backgroundColor: mHover
                ? darkMode
                  ? "#131420"
                  : "#E0DEDE"
                : "transparent",
              marginTop: "4px",
            }}
            onMouseEnter={() => setMHover(true)}
            onMouseLeave={() => setMHover(false)}
            onClick={() => navigate("/new-design")}
            className={`w-full p-3 flex flex-col gap-1 items-center justify-center rounded relative cursor-pointer`}
          >
            <p className="text-[1.5rem] text-dark-600 dark:text-light-200">
              <MdDesignServices />
            </p>
            Create Template
          </animated.button>
        )}
        {stepData.map(({ icon, bold, text, step, disabled }) => (
          <StepButton
            key={step}
            bold={bold}
            icon={icon}
            text={text}
            active={step === currentStep}
            onClick={disabled ? () => {} : goToStep(step)}
          />
        ))}
      </div>
      {/* <div>
        <StepButton
          icon={<FaMagic />}
          text="Creation"
          active={currentStep === "creation"}
          onClick={goToStep("creation")}
        />
      </div> */}
    </div>
  );
};

export default StepBar;
