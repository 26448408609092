import { HashLoader } from "react-spinners";
import React from 'react';

const Loading = ({
  loading,
  className = "w-full h-full",
}: {
  loading: boolean;
  className?: String;
}) => {
  return loading ? (
    <div
      className={`${className} z-50 bg-[#26273470] backdrop-brightness-75 flex justify-center items-center gap-9`}
    >
      <HashLoader
        color="#e9e9e9"
        cssOverride={{}}
        loading
        size={50}
        speedMultiplier={1.5}
      />
    </div>
  ) : null;
};

export default Loading;
