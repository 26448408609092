import React, { useState, useRef, useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import { NavbarButton } from "./Navbar";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function ProfileMenu() {
  const { logout, isAdmin } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [darkMode] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const goProfile = () => {
    navigate("/profile");
  };

  return (
    <div className={`relative inline-block text-left ${darkMode? "dark": ""}`} ref={dropdownRef}>
      <button onClick={toggleDropdown} className="flex items-center">
        <NavbarButton>
          <FaUserCircle className="text-black dark:text-white"/>
        </NavbarButton>
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-light-600 dark:bg-dark-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 text-black dark:text-white font-sf-pro text-sm">
            {isAdmin && (
              <button
                onClick={() => navigate("/user")}
                className="block px-4 py-2 hover:bg-light-200 dark:hover:bg-dark-100 w-full text-left"
              >
                User Management
              </button>
            )}
            <button
              onClick={goProfile}
              className="block px-4 py-2 hover:bg-light-200 dark:hover:bg-dark-100 w-full text-left"
            >
              My Profile
            </button>
            <button
              onClick={logout}
              className="block px-4 py-2 hover:bg-light-200 dark:hover:bg-dark-100 w-full text-left"
            >
              Sign out
            </button>
          </div>
        </div>
      )}
    </div>
  );
}