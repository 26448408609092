import { styled } from "styled-components";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { IPContext } from "../hooks/IPContext";
import React, { useContext } from "react";

interface BrandingTitleProps {
  darkMode: boolean;
}

const BrandingTitle = styled.h3<BrandingTitleProps>`
  font-size: 1.5rem;
  font-weight: 590;
  background: ${(props) =>
    props.darkMode
      ? "linear-gradient(90deg, #77dbf5 0%, #449ff4 90.19%)"
      : "linear-gradient(90deg, #FF0000 0%, #EF9781 90.19%)"};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const BrandingBar = () => {
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const { darkMode } = useContext(IPContext);

  return (
    <div className={`${darkMode ? "dark" : ""}`}>
      <div className="flex my-3 rounded [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
        <div className="w-[5px] rounded-l-[5px] bg-[linear-gradient(180deg,_#FF0000_0%,_#EF7558_100%)] dark:bg-[linear-gradient(180deg,_#306DF3_0%,_#1FA7D1_100%)] mt-[1px]"></div>
        <div className="flex flex-1 h-full justify-center p-5 border dark:border-[#36363C] border-light-500 rounded-r-[5px] dark:bg-dark-200 bg-light-600">
          <div className="flex flex-col flex-1">
            <BrandingTitle darkMode={darkMode}>
              Pixel Plus AI : Automatic Ad Generation with Branding
            </BrandingTitle>
            <p className="text-[15px] text-dark-100 dark:text-light-200">
              Efficiency Unleashed, Creativity Magnified: Your Brand, Redefined
              by AI Ads
            </p>
          </div>
          <div className="flex">
            {isAdmin && ( // Conditionally render the button based on isAdmin
              <Button variant="outline" onClick={() => navigate("/logs")}>
                View Logs
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandingBar;
