/* eslint-disable */
// "use strict";
var __createBinding =
    (this && this.__createBinding) ||
    (Object.create
      ? function (t, o, e, n) {
          void 0 === n && (n = e);
          var s = Object.getOwnPropertyDescriptor(o, e);
          (s && !("get" in s ? !o.__esModule : s.writable || s.configurable)) ||
            (s = {
              enumerable: !0,
              get: function () {
                return o[e];
              },
            }),
            Object.defineProperty(t, n, s);
        }
      : function (t, o, e, n) {
          void 0 === n && (n = e), (t[n] = o[e]);
        }),
  __setModuleDefault =
    (this && this.__setModuleDefault) ||
    (Object.create
      ? function (t, o) {
          Object.defineProperty(t, "default", { enumerable: !0, value: o });
        }
      : function (t, o) {
          t.default = o;
        }),
  __importStar =
    (this && this.__importStar) ||
    function (t) {
      if (t && t.__esModule) return t;
      var o = {};
      if (null != t)
        for (var e in t)
          "default" !== e &&
            Object.prototype.hasOwnProperty.call(t, e) &&
            __createBinding(o, t, e);
      return __setModuleDefault(o, t), o;
    };
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.injectCustomFont =
    exports.injectGoogleFont =
    exports.getGoogleFontsVariants =
    exports.setGoogleFontsVariants =
    exports.loadFont =
    exports.isFontLoaded =
    exports.replaceGlobalFonts =
    exports.removeGlobalFont =
    exports.addGlobalFont =
    exports.globalFonts =
    exports.getFontsList =
    exports.setGoogleFonts =
    exports.isGoogleFontChanged =
      void 0);
const mobx = __importStar(require("mobx")),
  loader_1 = require("./loader");
let GOOGLE_FONTS = mobx.observable([
    "Roboto",
    "Amatic SC",
    "Press Start 2P",
    "Marck Script",
    "Rubik Mono One",
  ]),
  googleFontsChanged = mobx.observable({ value: !1 });
function isGoogleFontChanged() {
  return googleFontsChanged.value;
}
function setGoogleFonts(t) {
  "default" !== t
    ? ((googleFontsChanged.value = !0),
      GOOGLE_FONTS.splice(0, GOOGLE_FONTS.length),
      GOOGLE_FONTS.push(...t))
    : (googleFontsChanged.value = !1);
}
function getFontsList() {
  return GOOGLE_FONTS;
}
function addGlobalFont(t) {
  exports.globalFonts.push(t);
}
function removeGlobalFont(t) {
  const o = exports.globalFonts.findIndex((o) => o.fontFamily === t);
  -1 !== o && exports.globalFonts.splice(o, 1);
}
function replaceGlobalFonts(t) {
  exports.globalFonts.replace(t);
}
(exports.isGoogleFontChanged = isGoogleFontChanged),
  (exports.setGoogleFonts = setGoogleFonts),
  (exports.getFontsList = getFontsList),
  (exports.globalFonts = mobx.observable([])),
  (exports.addGlobalFont = addGlobalFont),
  (exports.removeGlobalFont = removeGlobalFont),
  (exports.replaceGlobalFonts = replaceGlobalFonts);
var TEXT_TEXT = "Some test text;?#D-ПРИВЕТ!1230o9u8i7y6t5r4e3w2q1",
  canvas = document.createElement("canvas");
function measureArial() {
  return measureFont("Arial");
}
function measureTimes() {
  return measureFont("Times", "Times");
}
function measureFont(t, o = "Arial") {
  var e = canvas.getContext("2d");
  return (e.font = `normal 40px '${t}', ${o}`), e.measureText(TEXT_TEXT).width;
}
const MAX_ATTEMPTS = 100,
  loadedFonts = { Arial: !0 },
  isFontLoaded = (t) => !!loadedFonts[t];
async function loadFont(t) {
  var o;
  if (loadedFonts[t]) return;
  const e = !!(null === (o = document.fonts) || void 0 === o ? void 0 : o.load),
    n = measureArial();
  if (e)
    try {
      await document.fonts.load(`16px '${t}'`);
      const o = measureFont(t);
      if (n !== o) return void (loadedFonts[t] = !0);
    } catch (t) {}
  const s = measureTimes(),
    r = measureFont(t),
    a = Math.min(6e3, (0, loader_1.getFontLoadTimeout)()) / 60;
  for (let o = 0; o < a; o++) {
    const o = measureFont(t, "Arial"),
      e = measureFont(t, "Times");
    if (o !== r || o !== n || e !== s) return void (loadedFonts[t] = !0);
    await new Promise((t) => setTimeout(t, 60));
  }
  console.warn(
    `Timeout for loading font "${t}". Looks like editor can't load it. Is it a correct font family?`
  ),
    (0, loader_1.triggerLoadError)(`Timeout for loading font "${t}"`);
}
(exports.isFontLoaded = isFontLoaded), (exports.loadFont = loadFont);
const registeredGoogleFonts = {};
let googleFontsVariants = "400,400italic,700,700italic";
function setGoogleFontsVariants(t) {
  googleFontsVariants = t;
}
function getGoogleFontsVariants() {
  return googleFontsVariants;
}
function injectGoogleFont(t) {
  if (registeredGoogleFonts[t]) return;
  const o = `https://fonts.googleapis.com/css?family=${t.replace(
      / /g,
      "+"
    )}:${googleFontsVariants}`,
    e = document.createElement("link");
  (e.type = "text/css"),
    (e.href = o),
    (e.rel = "stylesheet"),
    document.getElementsByTagName("head")[0].appendChild(e),
    (registeredGoogleFonts[t] = !0);
}
(exports.setGoogleFontsVariants = setGoogleFontsVariants),
  (exports.getGoogleFontsVariants = getGoogleFontsVariants),
  (exports.injectGoogleFont = injectGoogleFont);
const registeredCustomFonts = {};
function injectCustomFont(t) {
  const o = t.fontFamily;
  if (registeredCustomFonts[o]) return;
  if (!t.url && !t.styles) return;
  const e = document.createElement("style");
  e.type = "text/css";
  const n = t.styles || (t.url ? [{ src: `url("${t.url}")` }] : []);
  let s = "";
  n.forEach((t) => {
    s += `\n      @font-face {\n        font-family: '${o}';\n        src: ${
      t.src
    };\n        font-style: ${t.fontStyle || "normal"};\n        font-weight: ${
      t.fontWeight || "normal"
    };\n      }\n    `;
  }),
    (e.innerHTML = s),
    document.getElementsByTagName("head")[0].appendChild(e),
    (registeredCustomFonts[t.fontFamily] = !0);
}
exports.injectCustomFont = injectCustomFont;
