import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import BrandingBar from "../components/BrandingBar";
import StepBar from "../components/StepBar";
import React, { useContext } from "react";
import { IPContext } from '../hooks/IPContext';

const PixelPlusAILayout = () => {
  const { darkMode } = useContext(IPContext);
  return (
    <div className={`${darkMode ? "dark" : ""}`}>
      <div className="h-[100vh] min-h-[900px] flex flex-col bg-[#EFEFEF] dark:bg-dark-300">
        <Navbar />
        <main className="container flex-1 flex flex-col overflow-y-auto">
          <BrandingBar />
          <div className="flex-1 flex gap-3 pb-5 overflow-y-auto">
            <StepBar />
            <div className="flex-1 overflow-clip rounded [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PixelPlusAILayout;
