// "use strict";
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.LineElement = void 0);
const mobx_state_tree_1 = require("mobx-state-tree"),
  shape_model_1 = require("./shape-model");
exports.LineElement = shape_model_1.Shape.named("Line")
  .props({
    type: "line",
    width: 400,
    height: 10,
    color: "black",
    dash: mobx_state_tree_1.types.array(mobx_state_tree_1.types.number),
    startHead: "",
    endHead: "",
  })
  .actions((e) => ({}));
