import React, { useContext } from "react";
import { IPContext } from "../hooks/IPContext";
import { FiSun, FiMoon } from "react-icons/fi";

const LoginNavbar = () => {
  const { darkMode, setDarkMode } = useContext(IPContext);
  const handleLightTheme = () => {
    setDarkMode(false);
  };

  const handleDarkTheme = () => {
    setDarkMode(true);
  };
  return (
    <div className="fixed top-0 w-full bg-light-600 dark:bg-dark-100 text-light-600 font-michroma [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
      <div className="container flex items-center justify-between">
        <div className="flex justify-center items-center gap-3 p-5 text-[25px]">
          <img
            src={"/logo/bclogo.svg"}
            alt="Brain Capital AI Logo"
            className="w-[50px]"
          />
          <a href="https://braincapital.ai" className="text-red-500 dark:text-white font-bold dark:font-normal">Brain&nbsp;Capital&nbsp;AI</a>
        </div>
        <div>
          <div
            className={`flex rounded bg-white border border-[2px] overflow-hidden ${
              darkMode ? "border-white" : "border-gray-500"
            }`}
          >
            <button
              onClick={handleLightTheme}
              className={`w-10 h-10 flex items-center justify-center ${
                darkMode ? "" : "bg-blue-600 text-white"
              }`}
            >
              <FiSun
                className={`text-xl ${darkMode ? "text-black" : "text-white"}`}
              />
            </button>
            <button
              onClick={handleDarkTheme}
              className={`w-10 h-10 flex items-center justify-center ${
                darkMode ? "bg-blue-600 text-white" : ""
              }`}
            >
              <FiMoon
                className={`text-xl ${darkMode ? "text-white" : "text-black"}`}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginNavbar;
