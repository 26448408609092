// "use strict";
Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.registerAnimation = exports.animate = exports.animations = void 0);
const MOVE_DIRECTION = {
  right: { from: { x: -200 }, to: { x: 0 } },
  left: { from: { x: 200 }, to: { x: 0 } },
  up: { from: { y: 200 }, to: { y: 0 } },
  down: { from: { y: -200 }, to: { y: 0 } },
  "bottom-right": { from: { x: -200, y: -200 }, to: { x: 0, y: 0 } },
  "bottom-left": { from: { x: 200, y: -200 }, to: { x: 0, y: 0 } },
  "top-right": { from: { x: -200, y: 200 }, to: { x: 0, y: 0 } },
  "top-left": { from: { x: 200, y: 200 }, to: { x: 0, y: 0 } },
};
exports.animations = {
  fade: ({ dTime: t, element: o, animation: e }) => {
    const i = t / e.duration;
    return "enter" === e.type
      ? { opacity: i * o.opacity }
      : { opacity: (1 - i) * o.opacity };
  },
  move: ({ dTime: t, element: o, animation: e }) => {
    const i = MOVE_DIRECTION[e.data.direction] || MOVE_DIRECTION.right,
      n = {};
    for (var r in i.from) {
      let a = i.from[r],
        m = i.to[r];
      "exit" === e.type && ((a = i.to[r]), (m = -i.from[r]));
      const s = o[r] + a,
        x = o[r] + m - s;
      n[r] = s + (t / e.duration) * x;
    }
    return n;
  },
  zoom: ({ dTime: t, element: o, animation: e }) => {
    const i = {},
      n = {
        x: o.x + o.width / 3,
        y: o.y + o.height / 3,
        width: o.width / 3,
        height: o.height / 3,
        fontSize: o.fontSize / 3,
      },
      r = {
        x: o.x,
        y: o.y,
        width: o.width,
        height: o.height,
        fontSize: o.fontSize,
      };
    for (var a in n) {
      let o = n[a],
        m = r[a];
      if ("exit" === e.type) {
        const t = o;
        (o = m), (m = t);
      }
      const s = m - o;
      i[a] = o + (t / e.duration) * s;
    }
    return i;
  },
};
const animate = ({ element: t, dTime: o, animation: e }) => {
  const i = exports.animations[e.name];
  return i
    ? i({ element: t, dTime: o, animation: e })
    : (console.error("Can not find animation type: " + e.name), {});
};
exports.animate = animate;
const registerAnimation = (t, o) => {};
exports.registerAnimation = registerAnimation;
