import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./tailwind.css";
import App from "./App";
import { AuthProvider } from "./hooks/useAuth.tsx";
import { IPProvider } from "./hooks/IPContext.tsx";
declare let module: { hot: any };

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

const render = (Component: React.ElementType) => {
  root.render(
    // <React.StrictMode>
    <Router>
      <AuthProvider>
        <IPProvider>
          <Component />
        </IPProvider>
      </AuthProvider>
    </Router>
    // </React.StrictMode>
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}