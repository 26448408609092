import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  login as loginHandler,
  signup as signupHandler,
  verifyEmail as verifyEmailHandler
} from "../actions/auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface AuthState {
  isLoading: boolean;
  setLoading: (value: boolean) => void;
  isVerified: boolean;
  subScriptionLevel: number | undefined;
  setSubScriptionLevel: (value: number) => void;
  isAuthenticated: boolean | undefined;
  isAdmin: boolean | undefined;
  isDesigner: boolean | undefined;
  UserInfo: () => any;
  login: (username: string, password: string, ip: string) => Promise<void>;
  logout: () => void;
  signup: (email: string, password: string, username: string) => Promise<string | undefined>;
  verifyEmail: (token: string) => Promise<string | undefined>;
}

interface LoginFunction {
  (email: string, password: string, ip: string): Promise<void>;
}

const AuthContext = createContext<AuthState | undefined>(undefined);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>();
  const [isApproved, setIsApproved] = useState<boolean | undefined>();
  const [isVerified, setIsVerified] = useState(false);
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>();
  const [isDesigner, setIsDesigner] = useState<boolean | undefined>();
  const [isLoading, setLoading] = useState(true);
  const [subScriptionLevel, setSubScriptionLevel] = useState<number | undefined>();
  const navigate = useNavigate();
  const login: LoginFunction = async (email, password, ip) => {
    setLoading(true);
    try {
      const data = { email, password, ip };
      const response = await loginHandler(data);
      console.log(response)
      const access_token = response.data.Authorization;
      if (access_token) {
        sessionStorage.setItem("auth-token", access_token);
        axios.defaults.headers.common = {
          Authorization: `${access_token}`,
        };
      }
      const userRes = await UserInfo();
      if (userRes.data.apikey == 0) {
        toast.error("Your account is not verified yet.");
        setIsAuthenticated(false);
      } else if(!userRes.data.status) {
        setIsVerified(true);
        toast.error("Your account is not approved by admin yet.");
        setIsAuthenticated(false);
        navigate("/");
      }
      else {
        setIsVerified(false);
        setIsAuthenticated(true);
      }
    } catch (e: any) {
      setIsAuthenticated(false);
      toast.error(`Login failed! ${e.response.data.message}`);
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    sessionStorage.removeItem("auth-token");
    navigate("/");
  };

  const verifyEmail = async (token: string) => {
    try {
      const response = await verifyEmailHandler(token);
      toast.success("You have verified your email");
      return "OK"
    }
    catch(error: any) {
      toast.error(error.response?.data.message);
    }
  }

  const signup = async (email: string, password: string, username: string) => {
    setLoading(true);

    try {
      const response = await signupHandler({ email, password, username });
      setIsAuthenticated(false);
      return response.data.Authorization;
    } catch (error:any) {
      setIsAuthenticated(false);
      navigate("/signup");
      toast.error(error.response?.data.message);
    } 
    finally {
      setLoading(false);
    }
  };

  const UserInfo = async () => {
    const response = await axios.get("/user/info");
    if (response.data) {
      setIsApproved(response.data.status);
      setIsAdmin(response.data.admin);
      setIsDesigner(response.data.design);
      setSubScriptionLevel(Number(response.data.subscription));
    }
    return response;
  };

  useEffect(() => {
    setLoading(true);
    const token = sessionStorage.getItem("auth-token");
    axios.defaults.headers.common = {
      Authorization: `${token}`,
    };
    if (token) {
      UserInfo()
        .then((userRes) => {
          if (userRes.data.status) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
          if (userRes.data.admin) {
            setIsAdmin(true);
            setIsDesigner(true);
          } else if(userRes.data.design) {
            setIsDesigner(true);
            setIsAdmin(false);
          } else {
            setIsAdmin(false);
            setIsDesigner(false);
          }
        })
        .catch(() => {
          setIsAuthenticated(false);
          setIsAdmin(false);
          setIsDesigner(false);
        })
        .finally(() => setLoading(false));

    } else {
      setIsAuthenticated(false);
      setIsAdmin(false);
      setIsDesigner(false);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (typeof isAuthenticated === "undefined") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (typeof isApproved === "undefined") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isApproved]);

  useEffect(() => {
    if (typeof isAdmin === "undefined") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isAdmin]);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        setLoading,
        isVerified,
        subScriptionLevel,
        setSubScriptionLevel,
        isAuthenticated,
        isAdmin,
        isDesigner,
        UserInfo,
        login,
        logout,
        signup,
        verifyEmail
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default useAuth;
