import React from 'react';
import { FaFilePdf } from "react-icons/fa";
import Button from "../../../components/Button";
import Dropbox from "../../../components/Dropbox";
import { usePPAContext } from "../../../hooks/usePPAContext";

const UploadPdf = ({ onSkip }: { onSkip: () => void }) => {
  const { setPdfFiles } = usePPAContext();

  const handleSkip = () => {
    onSkip();
  };

  return (
    <div className="h-full flex flex-col overflow-clip">
      <div className="flex items-center justify-between mb-[20px] px-[20px]">
        <div>
          <h3 className="text-[#FF0000] dark:text-light-600 text-[1.3rem] font-bold">
            Upload Brand Book PDF
          </h3>
          <p className="text-black dark:text-light-200">Upload your brand visuals for a cohesive identity</p>
        </div>
        <Button onClick={handleSkip} className="text-light-600">Skip</Button>
      </div>
      <div className="flex-1 overflow-clip mb-2">
        <Dropbox
          className="w-full h-full "
          title="Drag and drop a file here"
          icon={<FaFilePdf />}
          description="Supports: PDF"
          onChange={setPdfFiles}
          multiple={false}
        />
      </div>
    </div>
  );
};

export default UploadPdf;
