import React, { useContext, useEffect, useState } from "react";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { authenticatedUrl } from "../../../config";
import LoginNavbar from "../../../components/LoginNavbar";
import { ClockLoader } from "react-spinners";
import { IPContext } from "../../../hooks/IPContext";

type LoginState = {
  email: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const { login, isLoading, isAuthenticated, isVerified, verifyEmail } = useAuth();
  const [loginState, setLoginState] = useState<LoginState>({
    email: "",
    password: "",
  });

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const { ip, darkMode } = useContext(IPContext);
  
  const onChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState((prv) => ({
      ...prv,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = () => {
    login(loginState.email, loginState.password, ip).then(() => {
      console.log(isLoading);
    });
  };

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate(authenticatedUrl);
    }
  }, [isAuthenticated, navigate, isLoading]);

  useEffect(() => {
    if(token) {
      verifyEmail(token);
    }
  }, [token])

  return (
    <div className={`${darkMode? "dark": ""}`}>
      <LoginNavbar />
      <div className="w-full h-[100vh] flex items-center justify-center bg-light-500 dark:bg-dark-300">
        <div className="w-[500px] flex flex-col gap-7">
          <div className="mb-10">
            <h3 className="text-center text-[2rem] text-[#FF0000] dark:text-light-600 font-[600]">
              Welcome to Brain Capital AI
            </h3>
            <p className="text-center text-black dark:text-light-200">To get started, please sign in</p>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="text-black dark:text-light-600">
              Email:{" "}
            </label>
            <TextInput
              id="email"
              name="email"
              placeholder="@mail.com"
              value={loginState?.email}
              onChange={onChangeState}
              className="px-4 border-[1px] border-solid dark:border-[#3B4451] border-light-600 "
            />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="password" className="text-black dark:text-light-600">
              Password:{" "}
            </label>
            <TextInput
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              value={loginState?.password}
              onChange={onChangeState}
              className="px-4 border-[1px] border-solid dark:border-[#3B4451] border-light-600"
            />
          </div>
          <div className="flex flex-row items-center justify-end">
            {/* <div className="flex items-center gap-2">
              <button
                className="text-[20px]"
                onClick={() => setRememberMe(!rememberMe)}
              >
                {rememberMe ? (
                  <MdOutlineCheckBox />
                ) : (
                  <MdCheckBoxOutlineBlank />
                )}
              </button>
              <p className="text-light-600">Remember me</p>
            </div> */}
            <button onClick={()=>{navigate("/forgot-password")}}>
              <p className="text-[#FF0000] dark:text-primary italic">Forgot Password?</p>
            </button>
          </div>
          {isVerified && (
            <div className="flex text-center dark:text-green-500 text-[#0000FF]">
              Thank you for requesting to register. Your email has been now verified. Please wait for Admin to approve your account registration. for help or to send a reminder to Admin, please email contact@braincapital.ai
            </div>
          )}
          <Button
            className="w-full flex justify-center items-center text-light-600"
            onClick={onSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <ClockLoader color="white" size="20px" />
            ) : (
              <p className="flex items-center gap-2">Sign in</p>
            )}
          </Button>
          <div className="flex justify-center gap-2">
            <p className="text-center text-black dark:text-light-200">Don’t have an account yet?</p>
            <Link to="/signup">
              <p className="text-primary">Register</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
