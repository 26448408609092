import React, { ClipboardEvent, useCallback, useRef, useState, useContext } from "react";
import Button from "../../../components/Button";
import AnimationLayout from "../../../layouts/AnimationLayout";
import { ContentEditableEvent } from "react-contenteditable";
import { usePPAContext } from "../../../hooks/usePPAContext";
import PPASidePanel from "./PPASidePanel";
import { TextArea, TextContainer } from "../../../components/TextArea";
import Loading from "../../../components/Loading";
import { IPContext } from "../../../hooks/IPContext"

const PPAContent = () => {
  const [input, setInput] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const { darkMode } = useContext(IPContext);
  const { summaries, doSummarize, isBusy, deleteAllSummarises, goToStep } =
    usePPAContext();

  const onTextInput = useCallback(
    (e: ContentEditableEvent) => {
      if (!isBusy) {
        const trimmedValue = e.target.value.replace(/&nbsp;/g, " ").trim();
        setInput(trimmedValue);
      }
    },
    [setInput]
  );

  const onLoadTxtFile = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.onchange = () => {
        if (fileRef.current) {
          const file = fileRef.current.files?.[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
              let result = e.target?.result as string;
              result = result.replace(/\n/g, "<br />");
              setInput(result);
            };
            reader.readAsText(file);
          }
        }
      };
      fileRef.current.click();
    }
  }, [fileRef.current, setInput]);

  const onSubmit = () => {
    if (input) {
      doSummarize(input);
    }
  };

  const onDiscard = () => {
    deleteAllSummarises();
  };

  const handleCopy = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const text = window.getSelection()?.toString() || "";
    event.clipboardData.setData("text/plain", text);
  };

  const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    setInput((prevInput) => prevInput + text);
  };

  return (
    <>
      <AnimationLayout className="gap-3">
        <div className="relative rounded w-full border dark:border-dark-700">
          <Loading
            loading={isBusy}
            className={"absolute rounded w-full h-full"}
          />
          <div className="dark:bg-dark-200 bg-light-600 flex-1 h-full rounded p-10 flex flex-col overflow-clip justify-between gap-3 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
            <div className="mb-[20px] pl-[20px]">
              <h3 className="dark:text-light-600 text-[#FF0000] text-[1.3rem] font-bold">
                Ad Content Description
              </h3>
              <p className="dark:text-light-200 text-black">Please write or paste your ad description</p>
            </div>
            <TextContainer
              className={`rounded flex-1 overflow-y-auto border border-1 border-[#EBE6E6] ${
                isBusy
                  ? "dark:bg-dark-400 bg-[#F4F4F4] cursor-not-allowed"
                  : "cursor-text dark:bg-dark-600 bg-[#F4F4F4] shadow"
              }`}
              placeholder="Please add description of product or service which you want automatic Ad Generation..."
              darkMode = {darkMode}
            >
              <TextArea
                html={input}
                onChange={onTextInput}
                onCopy={handleCopy}
                onPaste={handlePaste}
                className={`w-full border border-transparent dark:focus:border-primary focus:border-[#FF0000] rounded dark:text-light-200 text-black`}
                disabled={isBusy}

              />
            </TextContainer>
            <div className="flex justify-center gap-5 mt-[20px]">
              <input ref={fileRef} type="file" accept=".txt" hidden />
              <Button
                variant="outline"
                onClick={onLoadTxtFile}
                disabled={isBusy}
              >
                Load from txt file
              </Button>
              <Button
                onClick={onSubmit}
                disabled={isBusy || !input || input.length <= 0}
              >
                <p className="flex items-center gap-2 text-light-600">Summarize</p>
              </Button>
            </div>
          </div>
        </div>
        {summaries && summaries.length > 0 && (
          <div className="bg-light-600 dark:bg-dark-200 w-[400px] h-full rounded pt-10 px-5 pb-10 flex flex-col overflow-clip border dark:border-dark-700 justify-between gap-3 [box-shadow:0px_4px_4px_0px_rgba(1,_1,_1,_0.25)] dark:shadow-none">
            <PPASidePanel />
            <div className="flex justify-center gap-5 mt-[20px]">
              <Button variant="outline" onClick={onDiscard} disabled={isBusy}>
                Discard
              </Button>
              <Button onClick={goToStep("images")} disabled={isBusy}>
                <p className="flex items-center gap-2 text-light-600 break-all">Next Step</p>
              </Button>
            </div>
          </div>
        )}
      </AnimationLayout>
    </>
  );
};

export default PPAContent;
