import React from 'react';

export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
    >
      <path
        d="M16.4048 16.4403L12.0198 12.0553C13.0151 10.8711 13.6179 9.34591 13.6179 7.68156C13.6179 3.92713 10.5633 0.872559 6.80894 0.872559C3.05455 0.872559 0 3.92713 0 7.68156C0 11.436 3.05455 14.4906 6.80894 14.4906C8.47328 14.4906 9.99848 13.8884 11.1826 12.8925L15.5676 17.2775C15.6831 17.393 15.8346 17.451 15.9862 17.451C16.1378 17.451 16.2893 17.393 16.4048 17.2775C16.6363 17.046 16.6363 16.6718 16.4048 16.4403ZM1.18416 7.68156C1.18416 4.58021 3.70761 2.05673 6.80894 2.05673C9.91026 2.05673 12.4337 4.58021 12.4337 7.68156C12.4337 10.7829 9.91026 13.3064 6.80894 13.3064C3.70761 13.3064 1.18416 10.7829 1.18416 7.68156Z"
        fill="white"
      />
    </svg>
  );
};

export const RingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
    >
      <path
        d="M15.4407 11.33L13.8963 9.78247V7.73249C13.8945 6.31582 13.3686 4.95013 12.4202 3.89949C11.4718 2.84886 10.1683 2.18792 8.76172 2.04446V0.872559H7.62069V2.04446C6.21414 2.18792 4.91062 2.84886 3.9622 3.89949C3.01379 4.95013 2.48784 6.31582 2.48607 7.73249V9.78247L0.941696 11.33C0.834702 11.4371 0.77457 11.5825 0.774536 11.7341V13.4491C0.774536 13.6007 0.834645 13.7461 0.941639 13.8534C1.04862 13.9605 1.19374 14.0208 1.34505 14.0208H5.33864V14.4649C5.32622 15.1902 5.58134 15.8944 6.05508 16.4429C6.52882 16.9913 7.18788 17.3453 7.90595 17.437C8.30256 17.4765 8.703 17.4322 9.08153 17.3072C9.46007 17.1823 9.80831 16.9793 10.1039 16.7113C10.3994 16.4435 10.6357 16.1165 10.7976 15.7515C10.9594 15.3866 11.0433 14.9918 11.0438 14.5924V14.0208H15.0374C15.1887 14.0208 15.3338 13.9605 15.4408 13.8534C15.5477 13.7461 15.6079 13.6007 15.6079 13.4491V11.7341C15.6079 11.5825 15.5477 11.4371 15.4407 11.33ZM9.90274 14.5924C9.90274 15.0472 9.72241 15.4835 9.40144 15.8051C9.08047 16.1268 8.64513 16.3074 8.1912 16.3074C7.73728 16.3074 7.30193 16.1268 6.98096 15.8051C6.65999 15.4835 6.47966 15.0472 6.47966 14.5924V14.0208H9.90274V14.5924ZM14.4668 12.8774H1.91556V11.9708L3.45994 10.4233C3.56693 10.3161 3.62707 10.1707 3.6271 10.0191V7.73249C3.6271 6.51957 4.10796 5.35635 4.96389 4.49868C5.81983 3.64103 6.98072 3.1592 8.1912 3.1592C9.40168 3.1592 10.5626 3.64103 11.4185 4.49868C12.2745 5.35635 12.7553 6.51957 12.7553 7.73249V10.0191C12.7553 10.1707 12.8154 10.3161 12.9225 10.4233L14.4668 11.9708V12.8774Z"
        fill="white"
      />
    </svg>
  );
};

export const TextIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.71429H3.14286V11H4.71429V4.71429H7.85714V3.14286H0V4.71429ZM15.7143 0V1.57143H11.7857V11H10.2143V1.57143H6.28571V0H15.7143Z"
        fill="#C8C8CD"
      />
    </svg>
  );
};

export const HomeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentcolor"
      {...props}
    >
      <path
        d="M10 3.3L1.4 12H4v5h5v-3h2v3h5v-5h2.6L10 3.3z"
      />
    </svg>
  );
};