import React, { useState, createContext, PropsWithChildren, useContext } from 'react';

interface SubScriptionContextProps {
  id: number;
  name: string;
  price: string;
  isYearly: boolean;
  productId: string;
  setSubScriptionValues: ( id: number, name: string, price: string, isYearly: boolean, productId: string) => void;
}

// const SubScriptionContext = React.createContext<SubScriptionContextProps>({
//   name: '',
//   price: '',
//   isYearly: false,
//   setSubScriptionValues: () => {},
// });

const SubScriptionContext = createContext<SubScriptionContextProps | null>(null);

export const SubScriptionProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [id, setId ] = useState(0);
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [isYearly, setIsYearly] = useState(false);
    const [productId, setProductId] = useState('');
    const setSubScriptionValues = (id: number, name: string, price: string, isYearly: boolean, productId: string) => {
      setId(id);
      setName(name);
      setPrice(price);
      setIsYearly(isYearly);
      setProductId(productId);
    };
  
    return (
      <SubScriptionContext.Provider value={{ id, name, price, isYearly, productId, setSubScriptionValues }}>
        {children}
      </SubScriptionContext.Provider>
    );
  };

  export const useSubScriptionContext = () => {
    const context = useContext(SubScriptionContext);
    if (!context) {
      throw new Error("useSubScriptionContext should be used inside of SubScriptionProvider");
    }
    return context;
  };