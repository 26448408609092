import React, {useContext} from 'react';
import { observer } from 'mobx-react-lite';
import { Spinner } from '@blueprintjs/core';
import { EditorContainer, SidePanelWrap, WorkspaceWrap } from './resource';
import { Toolbar } from './resource/toolbar/toolbar';
import { ZoomButtons } from './resource/toolbar/zoom-buttons';
import { SidePanel, DEFAULT_SECTIONS, TextSection, ElementsSection, UploadSection, LayersSection, SizeSection } from './resource/side-panel';
import { Workspace } from './resource/canvas/workspace';
import { Tooltip } from './resource/canvas/tooltip';
import { setTranslations } from './resource/config';
import { loadFile } from './file';
import { ShapesSection } from './sections/shapes-section';
// import { UploadSection } from './sections/upload-section';
import { useProject } from './project';
import en from './translations/en.json';
import Topbar from './topbar/topbar';

import {IPContext} from '../../hooks/IPContext';

// replace elements section with just shapes
DEFAULT_SECTIONS.splice(3, 1, ShapesSection);

const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};

const App = observer(({ store, isEdit, index }) => {
  const project = useProject();
  const height = useHeight();
  const Tool_Sections = [TextSection, ElementsSection, UploadSection, LayersSection, SizeSection]
  const { darkMode } = useContext(IPContext);

  setTranslations(en);

  React.useEffect(() => {
    project.firstLoad();
  }, []);

  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // skip the case if we dropped DOM element from side panel
    // in that case Safari will have more data in "items"
    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      loadFile(ev.dataTransfer.files[i], store);
    }
  };

  return (
    <div
      style={{
        width: '100vw',
        height: height + 'px',
        display: 'flex',
        flexDirection: 'column',
      }}
      className={darkMode ? 'bp5-dark' : ''}
      onDrop={handleDrop}
    >
      <Topbar store={store} isEdit={isEdit} index={index}/>
      <div style={{ height: 'calc(100% - 50px)' }}>
        <EditorContainer className="editor-app-container">
          <SidePanelWrap>
            <SidePanel store={store} sections={Tool_Sections} />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar
              store={store}
              components={{
                
              }}
            />
            <Workspace store={store} components={{ Tooltip }} />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </EditorContainer>
      </div>
      {project.status === 'loading' && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
            }}
          >
            <Spinner />
          </div>
        </div>
      )}
    </div>
  );
});

export default App;
