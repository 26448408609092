Object.defineProperty(exports, "__esModule", { value: !0 }),
  (exports.getCrop =
    exports.cropImage =
    exports.loadImage =
    exports.getImageSize =
      void 0);
const svg_1 = require("./svg");
function getImageSize(t) {
  return new Promise((e) => {
    const o = document.createElement("img");
    (o.onload = async () => {
      0 === o.width || 0 === o.height
        ? e(await (0, svg_1.getSvgSize)(t))
        : e({ width: o.width, height: o.height });
    }),
      (o.crossOrigin = "anonymous"),
      (o.src = t);
  });
}
function loadImage(t) {
  return new Promise((e, o) => {
    const i = document.createElement("img");
    (i.onload = () => {
      e(i);
    }),
      (i.onerror = (t) => {
        o(t);
      }),
      (i.crossOrigin = "anonymous"),
      (i.src = t);
  });
}
async function cropImage(t, e) {
  const o = await loadImage(t),
    i = document.createElement("canvas");
  (i.width = 2 * o.width),
    (i.height = 2 * o.height),
    i.getContext("2d").drawImage(o, 0, 0, i.width, i.height);
  const h = document.createElement("canvas");
  (h.width = e.width), (h.height = e.height);
  const g = h.getContext("2d");
  let { cropX: r, cropY: n } = e;
  const a = i.width * e.cropWidth,
    c = i.height * e.cropHeight,
    s = e.width / e.height;
  let d, m;
  const p = a / c;
  return (
    "svg" === e.type
      ? ((d = a), (m = c))
      : s >= p
      ? ((d = a), (m = a / s))
      : ((d = c * s), (m = c)),
    g.drawImage(i, r * i.width, n * i.height, d, m, 0, 0, h.width, h.height),
    h.toDataURL("image/png")
  );
}
function getCrop(t, e) {
  const { width: o, height: i } = t,
    h = o / i;
  let g, r;
  h >= e.width / e.height
    ? ((g = e.width), (r = e.width / h))
    : ((g = e.height * h), (r = e.height));
  const n = (e.width - g) / 2,
    a = (e.height - r) / 2;
  return {
    cropX: n / e.width,
    cropY: a / e.height,
    cropWidth: g / e.width,
    cropHeight: r / e.height,
  };
}
(exports.getImageSize = getImageSize),
  (exports.loadImage = loadImage),
  (exports.cropImage = cropImage),
  (exports.getCrop = getCrop);
